import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import AppContext from 'context/Context';
import { post, patch } from 'services/skill-matric-services/smSkillLevelService';

const SkillLevelModal = ({ data, isShow, handleClose, handleSubmit }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const [formData, setFormData] = useState(data);

  const handleChange = ({ target }) => {
    console.log(target)
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit');
    e.preventDefault();
    console.log(formData);

    if (formData.id > 0) {
      await patch(formData.id, {...formData});
    } else {
      await post({ id: uuid(), ...formData });
    }
    handleSubmit();
  };

  useEffect(() => {}, []);

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">{formData?.id > 0 ? 'Edit' : 'Create'} Skill Level</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData?.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Col md="12">
            <Form.Label className="fs-0">Level</Form.Label>
            <Form.Control
              type="number"
              name="levelNo"
              required
              value={formData?.levelNo}
              onChange={handleChange}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SkillLevelModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
};

export default SkillLevelModal;
