import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import SaveBtn from './SaveBtn';
import { moreThanMinDate } from 'helpers/utils';
import { handleUpdate } from 'helpers/newCritical';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';

const TransportForm = ({ job, dept, jobStageItem, customerType }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [transItem, setTransItem] = useState({
    driverName: '',
    isAgency: false,
    deliveryScannedDate: '',
    odrComments: '',
    issues: ''
  });

  useEffect(() => {
    if (job) setTransItem(prev => ({ ...prev, ...job }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'DespatchTransportData');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage,
      customerType
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="driverName">
              <Form.Label>Driver Name</Form.Label>
              <Form.Control
                type="text"
                value={transItem.driverName || ''}
                onChange={e =>
                  setTransItem(prev => ({
                    ...prev,
                    driverName: e.target.value
                  }))
                }
              ></Form.Control>
            </Form.Group>

            <Form.Group
              className="d-flex align-items-end ms-4"
              as={Col}
              id="agency"
            >
              <Form.Check
                type="checkbox"
                label="Agency?"
                checked={transItem.isAgency}
                onChange={e =>
                  setTransItem(prev => ({
                    ...prev,
                    isAgency: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="route">
              <Form.Label>Route</Form.Label>
              <Form.Control
                type="text"
                value={transItem.wdJobInfo?.route || ''}
                readOnly
              ></Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="delScanned">
              <Form.Label>Scanned Date</Form.Label>
              <DatePicker
                calendarStartDay={1}
                className="form-control"
                placeholderText="Scanned Date"
                readOnly
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(transItem.deliveryScannedDate)
                    ? new Date(transItem.deliveryScannedDate)
                    : ''
                }
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3 g-3" as={Col} id="odrs">
            <Form.Label>ODR Comment</Form.Label>
            <Form.Control
              as={'textarea'}
              type="text"
              value={transItem.odrComments || ''}
              onChange={e =>
                setTransItem(prev => ({ ...prev, odrComments: e.target.value }))
              }
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 g-3" as={Col} controlId="issues">
            <Form.Label>Any issues</Form.Label>
            <Form.Control
              as={'textarea'}
              rows={4}
              type="text"
              value={transItem.issues || ''}
              onChange={e =>
                setTransItem(prev => ({ ...prev, issues: e.target.value }))
              }
            ></Form.Control>
          </Form.Group>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="Saving..."
                handleClick={() =>
                  handleUpdate(transItem, setLoading, 'DespatchTransportData')
                }
              />

              <SaveBtn
                loading={loading}
                text={
                  customerType === 902
                    ? 'Save & Move to Commercial Feedback'
                    : 'Save & Move to CSO Feedback'
                }
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(transItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

TransportForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object,
  customerType: PropTypes.number
};

export default TransportForm;
