import React from 'react';
import { Card, Button} from 'react-bootstrap';

/* eslint-disable react/prop-types */
function openBacklog(boardId, sprintId, onClose) {
    var win = window.open(`https://emplas.atlassian.net/jira/people/5fb7f800cbead50069002059/boards/${boardId}/backlog`, '_blank', 'width=1600,height=900');
    var loop = setInterval(function () {
        if (win.closed) {
            clearInterval(loop);
            onClose();
        }
    }, 1000);

}

function openJira(boardId, sprintId) {
    window.open(`https://emplas.atlassian.net/jira/people/5fb7f800cbead50069002059/boards/${boardId}?sprints=${sprintId}`, '_blank', 'width=1600,height=900')
}

const SprintLinks = (props) => {
    return (
        <Card className='h-100'>
            <Card.Body>
                <div className="d-grid gap-1">
                    <Button size="sm" variant="primary" onClick={() => { openJira(props.selectedBoardId, props.selectedSprintId ); }}>
                        Open Jira
                    </Button>
                    <Button size="sm" variant="secondary" onClick={() => { openBacklog(props.selectedBoardId, props.selectedSprintId, props.onClose); }}>
                        Open Backlog
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};
/* eslint-enable react/prop-types */
export default SprintLinks;