import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
import Flex from 'components/common/Flex';
import { Row, Col } from 'react-bootstrap';
import useTokenCheck from 'hooks/useTokenCheck';

const Authentication = () => {
  const [loading, isAuthenticated, path, isAuthorization] = useTokenCheck();
  const navigate = useNavigate();

  if ((!loading && isAuthenticated && isAuthorization) && path !== '') {
    navigate(path);
    return;
  }

  return (
    <AuthCardLayout footer={false}>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Flex alignItems="center" justifyContent="center">
            <div>
              <h4>Welcome!</h4>
              <p className="mb-0">
                Please be patient while we verify your login information.
                You will soon be redirected to the correct page.
              </p>
            </div>
          </Flex>
        </Col>
      </Row>
    </AuthCardLayout>
  );
};

export default Authentication;