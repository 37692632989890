// data is a dropdow array [{label: label, value: 1}]
export const joinDataMultiDropdown = data => {
  if (data && typeof data === 'object') {
    return data?.reduce((accumulator, currentObject) => {
      return accumulator === ''
        ? currentObject.value
        : `${accumulator},${currentObject.value}`;
    }, '').toString();
  }
  return data;
};

// split string from "1,2" to dropdow array [{label: label, value: 1}, {label: label2, value: 2}]
export const splitStringMapValueToDropDown = (dataString, options) => {
  if (!dataString || !options || dataString == undefined || dataString == null) {
    return [];
  }
  const valuesArray = dataString.split(',').map(value => value.trim());
  const mappedValues = valuesArray
    .map(value => options.find(option => option.value.toString() === value.toString()))
    .filter(Boolean);
  return mappedValues;
};
