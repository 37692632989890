import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FeedbackForm from '../forms/FeedbackForm';

const ColumnHeader = ({ id, title, itemCount, colour, selectedJobs, showAll }) => {
  const [show, setShow] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [showAllCheck, setShowAllCheck] = useState(false);

  const handleShowAllCheck = (e) => {
      showAll(e.target.checked);
      setShowAllCheck(e.target.checked);
  }

  const openFeedbackForm = jobs => {
    if (!jobs?.length) {
      toast.error(
        <div className="text-700 text-white">
          <h6 className="text-white">Error!</h6>
          <hr className="me-2" />
          <p>Please select at least one job before continuing!</p>
        </div>
      );
      return;
    }
    setJobs(selectedJobs.map(x => ({ ...x, stageId: 5445 })));
    setShow(true);
  };

  return (
    <>
      <div
        style={{ backgroundColor: colour }}
        className="light kanban-column-header"
      >
        <h5 className="fs-0 mb-0 py-2">
          {title} <span className="text-800">({itemCount})</span>
        </h5>
        {(id === 4135 || id === 5554) && (
          <button
            className="m-0 rounded-2 shadow py-1 px-2 bg-soft-secondary border-0 float-end text-800"
            onClick={async () => openFeedbackForm(selectedJobs)}
          >
            Bulk Complete
          </button>
        )}

        {(id === 4126) && (
          <Form.Group controlId="showAllCusts">
            <Form.Check
              id='showAllCusts'
              type="checkbox"
              label="Show All"
              checked={showAllCheck}
              onChange={e =>
                handleShowAllCheck(e)
              }
          />
          </Form.Group>
        )}
      </div>

      <Modal size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div id="form-4135">
            <FeedbackForm dept={{ name: title, data: colour }} job={jobs} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

ColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colour: PropTypes.string,
  selectedJobs: PropTypes.arrayOf(PropTypes.object),
  showAll: PropTypes.func.isRequired
};

export default ColumnHeader;
