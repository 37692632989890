import { useEffect, React } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import { UploadFile } from 'helpers/fileHelper';
import Quill from 'quill';
import './editor.css';

import PropTypes from 'prop-types';

let BlockEmbed = Quill.import('blots/block/embed');
class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    } else {
      node.setAttribute('src', value.url);
      node.setAttribute('alt', value.alt);
      node.setAttribute('width', value.width);
      node.setAttribute('height', value.height);
      node.setAttribute('style', value.style);
    }
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      style: node.getAttribute('style')
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot);

const Editor = ({
  initialText,
  imageFolder,
  handleChangeOut,
  minHeight = 300,
  placeholder = 'Type /canned to create or insert predefined responses.'
}) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
    placeholder: placeholder
  });

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      setTimeout(() => {
        document.querySelectorAll('.ql-image').forEach(button => {
          button.innerHTML = '🗀';
        });
      }, 0);

      quill.clipboard.dangerouslyPasteHTML(initialText);
      quill.getModule('toolbar').addHandler('image', selectLocalFile);
      quill.on('text-change', () => {
        handleChangeOut(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill, Quill, initialText]);

  const selectLocalFile = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveFileToServer(file);
    };
  };
  const saveFileToServer = async file => {
    const fileUrl = await UploadFile(imageFolder, file);
    insertFileToEditor(file.name, fileUrl, file.type);
  };
  const insertFileToEditor = (fileName, fileUrl, fileType) => {
    console.log(fileName, fileUrl, fileType);
    const range = quill.getSelection();
    let filePreview = '';

    if (fileType.startsWith('image/')) {
      filePreview = `<img src="${fileUrl}" alt="${fileName}" style="max-width: 100%;" />`;
    } else if (fileType === 'application/pdf') {
      filePreview = `<iframe src="https://docs.google.com/gview?url=${encodeURIComponent(
        fileUrl
      )}&embedded=true" width="100%" height="1400px"></iframe>`;
    } else if (fileType.startsWith('audio/')) {
      filePreview = `<audio controls><source src="${fileUrl}" type="${fileType}"></audio>`;
    } else if (fileType.startsWith('video/')) {
      filePreview = `<video controls width="100%"><source src="${fileUrl}" type="${fileType}"></video>`;
    } else if (
      fileType.startsWith('text/') ||
      fileType.includes('word') ||
      fileType.includes('excel')
    ) {
      filePreview = `<iframe src="https://docs.google.com/gview?url=${fileUrl}&embedded=true" width="100%" height="400px"></iframe>`;
    } else {
      filePreview = `<a href="${fileUrl}" download="${fileName}">${fileName}</a>`;
    }

    quill.clipboard.dangerouslyPasteHTML(range.index, filePreview);
  };

  return (
    <div className="editor-parent" style={{ width: '100%' }}>
      <div
        style={{
          minHeight: minHeight,
          marginBottom: '10px',
          backgroundColor: 'white'
        }}
        ref={quillRef}
      />
    </div>
  );
};

Editor.propTypes = {
  imageFolder: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChangeOut: PropTypes.func,
  placeholder: PropTypes.string,
  initialText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minHeight: PropTypes.number
};

export default Editor;
