import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAllEvaUsers } from 'services/userServices';
import { RRule } from 'rrule';

const getMeetingDate = repeatRule => {
  if (!repeatRule) return null;

  const ruleJson = JSON.parse(repeatRule);

  const rule = new RRule({
    freq: ruleJson.Freq,
    dtstart: new Date(ruleJson.Dtstart),
    until: ruleJson.Until ? new Date(ruleJson.Until) : null,
    count: ruleJson.Count || null,
    byweekday: ruleJson.Byweekday,
    interval: ruleJson.interval || 1
  });

  const today = new Date();

  const occurrencesToday = rule.between(
    new Date(today.setHours(0, 0, 0, 0)),
    new Date(today.setHours(23, 59, 59, 999)),
    true
  );

  if (occurrencesToday.length > 0) {
    return today;
  } else {
    if (ruleJson.Until && new Date(ruleJson.Until) < new Date()) {
      const allOccurrences = rule.all();
      return allOccurrences.length > 0 ? allOccurrences[allOccurrences.length - 1] : null;
    } else {
      const nextOccurrence = rule.after(new Date());
      return nextOccurrence ? nextOccurrence : null;
    }
  }
};


const MeetingDetailHeader = ({
  title,
  repeatRule,
  eventDate,
  author,
  regFee
}) => {
  let displayDate = eventDate;
  if (repeatRule) {
    displayDate = getMeetingDate(repeatRule);
    displayDate = displayDate ?? eventDate;
  }

  const [user, setUser] = useState({});
  const isMounted = useRef(null);
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) {
          const item = res.find(i => i.userId == author);
          setUser(item);
        }
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, [author]);

  return (
    <Card className="p-0 mb-3">
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              <Calendar date={displayDate} />
              <div className="fs--1 ms-2 flex-1">
                <h5 className="fs-0 text-capitalize">{title}</h5>
                <p className="mb-0 text-capitalize">
                  by
                  <Link className=" ms-1 " to="#!">
                    {user ? user.name : ''}
                  </Link>
                </p>
                <span className="fs-0 text-warning fw-semi-bold">{regFee}</span>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0"></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MeetingDetailHeader.propTypes = {
  title: PropTypes.string,
  eventDate: PropTypes.object,
  author: PropTypes.number,
  regFee: PropTypes.string,
  repeatRule: PropTypes.string
};

export default MeetingDetailHeader;
