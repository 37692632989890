import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProject: null
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    applyProject: (state, action) => {
      state.selectedProject = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applyProject } = projectSlice.actions;

export default projectSlice.reducer;
