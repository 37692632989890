import axios from 'axios';
import { API2_HOST } from 'helpers/urlHelper';
axios.defaults.withCredentials = true;

export const mapFile = async (files) => {
  var fullURL = `${API2_HOST}/attachments`;
  const res = await axios({
    url: fullURL,
    method: 'POST',
    data: files,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res;
};

export const getFiles = async (recordValue, app) => {
  var fullURL = `${API2_HOST}/attachments?recordValue=${recordValue}&app=${app}`;
  const res = await axios({
    url: fullURL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res;
};

export const deleteFile = async (rq) => {
  var fullURL = `${API2_HOST}/attachments`;
  const res = await axios({
    url: fullURL,
    method: 'delete',
    data: rq,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res;
};
