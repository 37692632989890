import React from 'react';
import './Priority.scss';
import PropTypes from 'prop-types';

const Priority = ({ param }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      {param && (
        <>
          <div
            style={{
              '--falcon-circle-progress-bar': param.falconValue // Gán giá trị là chuỗi
            }}
          >
            <svg
              className="circle-progress-svg"
              width="26"
              height="26"
              viewBox="0 0 120 120"
            >
              <circle
                className="progress-bar-rail"
                cx="60"
                cy="60"
                r="54"
                fill="none"
                strokeLinecap="round"
                strokeWidth="12"
              ></circle>
              <circle
                className="progress-bar-top"
                cx="60"
                cy="60"
                r="54"
                fill="none"
                strokeLinecap="round"
                stroke={param.color} // Màu cho vòng tròn tiến trình
                strokeWidth="12"
                style={{
                  strokeDasharray: 260, // Thay đổi để phù hợp với biến CSS
                  strokeDashoffset: `calc(100 - var(--falcon-circle-progress-bar))`, // Công thức để tạo ra hiệu ứng tiến trình,
                }}
              ></circle>
            </svg>
          </div>
          <h6 className="mb-0 text-700">{param?.label}</h6>
        </>
      )}
    </div>
  );
};

Priority.propTypes = {
  param: PropTypes.any,
};

export default Priority;
