import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyProject } from '../../../../slices/projectSlice';
import { newProject, defaultFields } from 'helpers/projectHelpers';
import { isEmptyString } from 'helpers/utils';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import ProjectFormItem from 'components/partials/projects/ProjectFormItem';
import UserDropdown from 'components/dropdowns/UserDropdown';
import NewField from 'components/partials/projects/NewField';
import AddNewField from 'components/partials/projects/AddNewField';
import ActionsManager from 'components/partials/actions/ActionsManager';
import { toast } from 'react-toastify';
import { getProjectById, postProject } from 'services/projectServices';
import { updateProjectDetails } from '../../../../helpers/projectUtilities';
import BasicTemplate from 'components/Toast/BasicTemplate';
import OptionListSelect from 'components/dropdowns/OptionListSelect';

export default function ProjectManager() {
  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newValue, setNewValue] = useState('');

  const project = useSelector(state => state.project.selectedProject);
  const dispatch = useDispatch();

  useEffect(() => {
    const getProjectDetails = async id => await getProjectById(id);

    // Can we get an id?
    if (params.has('id')) {
      const id = params.get('id');

      getProjectDetails(id).then(project => {
        if (project && project.id > 0) {
          dispatch(
            applyProject({
              ...project,
              owners: project.owners.map(x => {
                return {
                  ...x,
                  label: x.name,
                  value: x.id ? x.id : x.userId
                };
              }),
              champions: project.champions.map(x => {
                return {
                  ...x,
                  label: x.name,
                  value: x.id ? x.id : x.userId
                };
              })
            })
          );
          return;
        }
      });
      return;
    }

    // If we get this far its a new Project
    dispatch(applyProject({ ...newProject, items: defaultFields }));
    return;
  }, []);

  const addNewField = () => {
    if (!isEmptyString(newTitle) && !isEmptyString(newValue)) {
      dispatch(
        applyProject({
          ...project,
          items: [
            ...project.items,
            { actions: [], notes: newValue, title: newTitle }
          ]
        })
      );
      setNewTitle(() => '');
      setNewValue(() => '');
      return;
    }
    return toast.error(
      <BasicTemplate text="Please enter a valid title and description to continue." />
    );
  };

  const handleInputChange = (e, name) => {
    const {
      target: { value }
    } = e;

    if (name === 'Project Name') {
      dispatch(
        applyProject({
          ...project,
          projectName: value
        })
      );
      return;
    }

    const items = [...project.items];
    const idx = items.findIndex(item => item.title === name);

    if (idx > -1) {
      // update existing
      items[idx] = {
        ...items[idx],
        title: name,
        notes: value
      };
      dispatch(
        applyProject({
          ...project,
          items: items
        })
      );
    } else {
      // nu 1
      dispatch(
        applyProject({
          ...project,
          items: [...project.items, { actions: [], notes: value, title: name }]
        })
      );
    }
  };

  const saveProject = async () => {
    setIsWaitingSave(true);

    if (!checkRequired()) {
      setIsWaitingSave(false);
      return false;
    }

    // Update project details for post
    let updatedProject = updateProjectDetails({ ...project });
    dispatch(applyProject(updatedProject));

    const success = await postProject(project);

    success
      ? toast.success(
          <BasicTemplate success={success} text="Project successfully saved!" />
        )
      : toast.error(
          <BasicTemplate
            success={success}
            text="There was a problem saving the project. Please check your entires and try again."
          />
        );

    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const checkRequired = () => {
    let valid = true;

    if (project.projectName === '') {
      toast.error(
        <BasicTemplate text="Please enter a project name to continue." />
      );
      valid = false;
    }

    if (project.owners?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please select at least one owner to continue." />
      );
      valid = false;
    }

    if (project.champions?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please select at least one champion to continue." />
      );
      valid = false;
    }

    return valid;
  };

  const multiSelectChange = (options, key) => {
    switch (key) {
      case 'owners':
        dispatch(
          applyProject({
            ...project,
            owners: [...options]
          })
        );
        break;
      case 'champions':
        dispatch(
          applyProject({
            ...project,
            champions: [...options]
          })
        );
        break;
      default:
        break;
    }
    return;
  };

  const singleSelectChange = (option, key) => {
    switch (key) {
      case 'status':
        dispatch(
          applyProject({
            ...project,
            status: option.value
          })
        );
        break;
      case 'priority':
        dispatch(
          applyProject({
            ...project,
            priority: option.value
          })
        );
        break;
      default:
        return;
    }
    return;
  };

  return (
    <Row>
      <Col xs={12}>
        <PageHeader
          title={
            project && project.id > 0 ? project.projectName : 'Create Project'
          }
          description={`<p>Use this page to manage your projects. Assign actions below the description.</p><p>Leave an input blank to ignore the field.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            {/* Default Fields */}
            <ProjectFormItem
              id="name"
              title="Project Name"
              body={project && project.projectName}
              placeholder="Please enter a name for this project"
              valueChange={e => handleInputChange(e, 'Project Name')}
            />
            <ProjectFormItem
              id="project-owners"
              title="Project Owners"
              inputElement={
                <UserDropdown
                  selectedOptions={project?.owners}
                  handleChange={opts => multiSelectChange(opts, 'owners')}
                />
              }
            />
            <ProjectFormItem
              id="project-champions"
              title="Project Champions"
              inputElement={
                <UserDropdown
                  selectedOptions={project?.champions}
                  handleChange={opts => multiSelectChange(opts, 'champions')}
                />
              }
            />
            <ProjectFormItem
              id="project-priority"
              title="Project Priority"
              inputElement={
                <OptionListSelect
                  optionKey="project.priority"
                  selected={project && project.priority ? project.priority : 0}
                  changeFn={opt => singleSelectChange(opt, 'priority')}
                />
              }
            />
            <ProjectFormItem
              id="project-status"
              title="Project Status"
              inputElement={
                <OptionListSelect
                  optionKey="project.status"
                  selected={project && project.status ? project.status : 0}
                  changeFn={opt => singleSelectChange(opt, 'status')}
                />
              }
            />
            {/* Fields attached to project */}
            {project.items &&
              project.items.map((x, idx) => {
                const id = `${x.title
                  .replaceAll(' ', '-')
                  .toLowerCase()}_${idx}`;
                return (
                  <React.Fragment key={id}>
                    <ProjectFormItem
                      id={id}
                      title={x.title}
                      body={x.notes}
                      table={
                        x.notes && (
                          <ActionsManager
                            actions={x.actions}
                            title={x.title}
                            itemId={x.id}
                          />
                        )
                      }
                      valueChange={e => handleInputChange(e, x.title)}
                    />
                  </React.Fragment>
                );
              })}
            {/* Add custom field always at bottom */}
            <NewField
              newTitle={newTitle}
              newValue={newValue}
              titleChange={e => setNewTitle(e.target.value)}
              valueChange={e => setNewValue(e.target.value)}
              button={<AddNewField handleClick={addNewField} />}
            />
            <Row>
              <Col xs={12}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  onClick={() => saveProject()}
                >
                  {!isWaitingSave ? (
                    'Save Project'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
