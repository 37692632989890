import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const previewDocument = async (templatePath, data, fileName) => {
  const blob = await getBlob(templatePath, data);
  if(blob) {
    saveAs(blob, fileName);
  }
};

const getBlob = async (templatePath, data) => {
  const response = await fetch(templatePath);
  const templateArrayBuffer = await response.arrayBuffer();
  console.log(templateArrayBuffer)
  const zip = new PizZip(templateArrayBuffer);
  const doc = new Docxtemplater(zip, {
    paragraphLoop: true,
    linebreaks: true
  });

  doc.setData(data);

  try {
    doc.render();
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });

    return out;

  } catch (error) {
    console.error('error:', error);
    return null;
  }
};

const buildTableXml = (headers, rows) => {
  let tableXml = `
    <w:tbl>
        <w:tblPr>
            <w:tblW w:w="5000" w:type="pct"/>
            <w:tblBorders>
                <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                <w:insideH w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                <w:insideV w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            </w:tblBorders>
        </w:tblPr>
        <w:tr>
    `;

  headers.forEach((header, index) => {
    const isLastColumn = index === headers.length - 1;
    const width = isLastColumn ? 4000 : 2000;
    tableXml += `
        <w:tc>
            <w:tcPr>
                <w:tcW w:w="${width}" w:type="dxa"/>
                <w:shd w:fill="D3D3D3"/> <!-- Màu nền xám cho header -->
            </w:tcPr>
            <w:p><w:r><w:t>${header.headerText}</w:t></w:r></w:p>
        </w:tc>
        `;
  });

  tableXml += `</w:tr>`;

  rows?.forEach(row => {
    tableXml += `<w:tr>`;
    headers.forEach((header, index) => {
      let cellContent = row[header.value];
      const isFirstColumn = index === 0;
      const bgColor = isFirstColumn ? 'D3D3D3' : '';
      const isValidNumber = !isNaN(cellContent) && !isNaN(parseFloat(cellContent)) && cellContent.toString().trim() === parseFloat(cellContent).toString();
      if (isValidNumber && parseFloat(cellContent) > 0) {
        cellContent = `<w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>${cellContent}</w:t></w:r>`;
      } else {
        var content = escapeXml(cellContent);
        content = content ?? '';
        cellContent = `<w:r><w:t>${content}</w:t></w:r>`;
      }

      tableXml += `
            <w:tc>
                <w:tcPr><w:tcW w:w="2000" w:type="dxa"/>
                ${bgColor ? `<w:shd w:fill="${bgColor}"/>` : ''}
                </w:tcPr>
                <w:p>${cellContent}</w:p>
            </w:tc>
        `;
    });
    tableXml += `</w:tr>`;
  });

  tableXml += `</w:tbl>`;
  return tableXml;
};

const escapeXml = str => {
  if (typeof str === 'string') {
    return str.replace(/[&<>"']/g, match => {
      switch (match) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case "'":
          return '&apos;';
        default:
          return match;
      }
    });
  }
  return str;
};

export { previewDocument, buildTableXml, escapeXml, getBlob };
