import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Form } from "react-bootstrap";
import Minimise from "components/Minimise";

const ProjectFormItem = ({ id, title, body, inputElement, valueChange, placeholder, table, button, readOnly=false }) => {

    if (!inputElement) {
        inputElement = (
            <Form.Group className="mb-3">
                <Form.Control as="textarea"
                    rows={3}
                    placeholder={placeholder ? placeholder : "Type here..."}
                    defaultValue={body}
                    onBlur={valueChange}
                    readOnly={readOnly}
                />
            </Form.Group>
        );
    }

    return (
        <Row>
            <Col xs={12}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-between mb-3 mx-1">
                            <span>{title}</span>
                            <span className="d-flex align-items-start"><Minimise id={`${id}-collapse`} /></span>
                        </Card.Title>
                        <div id={`${id}-collapse`}>
                            {inputElement}
                            {table}
                            {button}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

ProjectFormItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    body: PropTypes.string,
    inputElement: PropTypes.element,
    valueChange: PropTypes.func,
    placeholder: PropTypes.string,
    table: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    button: PropTypes.element,
    readOnly: PropTypes.bool
}

export default ProjectFormItem;
