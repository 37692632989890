import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getAllEvaUsers } from 'services/userServices';

const UserDropdown = ({ selectedOptions, handleChange, isMulti = true }) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="users"
      options={users}
      value={
        typeof selectedOptions === 'number'
          ? users?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder="Select a user from the list..."
    />
  );
};

UserDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default UserDropdown;
