import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import { handleUpdate } from 'helpers/newCritical';
import SaveBtn from './SaveBtn';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';

const ProductionForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [prodItem, setProdItem] = useState({
    batched: false,
    itemsReadyForQA: false,
    csoNotifiedOfShortages: false,
    commercialNotifiedOfShortages: false,
    ncAddedToBriefings: false,
    itemsHandedToQA: false,
    itemsHandedToLoading: false,
    handshake: false
  });

  useEffect(() => {
    if (job) setProdItem(prev => ({ ...prev, ...job }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'Production');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="batched">
              <Form.Check
                disabled
                type="checkbox"
                id="items-batched"
                label="Items batched?"
                defaultChecked={prodItem.batched || prodItem.wdJobInfo?.batched}
              />
            </Form.Group>

            <Form.Group as={Col} id="itemsReadyForQA">
              <Form.Check
                type="checkbox"
                id="items-ready-for-qa"
                label="Items ready for QA?"
                checked={prodItem.itemsReadyForQA}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    itemsReadyForQA: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="cSONotifiedOfShortages">
              <Form.Check
                type="checkbox"
                id="cso-notified-of-shortages"
                label="CSO notified of shortages?"
                checked={prodItem.csoNotifiedOfShortages}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    csoNotifiedOfShortages: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="nCAddedToBriefings">
              <Form.Check
                type="checkbox"
                id="n&c-info-added-to-briefings"
                label="N&C info added to briefings?"
                checked={prodItem.ncAddedToBriefings}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    ncAddedToBriefings: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="commercialNotifiedOfShortages">
              <Form.Check
                type="checkbox"
                id="commerical-notified-of-shortages"
                label="Commercial notified of shortages?"
                checked={prodItem.commmercialNotifiedOfShortages}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    commercialNotifiedOfShortages: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="itemsHandedToLoading">
              <Form.Check
                type="checkbox"
                id="items-handed-to-loading"
                label="Items handed to loading?"
                checked={prodItem.itemsHandedToLoading}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    itemsHandedToLoading: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="handshake">
              <Form.Check
                type="checkbox"
                id="manufacturing-&-loading-handshake"
                label="Manufacturing & loading handshake?"
                checked={prodItem.handshake}
                onChange={e =>
                  setProdItem(prev => ({
                    ...prev,
                    handshake: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="Saving..."
                handleClick={() =>
                  handleUpdate(prodItem, setLoading, 'Production')
                }
              />

              <SaveBtn
                loading={loading}
                text="Save & Move to QC/QA"
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(prodItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

ProductionForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default ProductionForm;
