import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

/* eslint-disable react/prop-types */

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
    LegendComponent
]);

const getOptions = data => ({
    series: [
        {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            radius: '90%',
            pointer: {
                show: false
            },
            progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                    color: data <= 100 ? getColor('primary') : getColor('danger')
                }
            },
            axisLine: {
                lineStyle: {
                    width: 8,
                    color: [[1, getColor('200')]]
                }
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            data: [
                {
                    value: data,
                    detail: {
                        offsetCenter: ['7%', '4%']
                    }
                }
            ],
            detail: {
                width: 50,
                height: 14,
                fontSize: 28,
                fontWeight: 500,
                fontFamily: 'poppins',
                color: getColor('500'),
                formatter: '{value}%'
            }
        }
    ]
});


const SprintPieChart = (props) => {

    let percentComplete = 0;
    if (props.total && props.amount) {
        percentComplete = Math.round((props.total / props.amount) * 100);
    }

    let loading = props.loading;
    let amount = 0;
    let amountDesc = "";
    let total = 0;
    let totalDesc = "Loading";

    if (!loading) {
        amount = props.amount;
        amountDesc = props.amountDesc;
        total = props.total;
        totalDesc = props.totalDesc;
    }

    return (
        <Card className="h-100 mt-3">
            <FalconCardHeader
                title={props.title}
                titleTag="h6"
                className="py-2"
                light
            />
            <Card.Body
                //className={classNames(bodyClassName, 'h-100')}
                as={Flex}
                direction="column"
                alignItems="between"
            >
                <ReactEChartsCore
                    echarts={echarts}
                    option={getOptions(percentComplete)}
                    style={{ height: '10.3rem', widht: '10.3rem' }}
                />
                <div className="text-center mt-3">
                    <h6 className="fs-0 mb-1">
                        {/* <FontAwesomeIcon
              icon="check"
              transform="shrink-2"
              className="text-success me-1"
            /> */}
                        {loading ? "" : Math.round(total)} {totalDesc}
                    </h6>
                    <p className="fs--3 mb-0">{ loading ? "" :  Math.round(amount)} {amountDesc}</p>
                    { props.extra ? <>{props.extra}</> : <></>}
                </div>
            </Card.Body>

            {/* <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 6 Months</option>
              <option>Last Year</option>
              <option>Last 2 Year</option>
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Link className="fs--1 font-sans-serif" to="#!">
              Help
            </Link>
          </Col>
        </Row>
      </Card.Footer> */}
        </Card>
    );
};

/* eslint-enable react/prop-types */
export default SprintPieChart;
