import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RunningCustomersFocus from './RunningCustomersFocus';
import PageHeader from 'components/common/PageHeader';
import { useParams } from 'react-router-dom';

const CustomersFocusDashboard = () => {
  const { id } = useParams();

  return (
    <>
      <PageHeader
        title="Customers Focus Meeting Dashboard"
        description={`Manage and view all available Customers Focus Meeting from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <RunningCustomersFocus meetingId={id} />
        </Col>
      </Row>
    </>
  );
};

export default CustomersFocusDashboard;
