import axios from 'axios';
axios.defaults.withCredentials = true;
const bucketName = 'eva-files';
import { FILE_API_HOST } from 'helpers/urlHelper';

export const uploadFile = async (prefix, file) => {
  const formData = new FormData();
  formData.append('files', file);
  var fullURL = `${FILE_API_HOST}/eva/multi-upload?bucketName=${bucketName}&prefix=${prefix}`;

  const res = await axios({
    url: fullURL,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res;
};

export const getFiles = async (prefix) => {
  var fullURL = `${FILE_API_HOST}/files/${bucketName}?prefix=${prefix}`;

  const res = await axios({
    url: fullURL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res;
};

export const deleteFile = async (key) => {
  var fullURL = `${FILE_API_HOST}/files/${bucketName}?key=${key}`;

  const res = await axios({
    url: fullURL,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  
  return res;
};


export const dowloadFile = async (key) => {
  var fullURL = `${FILE_API_HOST}/files/download/${bucketName}?key=${key}`;

  const res = await axios({
    url: fullURL,
    method: 'get',
    responseType: 'blob',
  });
  return res;
};

