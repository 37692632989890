import React from "react";
import PropTypes from 'prop-types';

const FormHeader = ({ name, colour, children }) => {
    return (
        <div
            style={{ border: `solid 1px ${colour}` }}
            className="m-2 mb-4 rounded shadow"
        >
            <div
                style={{ backgroundColor: colour }}
                className="light d-flex align-items-center shadow-sm rounded p-2 "
            >
                <h1 className="h2">{name}</h1>
            </div>
            {children}
        </div>
    )
}

FormHeader.propTypes = {
    name: PropTypes.string,
    colour: PropTypes.string,
    children: PropTypes.node
}

export default FormHeader;