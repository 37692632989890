import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { search as searchSubSkill } from 'services/skill-matric-services/smSubSkillService';
import { search as searchTrainingSubSkill } from 'services/skill-matric-services/smSubSkillTrainingRecordService';
import {
  search as searchCriteria
} from 'services/skill-matric-services/smSkillCriteriaService';
import PropTypes from 'prop-types';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';

export default function SubSkillCheckList({
  trainingId = 0,
  trainee,
  skillLevelId,
  skillActivityId,
  areaId,
  outSubChecks
}) {
  const [criterias, setCriterias] = useState([]);
  const [checks, setChecks] = useState([]);
  const [subSkills, setSubSkill] = useState([]);
  const [subSkillChecks, setSubSkillChecks] = useState([]);

  useEffect(() => {
    console.log(skillLevelId);
    if (skillLevelId) {
      searchCriteria({ isGetAll: true, skillLevelId }).then(res => {
        setCriterias(res.items);
        console.log(res.items);
      });
    }
  }, [skillLevelId]);

  console.log(criterias);

  useEffect(() => {
    if (trainingId > 0) {
      searchTrainingSubSkill({ isGetAll: true, trainingId })
        .then(res => {
          setSubSkillChecks(res.items);
          const mapped = mapSubSkillCheck(subSkills, res.items);
          setChecks(mapped);
        })
        .catch(err => {
          console.log('Error in user fetch', err);
        });
    }
  }, [trainingId, subSkills]);

  useEffect(() => {
    if (skillLevelId && areaId) {
      //  && skillActivityId ) {
      searchSubSkill({ isGetAll: true, areaId, skillLevelId }) //, skillActivityId,
        .then(res => {
          setSubSkill(res.items.sort((a, b) => a.position - b.position));

          const mapped = mapSubSkillCheck(res.items, subSkillChecks);
          setChecks(mapped);
          console.log(mapped);
        })
        .catch(err => {
          console.log('Error in user fetch', err);
        });
    }
  }, [skillLevelId, skillActivityId, areaId]);

  const mapSubSkillCheck = (sSkills, sSkillChecks) => {
    return sSkills.map(subSkill => {
      let rs = subSkill;
      if (sSkillChecks.length > 0) {
        const checkedSkill = subSkillChecks.find(
          i => i.subSkillId == subSkill.id
        );
        console.log(checkedSkill);
        rs = {
          ...rs,
          trainerId: checkedSkill ? checkedSkill.trainerId : 0,
          trainingDate: checkedSkill ? checkedSkill.trainingDate : null
        };
      }
      return rs;
    });
  };

  const cssBase = {
    display: 'flex',
    gap: '10px',
    justifyContent: 'left',
    alignItems: 'baseline',
    marginBottom: '10px'
  };

  return (
    <>
      {
        <Card className="mb-3">
          <Card.Body className="bg-light" style={{ minHeight: '840px' }}>
            {criterias?.map((c, idx) => (
              <Row className="gx-2 gy-4" key={idx}>
                <Col md={6}>
                  <Row rows={12}>
                    <Form.Group controlId="title">
                      <Form.Label>
                        <span>{c.skillActivityRef + ". " + c.skillActivityName}</span>
                      </Form.Label>
                    </Form.Group>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gx-2 gy-3">
                    <Col md={4}>
                      <Form.Group controlId="title" style={cssBase}>
                        <Form.Label>Trainee</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="title" style={cssBase}>
                        <Form.Label>Trainer</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="title" style={cssBase}>
                        <Form.Label>Date</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                {checks.filter(x=>x.skillActivityId == c.skillActivityId)?.map((i, idx) => (
                  <Row key={idx}>
                    <Col md={6}>
                      <Row rows={12}>
                        <Form.Group controlId="title">
                          <Form.Label>
                            <span
                              style={{
                                display: 'inline-block',
                                minWidth: '25px',
                                textAlign: 'right',
                                paddingRight: '10px'
                              }}
                            >
                              {i.position}.{' '}
                            </span>
                            <span>{i.skillName}</span>
                          </Form.Label>
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row key={idx} className="gx-2 gy-3">
                        <Col md={4}>
                          <UserDropdownV2
                            disabled={true}
                            fieldName="trainee"
                            isMulti={false}
                            closeMenuOnSelect
                            handleChange={(key, value) => {
                              console.log('subSkillId: ' + i.id);
                              console.log(key, value);
                            }}
                            placeholder="Trainee"
                            value={trainee}
                          />
                        </Col>
                        <Col md={4}>
                          <UserDropdownV2
                            disabled={false}
                            fieldName="trainer"
                            isMulti={false}
                            closeMenuOnSelect
                            handleChange={(key, value) => {
                              let newChecks = checks.map(x => {
                                return i.id == x.id
                                  ? {
                                      ...x,
                                      trainerId: value.userId
                                    }
                                  : x;
                              });

                              setChecks(newChecks);
                              outSubChecks(newChecks);
                            }}
                            placeholder="Trainer"
                            value={i.trainerId}
                          />
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="title" style={cssBase}>
                            <InputDatePicker
                              column="trainingDate"
                              handleChange={(key, value) => {
                                let newChecks = checks.map(x => {
                                  return i.id == x.id
                                    ? {
                                        ...x,
                                        trainingDate: value == '' ? null : value
                                      }
                                    : x;
                                });

                                setChecks(newChecks);
                                outSubChecks(newChecks);
                              }}
                              selected={
                                i.trainingDate ? new Date(i.trainingDate) : null
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Row>
            ))}
          </Card.Body>
        </Card>
      }
    </>
  );
}

SubSkillCheckList.propTypes = {
  handleFilterChange: PropTypes.func,
  trainingId: PropTypes.number,
  trainee: PropTypes.number,
  skillLevelId: PropTypes.number,
  skillActivityId: PropTypes.number,
  areaId: PropTypes.number,
  outSubChecks: PropTypes.number
};
