import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import av3 from 'assets/img/team/3.jpg';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { createComment } from 'services/customer-service/scActionPlanComment';
import { getItemFromStore } from 'helpers/utils';
import { useSelector } from 'react-redux';
import CommentEditor from './CommentEditor';

const AddComment = ({ parentId, handleSubmit }) => {
  const [editing, setEditing] = useState(false);

  const [userName, setUser] = useState('');
  let name = useSelector(state => state.user.userName);
  useEffect(() => {
    if (!name) name = getItemFromStore('user', {}, sessionStorage)?.userName;
    if (name) setUser(name);
  }, [name]);

  const handleSave = async value => {
    await createComment({
      ActionPlanId: parentId,
      Description: value
    });
    handleSubmit();
    setEditing(false);
  };
  const handleCancel = async () => {
    setEditing(false);
  };

  return (
    <Row rows={12}>
      {editing ? (
        <CommentEditor
          userName={userName}
          handleSave={value => handleSave(value)}
          commentIn={''}
          handleCancel={handleCancel}
        />
      ) : (
        <Flex alignItems="center" className="border-top border-200 pt-3">
          {userName ? <Avatar name={userName} /> : <Avatar src={av3} />}
          <div
            style={{
              marginLeft: '12px',
              border: editing ? '' : 'solid 1px',
              width: '100%',
              borderRadius: '3px',
              padding: '5px',
              marginBottom: '5px',
              backgroundColor: 'white'
            }}
            onClick={() => {
              setEditing(true);
            }}
          >
            <span>Write a comment...</span>
          </div>
        </Flex>
      )}
    </Row>
  );
};

AddComment.propTypes = {
  parentId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func
};

export default AddComment;
