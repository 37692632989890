import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  getAtRiskODRs,
  getSupplierDeliveryChanges,
  getAgedODRs,
  getAgedAtRiskODRs,
  getNewODRs,
  getNewAtRiskODRs,
  getFaultSourceODRs
} from 'services/meeting-management-services/odrStageDashboardService';
import { getCollectionJobs, getNNCCustomers } from 'services/meeting-management-services/cusFocusSumaryService';
import {
  getNewCCFs,
  getMissingFeedbackRequiredInfoCCFs,
  getCriticalReturnCff
} from 'services/meeting-management-services/cusFocusSumaryService';
import CardNumberWidget from './CardNumberWidget';
import { searchActionPlan } from 'services/customer-service/scActionPlanService';
import { search as searchMetric } from 'services/meeting-management-services/cfDailyMetricService';
import CustomersFocusExportWidget from './CustomersFocusExportWidget';
import { CFType,CFName } from 'data/TempData';

const CustomersFocusWidget = ({ details }) => {
  const [atRisksODRs, setAtRisksODRs] = useState({ data: -1, url: '' });
  const [supplierDeliveryChanges, setSDC] = useState({ data: -1, url: '' });
  const [agedODRs, setAgedODRs] = useState({ data: -1, url: '' });
  const [newODRs, setNewODRs] = useState({ data: -1, url: '' });
  const [agedAtRiskODRs, setAgedAtRiskODRs] = useState({ data: -1, url: '' });
  const [newAtRiskODRs, setNewAtRiskODRs] = useState({ data: -1, url: '' });
  const [newCCFs, setNewCCFs] = useState({ data: -1, url: '' });
  const [missingInfoCcfs, setMissingInfoCcfs] = useState({ data: -1, url: '' });
  const [ncCustomers, setNcCustomers] = useState({ data: -1, url: '' });
  const [criticalReturnCcf, setCriticalReturnCcf] = useState({ data: -1, url: '' });
  const [collectionJobs, setCollectionJobs] = useState({ data: -1, url: '' });

  const [faultSource, setFaultSource] = useState([]);

  const [actionPlans, setActionPlans] = useState([]);
  const [metrics, setMetrixs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadActionPlans = () => {
    searchActionPlan({ isGetAll: true, meetingId: details.id })
      .then(res => {
        if (res) {
          setActionPlans(res.items);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const loadDailyMetric = () => {
    searchMetric({ dayNumber: 29 })
      .then(res => {
        if (res) {
          setMetrixs(res.items);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    Promise.all([
      getAtRiskODRs(),
      getSupplierDeliveryChanges(),
      getAgedODRs(),
      getNewODRs(),
      getNNCCustomers(),
      getNewCCFs(),
      getFaultSourceODRs(),
      getAgedAtRiskODRs(),
      getNewAtRiskODRs(),
      getMissingFeedbackRequiredInfoCCFs(),
      getCriticalReturnCff(),
      getCollectionJobs(),
      loadActionPlans(),
      loadDailyMetric()
    ])
      .then(
        ([
          atRisks,
          sdc,
          agedODRs,
          newODRs,
          ncCustomers,
          newCCFs,
          faultSource,
          agedAtRiskODRs,
          newAtRiskODRs,
          missingInfoCcfsRs,
          criticalReturnCcfsRs,
          collectionJobsRs
        ]) => {
          setAtRisksODRs(atRisks);
          setSDC(sdc);
          setAgedODRs(agedODRs);
          setNewODRs(newODRs);
          setNewCCFs(newCCFs);
          setNcCustomers(ncCustomers);
          setFaultSource(faultSource);
          setAgedAtRiskODRs(agedAtRiskODRs);
          setNewAtRiskODRs(newAtRiskODRs);
          setMissingInfoCcfs(missingInfoCcfsRs);
          setCriticalReturnCcf(criticalReturnCcfsRs);
          setCollectionJobs(collectionJobsRs)
        }
      )
      .finally(() => {
        setIsLoading(false); // Set loading to false once all data has been loaded
      });
  }, [details]);

  return (
    <>
      <Row>
        {!isLoading && (
          <CustomersFocusExportWidget
            details={details}
            atRisksODRs={atRisksODRs}
            agedODRs={agedODRs}
            newODRs={newODRs}
            newCCFs={newCCFs}
            ncCustomers={ncCustomers}
            faultSource={faultSource}
            newAtRiskODRs={newAtRiskODRs}
            agedAtRiskODRs={agedAtRiskODRs}
            actionPlans={actionPlans}
            missingInfoCcfs={missingInfoCcfs}
            isLoading={isLoading}
            metrics={metrics}
          />
        )}
      </Row>
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="At Risks ODRs"
            data={atRisksODRs.data}
            loading={atRisksODRs.data == -1}
            url={atRisksODRs.url}
            faultSources={faultSource}
            cfType={CFType.AtRisksODRs}
            metrics={metrics}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Supplier Delivery Changes"
            data={supplierDeliveryChanges.data}
            loading={supplierDeliveryChanges.data == -1}
            url={supplierDeliveryChanges.url}
            faultSources={faultSource}
            cfType={CFType.SupplierDeliveryChanges}
            metrics={metrics}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Aged ODRs"
            data={agedODRs.data}
            loading={agedODRs.data == -1}
            url={agedODRs.url}
            faultSources={faultSource}
            cfType={CFType.AgedODRs}
            metrics={metrics}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New ODRs"
            data={newODRs.data}
            loading={newODRs.data == -1}
            url={newODRs.url}
            faultSources={faultSource}
            cfType={CFType.NewODRs}
            metrics={metrics}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New CCFs"
            data={newCCFs.data}
            loading={newCCFs.data == -1}
            url={newCCFs.url}
            faultSources={faultSource}
            cfType={CFType.NewCCFs}
            metrics={metrics}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title={CFName.NcCustomers}
            data={ncCustomers.data}
            loading={ncCustomers.data == -1}
            url={ncCustomers.url}
            cfType={CFType.NcCustomers}
            metrics={metrics}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Missing feedback required information for CCFs"
            data={missingInfoCcfs.data}
            loading={missingInfoCcfs.data == -1}
            url={missingInfoCcfs.url}
            cfType={CFType.MissingInfoCcfs}
            metrics={metrics}
          />
        </Col> 
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Critical Return to be Checked"
            data={criticalReturnCcf.data}
            loading={criticalReturnCcf.data == -1}
            url={criticalReturnCcf.url}
            cfType={CFType.CcfCriticalReturn}
            metrics={metrics}
            showChart={true}
            clickAbleNumber={false}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title={CFName.CollectionJobs}
            data={collectionJobs.data}
            loading={collectionJobs.data == -1}
            url={collectionJobs.url}
            clickAbleNumber={false}
            cfType={CFType.CollectionJobs}
            metrics={metrics}
          />
        </Col>
      </Row>
    </>
  );
};

CustomersFocusWidget.propTypes = {
  details: PropTypes.object
};

export default CustomersFocusWidget;
