import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { search } from 'services/skill-matric-services/smAreaService';

const SmAreaDropdown = ({
  isDisabled,
  title = 'Area',
  fieldName = 'areaId',
  closeMenuOnSelect = true,
  isMulti = false,
  param,
  placeholder = 'Area',
  handleChange,
  selectedOptions
}) => {
  return (
    <>
      <BaseDropdownV2
        disabled={isDisabled}
        fieldName={fieldName}
        title={title}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        placeholder={placeholder}
        handleChange={handleChange}
        getSource={search}
        sourceParam={param}
        mapToOption={res => {
          return res?.items.map(i => {
            return { ...i, value: i.id, label: i.name };
          });
        }}
        value={selectedOptions}
      />
    </>
  );
};

SmAreaDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  closeMenuOnSelect:  PropTypes.func,
  isMulti:  PropTypes.bool,
  param:  PropTypes.object,
  placeholder:  PropTypes.string,
  handleChange:  PropTypes.func,
  fieldName:  PropTypes.string,
  selectedOptions:  PropTypes.object,
};

export default SmAreaDropdown;
