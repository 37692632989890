import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchComments } from 'services/customer-service/scActionPlanComment';

const Comments = ({ parentId, loadComment, reload }) => {
  const [comments, setComments] = useState([]);
  useEffect(() => {
    fetchData(parentId);
  }, [parentId, reload]);

  const defaultRQ = new BaseTableRQ(1, 5);
  const fetchData = async id => {
    try {
      const res = await searchComments({ ...defaultRQ, ActionPlanId: id });
      if (res) {
        setComments(res.items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [idEditting, setIdEditting] = useState(0);
  const handleEditting = async id => {
    setIdEditting(id);
    fetchData(parentId);
  };

  return comments ? (
    <>
      {comments.map(({ id, createdBy, description, updated }) => (
        <div key={id}>
        <Comment
          id={id}
          idEditting={idEditting}
          createdBy={createdBy}
          description={description}
          updated={updated}
          handleEditting={handleEditting}
        /></div>
      ))}
      {!!loadComment && (
        <a className="fs--1 text-700 d-inline-block mt-2" href="#!">
          Load more comments ({loadComment})
        </a>
      )}
    </>
  ) : (
    ''
  );
};

Comments.propTypes = {
  parentId: PropTypes.string,
  comments: PropTypes.array,
  loadComment: PropTypes.string,
  reload: PropTypes.bool
};

export default Comments;
