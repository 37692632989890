import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { getOptionsByKey } from 'services/optionLists';

const SmDepartmentDropdown = ({
  closeMenuOnSelect = true,
  fieldName={fieldName},
  isMulti = false,
  placeholder = 'Department',
  handleChange,
  selectedOptions,
  styles,
}) => {
  return (
    <>
      <BaseDropdownV2
        fieldName={fieldName}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        placeholder={placeholder}
        handleChange={handleChange}
        getSource={getOptionsByKey}
        sourceParam={"emplas.po.department"}
        mapToOption={res => {
          return res?.map(i => {
            return { ...i, value: i.id, label: i.name };
          });
        }}
        value={selectedOptions}
        styles={styles}
      />
    </>
  );
};

SmDepartmentDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  closeMenuOnSelect:  PropTypes.func,
  isMulti:  PropTypes.bool,
  param:  PropTypes.object,
  placeholder:  PropTypes.string,
  handleChange:  PropTypes.func,
  fieldName:  PropTypes.string,
  selectedOptions:  PropTypes.object,
  styles: PropTypes.object,
};

export default SmDepartmentDropdown;
