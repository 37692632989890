import React  from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';
import SmSectionDropdown from 'components/dropdowns/SkillMatrix/SmSectionDropdown';
import DepartmentDropdown from 'components/dropdowns/CustomerServices/DepartmentDropdown';
import PropTypes from 'prop-types';

export default function TrainingRecordInfo({ filter, handleFilterChange }) {
  return (
    <>
      <Row className="g-3 mb-3">
        <Card className="mb-3">
          <Card.Body className="bg-light">
            <LeftInfoItem
              title={'Trainee'}
              component={
                <UserDropdownV2
                  disabled={filter?.id > 0}
                  fieldName="trainee"
                  isMulti={false}
                  handleChange={handleFilterChange}
                  placeholder="Trainee"
                  value={filter?.trainee}
                />
              }
            />
            <LeftInfoItem
              title={'Start Date:'}
              component={
                <InputDatePicker
                  column="startDate"
                  selected={
                    filter?.startDate ? new Date(filter?.startDate) : new Date()
                  }
                  handleChange={handleFilterChange}
                />
              }
            />
            <LeftInfoItem
              title={'Section:'}
              component={
                <SmSectionDropdown
                  isDisabled={filter?.id > 0}
                  handleChange={handleFilterChange}
                  selectedOptions={filter?.sectionId}
                />
              }
            />

            <LeftInfoItem
              title={'Department'}
              component={
                <DepartmentDropdown
                  fieldName="department"
                  handleChange={handleFilterChange}
                  selectedOptions={filter?.department}
                  isMulti={false}
                  value={filter?.department}
                />
              }
            />
          </Card.Body>
        </Card>
        {filter?.id && (
          <Card className="mb-3">
            <Card.Body className="bg-light">
              <Row className="gx-2 gy-3" style={{ marginBottom: '10px' }}>
                <Col md={12}>
                  <Row rows={12}>
                    <Form.Group controlId="title">
                      <Form.Label>Comment/ Feedback</Form.Label>
                    </Form.Group>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="gx-2 gy-3">
                    <Col md={12}>
                      <Form.Control
                        as="textarea"
                        name="comment"
                        rows="6"
                        placeholder=""
                        value={filter?.comment}
                        onChange={e =>
                          handleFilterChange('comment', e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        {filter?.id && (
          <Card className="mb-3">
            <Card.Body className="bg-light">
              <LeftInfoItem
                title={'Trainee Signature'}
                component={
                  <Form.Control
                    type="text"
                    name="traineeSignature"
                    placeholder=""
                    value={filter?.traineeSignature}
                    onChange={e =>
                      handleFilterChange('traineeSignature', e.target.value)
                    }
                  />
                }
              />

              <LeftInfoItem
                title={'Trainee Signature Date'}
                component={
                  <InputDatePicker
                    column="traineeSignatureDate"
                    selected={filter?.traineeSignatureDate ? new Date(filter?.traineeSignatureDate): undefined}
                    handleChange={handleFilterChange}
                  />
                }
              />

              <LeftInfoItem
                title={'Trainer Officer Signature'}
                component={
                  <Form.Control
                    type="text"
                    name="trainerSignature"
                    placeholder=""
                    value={filter?.trainerSignature}
                    onChange={e =>
                      handleFilterChange(
                        'trainerSignature',
                        e.target.value
                      )
                    }
                  />
                }
              />

              <LeftInfoItem
                title={'Trainer Officer Signature Date'}
                component={
                  <InputDatePicker
                    column="trainerSignatureDate"
                    selected={filter?.trainerSignatureDate ? new Date(filter?.trainerSignatureDate): undefined}
                    handleChange={handleFilterChange}
                  />
                }
              />
            </Card.Body>
          </Card>
        )}
      </Row>
    </>
  );
}
TrainingRecordInfo.propTypes = {
  filter: PropTypes.object,
  handleFilterChange:  PropTypes.func,
  meetingId: PropTypes.string,
  title: PropTypes.string
};

const LeftInfoItem = ({ title, component }) => {
  return (
    <Row className="gx-2 gy-3" style={{ marginBottom: '10px' }}>
      <Col md={6}>
        <Row rows={12}>
          <Form.Group controlId="title">
            <Form.Label>{title}</Form.Label>
          </Form.Group>
        </Row>
      </Col>
      <Col md={6}>
        <Row className="gx-2 gy-3">
          <Col md={12}>{component}</Col>
        </Row>
      </Col>
    </Row>
  );
};

LeftInfoItem.propTypes = {
  component: PropTypes.object,
  title: PropTypes.string,
};



// const LeftInput = ({ title, value }) => {
//   return (
//     <Row className="gx-2 gy-3">
//       <Col md={6}>
//         <Row rows={12}>
//           <Form.Group controlId="title">
//             <Form.Label>{title}</Form.Label>
//           </Form.Group>
//         </Row>
//       </Col>
//       <Col md={6}>
//         <Row className="gx-2 gy-3">
//           <Col md={12}>
//             <Form.Group
//               controlId="title"
//               style={{
//                 display: 'flex',
//                 gap: '10px',
//                 justifyContent: 'left',
//                 alignItems: 'baseline',
//                 marginBottom: '10px'
//               }}
//             >
//               <Form.Control
//                 type="text"
//                 name="trainee"
//                 placeholder=""
//                 value={value}
//                 onChange={e => {
//                   // handleChange('trainee', e.target.value, idx);
//                 }}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//       </Col>
//     </Row>
//   );
// };
