import { useCallback } from 'react';
import {
  saveSettings,
  getSetting
} from 'services/base-services/userSettingServices';
import { swalToastSuccess } from 'components/utilities/SwalToastUtil';

export const usePaginationBe = (paging, total, loadData, filter, setFilter) => {
  const gotoPage = useCallback(
    index => {
      index += 1;
      if (index <= 0) return;
      const newPaging = { ...paging, pageNum: index };
      loadData(newPaging);
    },
    [paging, loadData]
  );

  const nextPage = useCallback(() => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    const newPaging = { ...paging, pageNum: page };
    loadData(newPaging);
  }, [paging, total, loadData]);

  const previousPage = useCallback(() => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    const newPaging = { ...paging, pageNum: page };
    loadData(newPaging);
  }, [paging, loadData]);

  const handleSort = useCallback(
    col => {
      if (!col) return;

      const savedRq = { ...filter };

      let request = {
        ...paging,
        sortBy: col.id,
        desc: col.isBeSortedDesc === undefined ? false : col.isBeSortedDesc
      };

      if (savedRq.sortBy === col.id) {
        request.desc = !savedRq.desc;
      }

      const updatedFilter = {
        ...filter,
        sortBy: col.id,
        desc: request.desc
      };
      setFilter(updatedFilter);

      loadData(request);
    },
    [filter, paging, setFilter, loadData]
  );

  return { gotoPage, nextPage, previousPage, handleSort };
};

export const useSortBe = (originColumns, colSort, setColumns, columns) => {
  const reNewColumns = useCallback(() => {
    const updatedCols = originColumns.map(i => {
      const col = columns.find(x => x.accessor === i.accessor);
      if (colSort && colSort.sortBy === i.accessor) {
        return {
          ...i,
          isVisible: col ? col.isVisible : i.isVisible,
          isBeSortedDesc: colSort.isBeSortedDesc,
          isBeSorted: colSort.isBeSorted
        };
      }
      return {
        ...i,
        isVisible: col ? col.isVisible : i.isVisible
      };
    });
    setColumns(updatedCols);
  }, [colSort, originColumns, columns, setColumns]);

  return { reNewColumns };
};

export const useHanleConfig = (key, filter, mapRQData, defaultRQ, defaultPaging, loadData) => {
  const saveConfig = useCallback(() => {
    console.log('data to save:', filter);
    let rq = mapRQData(filter);
    let mappedRq = { ...defaultRQ, ...rq };
    const jsonString = JSON.stringify(mappedRq);
    console.log(mappedRq);
    saveSettings(key, jsonString);
    swalToastSuccess('success');
  }, [key, filter, mapRQData, defaultRQ]);

  const getConfig = useCallback(() => {
    getSetting(key).then(response => {
      if (response && response.success) {
        let mapRq = mapRQData(JSON.parse(response.data.settingValue));
        let rq = defaultPaging({ ...defaultRQ, ...mapRq });
        loadData(rq);
      } else {
        loadData({ ...defaultRQ });
      }
    });
  }, [key, filter, mapRQData, defaultRQ, defaultPaging, loadData]);

  return { saveConfig, getConfig };
};
