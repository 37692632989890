import axios from 'axios';
import { API2_HOST } from '../helpers/urlHelper';

axios.defaults.withCredentials = true;

const loadProjectData = async () => {

    const res = await axios({
      method: 'get',
      url: `${API2_HOST}/jira-project-management/project`
    }).catch(err => {
      console.error('projectData', err);
    });

    return res.status === 200
      ? res.data
      : {  };
};

const loadStatusData = async () => {

  const res = await axios({
    method: 'get',
    url: `${API2_HOST}/jira-project-management/status`
  }).catch(err => {
    console.error('loadBoardData', err);
  });

  return res.status === 200
    ? res.data
    : {  };
};

const loadBoardData = async (projectId) => {

    const res = await axios({
      method: 'get',
      url: `${API2_HOST}/jira-project-management/board?projectId=${projectId}`
    }).catch(err => {
      console.error('loadBoardData', err);
    });

    return res.status === 200
      ? res.data
      : {  };
};

const loadBoardSprintData = async (boardId) => {

    const res = await axios({
      method: 'get',
      url: `${API2_HOST}/jira-project-management/board/${boardId}/sprint`
    }).catch(err => {
      console.error('loadBoardSprintData', err);
    });

    return res.status === 200
      ? res.data
      : {  };
};

const loadSprintData = async (sprintId) => {

    const res = await axios({
      method: 'get',
      url: `${API2_HOST}/jira-project-management/sprint/${sprintId}`
    }).catch(err => {
      console.error('loadSprintData', err);
    });

    return res.status === 200
      ? res.data
      : {  };
};

export {
    loadProjectData,
    loadStatusData,
    loadBoardData,
    loadBoardSprintData,
    loadSprintData,
}