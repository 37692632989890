import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import MainForm from './MainForm';

const NCModal = ({ setShow, show, modalInfo, depts }) => {
  const { id, customerCode, jobNo, stageId } = modalInfo;

  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)}>
      <MainForm
        id={id}
        customerCode={customerCode}
        jobNo={jobNo}
        stageId={stageId}
        depts={depts}
      />
    </Modal>
  );
};

NCModal.propTypes = {
  depts: PropTypes.array,
  setShow: PropTypes.func,
  show: PropTypes.bool,
  modalInfo: PropTypes.object
};

export default NCModal;
