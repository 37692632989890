import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchUsers } from 'services/skill-matric-services/smTrainingRecordService';
import TrainingRecordTable from './TrainingRecordTable';
import TrainingRecordTableHeader from './TrainingRecordTableHeader';
import TraningRecordFilter from './TraningRecordFilter';
import PropTypes from 'prop-types';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import { getNotCompleteUsers } from 'services/skill-matric-services/smTrainingRecordService';
import { getAllEvaUsers } from 'services/userServices';

const RunningTrainingRecord = ({ meetingId }) => {
  const defaultRQ = { ...new BaseTableRQ(1, 25), meetingId: meetingId };
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [resetTable, setResetTable] = useState(false);
  const [filter, setFilter] = useState({});
  useEffect(() => {
    setIsFilter(false);
  }, []);

  useEffect(() => {
    loadUsers({ ...paging });
  }, []);

  const handleSubmit = async () => {
    setResetTable(!resetTable);
    let rq = await mapRQData(filter);
    loadUsers({ ...defaultRQ, ...rq });
  };

  const mapRQData = async data => {
    if (data) {
      let mapRq = { ...data };

      if (data?.startDate) {
        mapRq.startDate = data.startDate.toISOString();
      }

      mapRq.areaId = data?.areaId?.id;
      mapRq.sectionId = data?.sectionId?.id;
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      mapRq.skillActivityId = data?.skillActivityId?.id;
      mapRq.skillLevelId = data?.skillLevelId?.id;
      mapRq.traineeIds = joinDataMultiDropdown(data?.trainee);

      if (mapRq.keyword) {
        try {
          const res = await getAllEvaUsers();
          const keyword = mapRq.keyword.toLowerCase();
          const filteredUsers = res.filter(
            i =>
              i.name.toLowerCase().includes(keyword) ||
              i.email.toLowerCase().includes(keyword)
          );
          mapRq.traineeIds = filteredUsers.map(x => x.userId).join(',');
          if (!mapRq.traineeIds || mapRq.traineeIds.length == 0) {
            mapRq.traineeIds = '-1'
          }
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      }

      return mapRq;
    }

    return null;
  };

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadUsers(paging);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadUsers(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadUsers(paging);
  };

  const loadUsers = pagingRq => {
    getNotCompleteUsers(pagingRq).then(res => {
      if (res) {
        searchUsers({ ...pagingRq, ids: res.items })
          .then(resUsers => {
            if (resUsers) {
              setUsers(resUsers.items);
              setTotal(res.total);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });

    setPaging(pagingRq);
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
    setFilter({});
  };

  const handleChange = (field, value) => {
    console.log(field, value);
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleClear = () => {
    setFilter(undefined);
    setResetTable(!resetTable);
    loadUsers({ ...defaultRQ });
  };

  return (
    <Card>
      <Row style={{ padding: '10px' }}>
        <TrainingRecordTableHeader
          style={{ padding: '10px' }}
          toggelFilter={toggelFilter}
        />
        <Col lg={isFilter ? 9 : 12}>
          <TrainingRecordTable
            data={users}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            resetTable={resetTable}
          />
        </Col>
        {isFilter && (
          <Col lg={3}>
            <TraningRecordFilter
              handleChange={handleChange}
              formData={filter}
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

RunningTrainingRecord.propTypes = {
  meetingId: PropTypes.string
};

export default RunningTrainingRecord;
