import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import SaveBtn from './SaveBtn';
import { handleUpdate } from 'helpers/newCritical';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';

const LoadingForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState({
    vehicleAllocationOk: false,
    itemsLoaded: false,
    odrRequired: false,
    loadDispatched: false,
    handoverComplete: false
  });

  useEffect(() => {
    if (job) setLoadingItem(prev => ({ ...prev, ...job }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'DespatchLoadingData');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="vehicleAllocation">
              <Form.Check
                type="checkbox"
                id="vehicle-allocation-ok"
                label="Vehicle allocation OK?"
                checked={loadingItem.vehicleAllocationOk}
                onChange={e =>
                  setLoadingItem(prev => ({
                    ...prev,
                    vehicleAllocationOk: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="itemsLoaded">
              <Form.Check
                type="checkbox"
                id="items-loaded"
                label="Items loaded?"
                checked={loadingItem.itemsLoaded}
                onChange={e =>
                  setLoadingItem(prev => ({
                    ...prev,
                    itemsLoaded: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="odr">
              <Form.Check
                type="checkbox"
                id="odr-required"
                label="ODR Required?"
                checked={loadingItem.odrRequired}
                onChange={e =>
                  setLoadingItem(prev => ({
                    ...prev,
                    odrRequired: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="dispatched">
              <Form.Check
                type="checkbox"
                id="load-dispatched"
                label="Load dispatched?"
                checked={loadingItem.loadDispatched}
                onChange={e =>
                  setLoadingItem(prev => ({
                    ...prev,
                    loadDispatched: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} id="handover">
            <Form.Check
              type="checkbox"
              id="handover-complete"
              label="Handover complete?"
              checked={loadingItem.handoverComplete}
              onChange={e =>
                setLoadingItem(prev => ({
                  ...prev,
                  handoverComplete: e.target.checked
                }))
              }
            />
          </Form.Group>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="Saving..."
                handleClick={() =>
                  handleUpdate(loadingItem, setLoading, 'DespatchLoadingData')
                }
              />
              <SaveBtn
                loading={loading}
                text="Save & Move to Despatch Transport"
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(loadingItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

LoadingForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default LoadingForm;
