import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import {
  patchMeeting,
  getMeeting
} from 'services/customer-service/scMeetingService';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import { RRule } from 'rrule';
import moment from 'moment';
import { weekday } from './RecurrenceModal';
import { RecurrenceModal } from './RecurrenceModal';
import { parseSourceToRuleParam } from 'helpers/rRuleHelper';

const EditMeetingModal = ({
  isOpenScheduleModal,
  setIsOpenScheduleModal,
  modalEventContent,
  handleSubmit
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [showRecurrence, setShowRecurrence] = useState(false);

  const utcStart = moment.utc(Date.now());
  const wDay = weekday.find(i => i.id.weekday == utcStart.day());
  const byweekday = wDay ? [wDay.id] : [];

  const [rRule, setRRule] = useState(undefined);

  useEffect(() => {
    const content = modalEventContent.event;
    if (content) {
      console.log(content.id);

      fetchData(content.id);
    }
  }, [modalEventContent]);

  useEffect(() => {
    if (
      formData &&
      formData.repeatRule !== undefined &&
      formData.repeatRule !== ''
    ) {
      try {
        const newRParam= parseSourceToRuleParam(formData.repeatRule);
        newRParam.dtstart = formData.start;
        setRRule(newRParam);
      } catch (error) {
        console.error('Error parsing rrule string:', error);
      }
    }
  }, [formData]);

  const fetchData = async id => {
    setShowRecurrence(false);
    setRRule(undefined);
    await getMeeting(id).then(res => {
      if (res?.data && res?.data.id > 0) {
        const content = res?.data;
        const data = {
          ...content,
          start: new Date(
            moment.utc(content.start).local().format('YYYY-MM-DD HH:mm:ss')
          ), //new Date(content.startStr), // new Date(content.extendedProps?.startStr),
          end: content.end
            ? new Date(
                moment.utc(content.end).local().format('YYYY-MM-DD HH:mm:ss')
              )
            : '' //content.endStr ? new Date(content.endStr) : '', // content.extendedProps?.endStr ? new Date(content.extendedProps?.endStr) : "",
        };

        if (data && data.repeatRule !== undefined && data.repeatRule !== '') {
          try {
            const newRrule = parseSourceToRuleParam(data.repeatRule);
            setRRule(newRrule);
            setShowRecurrence(true);
          } catch (error) {
            console.error('Error parsing rrule string:', error);
          }
        }
        setFormData(data);
        return;
      }
    });
  };

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleDropdown = (field, value) => {
    console.log(field, value);
    setFormData({ ...formData, [field]: value.map(i => i.userId) });
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };

  const setScheduleStartDate = date => {
    const utcStrTime = date ? date.toISOString() : '';
    setFormData({ ...formData, startStr: utcStrTime, start: date });
  };

  const onSubmit = async e => {
    e.preventDefault();
    // Default not change
    if (typeof formData.participants === 'string') {
      formData.participants = undefined;
    }
    if (typeof formData.managedBy === 'string') {
      formData.managedBy = undefined;
    }

    var repeatRule = {...rRule};
    if (showRecurrence) {
      repeatRule = { ...rRule };
      repeatRule.byweekday = rRule.byweekday?.map(i => i.weekday);
    }
    
    // await postMeeting({ id: uuid(), ...formData, recurrence });

    await patchMeeting(formData.id, {
      ...formData,
      repeatRule: showRecurrence ? repeatRule : undefined
    });
    handleSubmit();
    setIsOpenScheduleModal(false);
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData
      });
    }
  }, [isOpenScheduleModal]);

  return (
    <Modal
      size="lg"
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Edit Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0 required">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              value={formData?.title ? formData?.title : ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Start Date</Form.Label>
            <DatePicker
              selected={formData?.start ? formData?.start : undefined}
              calendarStartDay={1}
              onChange={date => {
                setScheduleStartDate(date);
              }}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">End Date</Form.Label>
            <DatePicker
              calendarStartDay={1}
              selected={formData.end}
              onChange={date => {
                const utcStrTime = date ? date.toISOString() : '';
                setFormData({ ...formData, endStr: utcStrTime, end: date });
              }}
              required={!formData.allDay ? formData.allDay : true}
              className="form-control"
              placeholderText="MM-DD-YYYY H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group controlId="allDay">
            <Form.Check
              type="checkbox"
              id="allDay"
              label="All Day"
              name="allDay"
              checked={formData.allDay ? formData.allDay : false}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="frequency">
            <Form.Check
              type="checkbox"
              id="repeat"
              label="Repeat"
              name="repeat"
              checked={showRecurrence}
              onChange={() => {
                setShowRecurrence(!showRecurrence);
                if (rRule !== undefined) {
                  setRRule(rRule);
                } else {
                  setRRule(
                    {
                      freq: RRule.WEEKLY,
                      dtstart: new Date(
                        Date.UTC(
                          utcStart.year(),
                          utcStart.month(),
                          utcStart.date(),
                          utcStart.hour(),
                          utcStart.minute(),
                          utcStart.second()
                        )
                      ),
                      byweekday: byweekday
                    }
                  );
                }
              }}
            />
          </Form.Group>

          {showRecurrence == true ? (
            <RecurrenceModal
              isShow={showRecurrence}
              setRRule={r => {
                setRRule(r);
              }}
              rRuleParam={rRule}
              rRule={rRule}
              setScheduleStartDate={setScheduleStartDate}
            />
          ) : null}
          <Form.Group className="mb-3" controlId="meetingLink">
            <Form.Label className="fs-0">Meeting Link</Form.Label>
            <Form.Control
              type="text"
              name="meetingLink"
              value={formData?.meetingLink ? formData.meetingLink : ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="venue">
            <Form.Label className="fs-0">Venue</Form.Label>
            <Form.Control
              type="text"
              name="venue"
              value={formData?.venue ? formData.venue : ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Col md="12">
            <UserDropdownV2
              label="Participants persons"
              fieldName="participants"
              handleChange={handleDropdown}
              placeholder="Participants persons"
              value={formData?.participants}
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Managed By"
              fieldName="managedBy"
              handleChange={handleDropdown}
              placeholder="Managed By"
              value={formData?.managedBy}
            />
          </Col>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description ? formData.description : ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Link
            to={`/customer-service/meetings/${formData.id}`}
            className="me-3 text-600"
          >
            Details
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditMeetingModal.propTypes = {
  modalEventContent: PropTypes.object,
  handleSubmit: PropTypes.func,
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array
};

export default EditMeetingModal;
