import { parseUserToDropdownValue } from 'services/userServices';
import { API2_HOST } from '../../helpers/urlHelper';
import moment from 'moment';
import {
  previewDocument,
  buildTableXml,
  escapeXml,
  getBlob
} from 'helpers/wordExportHelper';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { CFType, progresses } from 'data/TempData';
import { getAllEvaUsers } from 'services/userServices';
import {
  swalToastError,
  swalToastSuccess
} from 'components/utilities/SwalToastUtil';
import { parseHtmlToText } from './meetingReportService';

const buildParamOfAttachment = async data => {
  const ccfByDeptResultsXml = buildCcfs(data.newCCFs.data
    , data.faultSource)
  const nNCOdrCcfDdscHeaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'ODRS Aged New', value: 'odrsAgedNew' },
    { headerText: 'CCFS', value: 'ccfs' },
    { headerText: 'DDCs', value: 'ddcs' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  // nncCCFs,
  // nncOdrs

  const nNCOdrCcfDdscRows = data.faultSource?.map(x => {
    let ccfCount = data.nncCCFs.data.filter(a => a.faultSource == x.id).length;
    let odrCount = data.nncOdrs.data.filter(a => a.faultSource == x.id).length;
    let ddcCount = data.nncDdc.data.filter(a => a.faultSource == x.id).length;
    return {
      department: x.name,
      odrsAgedNew: odrCount,
      ccfs: ccfCount,
      ddcs: ddcCount,
      comments: ''
    };
  });
  


  nNCOdrCcfDdscRows?.push({
    department: 'Total',
    odrsAgedNew: nNCOdrCcfDdscRows.reduce(
      (total, item) => total + item.odrsAgedNew,
      0
    ),
    ccfs: nNCOdrCcfDdscRows.reduce((total, item) => total + item.ccfs, 0),
    ddcs: nNCOdrCcfDdscRows.reduce((total, item) => total + item.ddcs, 0),
    comments: ''
  });

  const nNCOdrCcfDdscXml = buildTableXml(
    nNCOdrCcfDdscHeaders,
    nNCOdrCcfDdscRows
  );

  const actionPlanHeaders = [
    { headerText: 'Action Number', value: 'id' },
    { headerText: 'Description of Action', value: 'description' },
    { headerText: 'Date added', value: 'created' },
    { headerText: 'Assigned to', value: 'requestParticipants' },
    { headerText: 'Target Date for completion', value: 'targetDate' },
    {
      headerText: 'Customer Communication Status',
      value: 'customerCommStatus'
    },
    { headerText: 'Progress Status', value: 'progress' },
    { headerText: 'Additional Comments', value: 'additionalComments' },
    { headerText: 'Feedback Generated', value: 'feedbackGenerated' }
  ];

  const users = await getAllEvaUsers();

  const actionPlanXml = buildTableXml(
    actionPlanHeaders,
    await Promise.all(
      data.actionPlans.map(async plan => {
        const participants = splitStringMapValueToDropDown(
          plan.requestParticipants,
          users
        )
          .map(user => user.name)
          .join(', ');
        const status = splitStringMapValueToDropDown(
          plan.progress.toString(),
          progresses
        )
          .map(x => x.label)
          .join(', ');

        const cmts = data.comments.filter(x => x.actionPlanId == plan.id);
        let commentText = '';
        if (cmts.length > 0) {
          const text = parseHtmlToText(cmts[0].description);
          const userName = users.find(u => u.userId == cmts[0].createdBy).name;
          commentText = userName + ' : ' + text;
        }

        return {
          ...plan,
          created: moment(plan.created).format('DD/MM/YYYY'),
          targetDate: moment(plan.targetDate).format('DD/MM/YYYY'),
          description: parseHtmlToText(plan.description),
          requestParticipants: participants,
          progress: status,
          additionalComments: commentText
        };
      })
    )
  );

  const concernHeaders = [
    { headerText: 'Action Number', value: 'actionNo' },
    { headerText: 'Concern', value: 'concern' },
    { headerText: 'Date added', value: 'dateAdded' },
    { headerText: 'Assigned to', value: 'assignedTo' },
    {
      headerText: 'Description of containment',
      value: 'descriptionOfContainment'
    },
    { headerText: 'Date of implementation', value: 'dateOfImplementation' },
    {
      headerText: 'Corrective/Preventative Action',
      value: 'correctivePreventativeAction'
    },
    { headerText: 'Customer Com Status', value: 'customerCommStatus' },
    { headerText: 'Progress Status', value: 'progressStatus' },
    { headerText: 'Additional Comments', value: 'additionalComments' }
  ];

  const concernRows = [];

  const concernXml = buildTableXml(concernHeaders, concernRows);

  let attendees = (await parseUserToDropdownValue(data.participants))
    .map(participant => participant.name.trim())
    .join('\n');
  const param = {
    date: moment().format('DD/MM/YYYY'),
    attendees,
    sumaryXml: buildSumaryInfoXML(data),
    agedODRXml: buildAgedODR(
      data.agedODRs?.data,
      data.faultSource,
      data.agedAtRiskODRs?.data,
      data.supplierDeliveryChangesAgedODRs?.data
    ),
    newODRsXml: buildNewODR(
      data.newODRs?.data,
      data.faultSource,
      data.newAtRiskODRs?.data,
      data.supplierDeliveryChangesNewODRs?.data
    ),
    ccfByDeptResultsXml: ccfByDeptResultsXml,
    newNCritialCustomersXml: buildNNCCustomerXml(data.ncCustomers),
    nNCOdrCcfDdscXml: nNCOdrCcfDdscXml,
    actionPlanXml: actionPlanXml,
    concernXML: concernXml,
    summaryOftheWeeksDataXml: buildSummaryXml( data
      // data.oneWeekODRs?.data,
      // data.oneWeekAtRiskOdrs?.data,
      // data.thisWeekNewCcfs?.data,
      // data.ncCustomers?.data,
      // data.thisWeekTradeDDC?.data,
      // data.thisWeekCommercialDDC?.data
    )
  };
  return param;
};

const templatePath = '/files/TemplateCFMeeting.docx';
const fileName = 'Summary_Report.docx';
const exportMsWork = async data => {
  const param = await buildParamOfAttachment(data);
  previewDocument(templatePath, param, fileName);
};

const sentMailWithAttactment = async data => {
  const param = await buildParamOfAttachment(data);
  const blob = await getBlob(templatePath, param);
  const formData = new FormData();
  formData.append('files', blob, fileName);

  let participantsArray = data?.participants?.split(',');
  if (!participantsArray || participantsArray.length == 0) {
    swalToastError('Participants persons are empty');
    return;
  }

  participantsArray.forEach(item => {
    formData.append('toByIds', item);
  });
  formData.append('toByEmails', 'productionmeeting@emplas.co.uk');
    formData.append('ccByEmails', 'duc.nguyen@emplas.co.uk');
    formData.append('ccByEmails', 'trung.tran@emplas.co.uk');
    formData.append('ccByEmails', 'quang.dinh@emplas.co.uk');
    formData.append('fromByIds', 2);
  formData.append('fromByIds', 2);

  const bodyRes = await fetch('/files/CustomerFocusMailTemplate.html');
  if (bodyRes.ok) {
    let bodyText = await bodyRes.text();
    const formattedDate = moment().format('DD/MM/YYYY');
    const currentYear = moment().format('YYYY');
    const summaryObj = buildObjectSummary(data);
    
    bodyText = bodyText
      .replace('{{current_date}}', formattedDate)
      .replace('{{current_year}}', currentYear)
      .replace('{{At Risks ODRs}}', wrapWithAtag(summaryObj.atRiskOdrsUrl, summaryObj.atRiskOdrs))
        .replace('class="arocss"',summaryObj.atRiskOdrs > 0? 'class="red"': '')
      .replace('{{Supplier Delivery Changes}}', wrapWithAtag(summaryObj.sdcUrl, summaryObj.sdc))
        .replace('class="sdc"',summaryObj.sdc > 0? 'class="red"': '')
      .replace('{{Aged ODRs}}', wrapWithAtag(summaryObj.agedOdrUrl, summaryObj.agedOdr))
        .replace('class="ao"',summaryObj.agedOdr > 0? 'class="red"': '')
      .replace('{{New ODRs}}', wrapWithAtag(summaryObj.newOdrUrl, summaryObj.newOdr)) //summaryObj.newOdr)
        .replace('class="no"',summaryObj.newOdr > 0? 'class="red"': '')
      .replace('{{New CCFs}}',wrapWithAtag(summaryObj.newCcfUrl, summaryObj.newCcf)) // summaryObj.newCcf)
        .replace('class="nc"',summaryObj.newCcf > 0? 'class="red"': '')
      .replace('{{New And Critical Customers}}',wrapWithAtag(summaryObj.ncCustomerUrl, summaryObj.ncCustomer)) // summaryObj.ncCustomer)
        .replace('class="nacc"',summaryObj.ncCustomer > 0? 'class="red"': '')
      .replace('{{Missing feedback required information for CCFs}}', wrapWithAtag(summaryObj.missingFeebackCcfUrl, summaryObj.missingFeebackCcf))
        .replace('class="mfrifc"', summaryObj.missingFeebackCcf > 0? 'class="red"': '');

    formData.append('Body', bodyText);
  } else {
    console.error('Failed to fetch the HTML template.');
    return;
  }

  formData.append(
    'Subject',
    'Customer Focus & Service Meeting ' +
      moment(new Date()).format('DD/MM/YYYY')
  );

  const apiResponse = await fetch(`${API2_HOST}/emails/send-with-attachments`, {
    method: 'POST',
    body: formData,
    credentials: 'include'
  });

  if (!apiResponse.ok) {
    throw new Error('Failed to send document to API');
  }

  swalToastSuccess('Document sent successfully');
};

const wrapWithAtag = (url, data) => {
  var handleUrl = url ?? '#';
  if(handleUrl == '#') return data;
  return `<a target='_blank' href='${handleUrl}'>${data}</a>`;
}

const buildObjectSummary = (data) => {
  return {
    atRiskOdrs: data.atRisksODRs.data.length,
    atRiskOdrsUrl: data.atRisksODRs.url,
    sdc: data.supplierDeliveryChangesAgedODRs.data.length + data.supplierDeliveryChangesNewODRs.data.length,
    sdcUrl: data.supplierDeliveryChangesAgedODRs.url.replace('odrAge=old','odrAge='),
    agedOdr: data.agedODRs.data.length,
    agedOdrUrl: data.agedODRs.url,
    newOdr: data.newODRs.data.length,
    newOdrUrl: data.newODRs.url,
    newCcf: data.newCCFs.data.length,
    newCcfUrl: data.newCCFs.url,
    ncCustomer: data.ncCustomers.data.length,
    ncCustomerUrl: data.ncCustomers.url,
    missingFeebackCcf: data.missingInfoCcfs.data.length,
    missingFeebackCcfUrl: data.missingInfoCcfs.url,
  }
}
const buildSumaryInfoXML = (data) => {
  const headers = [
    { headerText: 'At Risks ODRs', value: 'atRiskOdrs' },
    { headerText: 'Supplier Delivery Changes', value: 'sdc' },
    { headerText: 'Aged ODRs', value: 'agedOdr' },
    { headerText: 'New ODRs', value: 'newOdr' },
    { headerText: 'New CCFs', value: 'newCcf' },
    { headerText: 'New And Critical Customers', value: 'ncCustomer' },
    { headerText: 'Missing feedback required information for CCFs', value: 'missingFeebackCcf' },
  ];

  var rowsData = [buildObjectSummary(data)]
  return buildTableXml(headers, rowsData);
};

const buildCcfs = (data, faultSource) => {
  const ccfHeaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'Quantity by Dept', value: 'quantityByDept' },
    { headerText: 'Feedback/Actions', value: 'comments' }
  ];

  const tempCcfRows = buildAgedNewODRRows(data,faultSource, [], []);
  const ccfRows = tempCcfRows.map(x => ({ 
    ...x, 
    quantityByDept: x.odr 
  }));

  ccfRows?.push({
    department: 'Total',
    atRisk: 0,
    supplierDateChanges: 0,
    quantityByDept: ccfRows.reduce(
      (total, item) => total + item.quantityByDept,
      0
    ),
    comments: ''
  });


  return buildTableXml(ccfHeaders, ccfRows);
};


const buildAgedODR = (data, faultSource, atRiskData, sdcAgedODRs) => {
  const agedODRSheaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'At Risk ODRS', value: 'atRisk' },
    { headerText: 'Supplier Date Changes', value: 'supplierDateChanges' },
    { headerText: 'Aged ODRS', value: 'agedOdrs' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  const tempAgedODRSRows = buildAgedNewODRRows(data,faultSource, atRiskData, sdcAgedODRs);
  const agedODRSRows = tempAgedODRSRows.map(x => ({ 
    ...x, 
    agedOdrs: x.odr 
  }));

  agedODRSRows?.push({
    department: 'Total',
    atRisk: agedODRSRows.reduce((total, item) => total + item.atRisk, 0),
    supplierDateChanges: agedODRSRows.reduce(
      (total, item) => total + item.supplierDateChanges,
      0
    ),
    agedOdrs: agedODRSRows.reduce((total, item) => total + item.agedOdrs, 0),
    comments: ''
  });

  return buildTableXml(agedODRSheaders, agedODRSRows);
};

const buildAgedNewODRRows = (data, faultSource, atRiskData, sdcODRs) => {
  const supplierObj = faultSource.find(x => x.name == 'Supplier');
  const tempODRRows = faultSource?.map(x => {
    if (x.id != supplierObj.id) {
      let odr = data.filter(a => a.faultSource == x.id).length;
      let atRiskOdr = atRiskData.filter(a => a.faultSource == x.id).length;
      let sdc = sdcODRs.filter(a => a.faultSource == x.id).length;
      return {
        department: x.name,
        atRisk: atRiskOdr,
        supplierDateChanges: sdc,
        odr: odr,
        comments: ''
      };
    } else {
      let groupedData = data.filter(a => a.faultSource == x.id);
      let supplierOrAreas = Array.from(
        groupedData.reduce((map, current) => {
          const key = `${current.supplierOrAreaName}-${current.supplierOrArea}`;
          if (!map.has(key)) {
            map.set(key, {
              supplierOrAreaName: current.supplierOrAreaName,
              supplierOrArea: current.supplierOrArea,
            });
          }
          return map;
        }, new Map()).values()
      );
      const odrBySupplierRows = supplierOrAreas?.map(soa => {
        let odr = data.filter(a => a.faultSource == x.id && soa.supplierOrArea == a.supplierOrArea).length;
        let atRiskOdr = atRiskData.filter(a => a.faultSource == x.id && soa.supplierOrArea == a.supplierOrArea).length;
        let sdc = sdcODRs.filter(a => a.faultSource == x.id && soa.supplierOrArea == a.supplierOrArea).length;
        return {
          department: soa.supplierOrAreaName,
          atRisk: atRiskOdr,
          supplierDateChanges: sdc,
          odr: odr,
          comments: ''
        };
      });
      return odrBySupplierRows;
    }
  });
  const odrRows = tempODRRows.flat();
  return odrRows;
}

const buildNewODR = (data, faultSource, atRiskData, sdcNewODRs) => {
  const newODRSheaders = [
    { headerText: 'Department', value: 'department' },
    { headerText: 'At Risk ODRS', value: 'atRisk' },
    { headerText: 'Supplier Date Changes', value: 'supplierDateChanges' },
    { headerText: 'New ODRS', value: 'newODRS' },
    { headerText: 'Dept Head Comments', value: 'comments' }
  ];
  const tempNewODRSRows = buildAgedNewODRRows(data,faultSource, atRiskData, sdcNewODRs);
  const newODRSRows = tempNewODRSRows.map(x => ({ 
    ...x, 
    newODRS: x.odr 
  }));
  newODRSRows?.push({
    department: 'Total',
    atRisk: newODRSRows.reduce((total, item) => total + item.atRisk, 0),
    supplierDateChanges: newODRSRows.reduce(
      (total, item) => total + item.supplierDateChanges,
      0
    ),
    newODRS: newODRSRows.reduce((total, item) => total + item.odr, 0),
    comments: ''
  });
  return buildTableXml(newODRSheaders, newODRSRows);
};

const buildNNCCustomerXml = ncCustomers => {
  let docXml = '<w:body>';

  ncCustomers?.data?.forEach(entry => {
    const customerList = entry.customerNames
      .map(customer => escapeXml(customer))
      .join(', ');

    const coloredCustomer = `<w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>${customerList}</w:t></w:r>`;
    docXml += `<w:p><w:r><w:t>${entry.dayOfWeek} – </w:t></w:r><w:r>${coloredCustomer}</w:r></w:p>`;
    docXml += '<w:p></w:p>';
  });

  docXml += '</w:body>';
  return docXml;
};

const buildSummaryXml = (
  data
) => {
  console.log(data)
  // Lấy ngày hôm nay và format
  const today = moment().format('dddd (D/M)');
  const summaryOftheWeeksHeaders = [
    { headerText: 'QTY', value: 'qty' },
    { headerText: 'Monday', value: 'monday' },
    { headerText: 'Tuesday', value: 'tuesday' },
    { headerText: 'Wednesday', value: 'wednesday' },
    { headerText: 'Thursday', value: 'thursday' },
    { headerText: 'Friday', value: 'friday' },
  ];
  
  // Tìm ngày bắt đầu tuần (thứ Hai)
  const startOfWeek = moment().startOf('isoWeek');
  
  // Thêm thuộc tính dateValue với ngày tương ứng cho mỗi header
  summaryOftheWeeksHeaders.forEach((header, index) => {
    if (index > 0) { // Bỏ qua cột đầu tiên (QTY)
      header.dateValue = startOfWeek.clone().add(index - 1, 'days').format('dddd (D/M)');
      header.dateWithYearValue = startOfWeek.clone().add(index - 1, 'days').format('dddd (D/M/YY)');
      header.headerText += today == header.dateValue ? " (Today) " : ""
    }
  });

  const calculatData = (colName, valueDate, paramData, today, headers, metrics, optionId) => {
    let todayLength = paramData.length;
    if(colName == 'N&C Customers') {
      console.log(paramData,today)
      todayLength = paramData.find(x => x.dayOfWeek == today)?.customerNames.length ?? 0;
    }
    var metricSum=  metrics.filter(x => x.cfType == optionId && moment(x.recordDate).format('dddd (D/M/YY)')
    == headers.find(x => x.value == valueDate).dateWithYearValue
    ).reduce((sum, item) => sum + item.value, 0);
    return today == headers.find(x => x.value == valueDate).dateValue ? todayLength : metricSum;
  }
  const calculate= (colName, paramData, today, headers, metrics, optionId ) => {
    return {
      qty: colName,
      monday: calculatData(colName,'monday', paramData, today, headers, metrics, optionId),
      tuesday: calculatData(colName,'tuesday', paramData, today, headers, metrics, optionId),
      wednesday:calculatData(colName,'wednesday', paramData, today, headers, metrics, optionId),
      thursday:calculatData(colName,'thursday', paramData, today, headers, metrics, optionId),
      friday: calculatData(colName,'friday', paramData, today, headers, metrics, optionId),
    }
  }
  const summaryOftheWeekRows = [
    calculate('AT RISK ODRS', data.atRisksODRs.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.AtRisksODRs),
    calculate('CCFS', data.newCCFs.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.NewCCFs),
    calculate('ODRS - Aged', data.agedODRs.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.AgedODRs),
    calculate('ODRS - New', data.newODRs.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.NewODRs),
    calculate('DDCS – Trade', data.todayTradeDDC.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.DDCTrade),
    calculate('DDCS – Commercial (includes scheduled jobs)', data.todayCommercialDDC.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.DDCCommercial),
    calculate('N&C Customers', data.ncCustomers.data, today, summaryOftheWeeksHeaders, data.metrics, CFType.NcCustomers),
  ];

  return buildTableXml(summaryOftheWeeksHeaders, summaryOftheWeekRows);
};

export { exportMsWork, sentMailWithAttactment };
