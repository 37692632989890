import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { applyProject } from "slices/projectSlice";
import PropTypes from "prop-types";
import { CloseButton } from "react-bootstrap";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import ActionForm from "./ActionForm";
import Background from "components/common/Background";
import corner from "assets/img/illustrations/corner-5.png";

const ActionModal = ({ setVisible, visible, action, title, itemId }) => {
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.selectedProject);
    const userId = useSelector(state => state.user.userId);

    const handleClick = (newAction) => {
        if (Object.prototype.hasOwnProperty.call(project, 'items')) {
            newAction = { ...newAction, assignedBy: userId };
            const updatedProject = structuredClone(project);
            const itemIdx = updatedProject.items.findIndex(item => { return item.title === title });
            console.log(newAction)

            if (itemIdx > -1) {
                if (!action) {
                    updatedProject.items[itemIdx].actions.push(newAction);
                }

                if (action) {
                    const actionId = updatedProject.items[itemIdx].actions.findIndex(act => isEqual(act, action));
                    updatedProject.items[itemIdx].actions[actionId] = newAction;
                }

                dispatch(applyProject(updatedProject));
                setVisible(false);

                return toast.success(
                    <div className="text-700 text-white">
                        <h6 className="text-white">Success!</h6>
                        <hr className="me-2" />
                        <p>Action was successfully added!</p>
                    </div>,
                    {
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        progress: undefined,
                    });
            }
        }

        setVisible(false);
        return toast.error(
            <div className="text-700 text-white">
                <h6 className="text-white">Error!</h6>
                <hr className="me-2" />
                No item was found. Please try again.
            </div>
        );
    }

    return (
        <Modal
            alignment="center"
            size="lg"
            show={visible}
        >
            <Background image={corner} />
            <Modal.Header>
                <Modal.Title>{action ? `Edit Action for ${title}` : 'Create New Action'} </Modal.Title>
                <CloseButton style={{ zIndex: 999 }} onClick={() => setVisible(false)} />
            </Modal.Header>
            <Modal.Body>
                <ActionForm itemId={itemId} action={action} createAction={handleClick} />
            </Modal.Body>
        </Modal>
    );
}

ActionModal.propTypes = {
    setVisible: PropTypes.func,
    visible: PropTypes.bool,
    action: PropTypes.object,
    title: PropTypes.string,
    itemId: PropTypes.number,
}

export default ActionModal;
