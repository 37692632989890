/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useState } from 'react';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const [localPageIndex, setLocalPageIndex] = useState(pageIndex + 1);

  const handleInputChangePageIndexChange = (e) => {
    setLocalPageIndex(e.target.value);
  };
  const generateArr = (wlength, index) => {
    const leftArr = Array.from({ length: wlength }, (_, i) => index - i - 1);
    const rightArr = Array.from({ length: wlength }, (_, i) => index + i + 1);

    return [...leftArr.reverse(), index, ...rightArr];
  };

  const localGotoPage = (number) => {
    if (number > pageCount || number < 0) {
      setLocalPageIndex(number);
      return;
    }
    setLocalPageIndex(number + 1);
    gotoPage(number);
  }

  const localNextPage = () => {
    if (canNextPage) {
      setLocalPageIndex(pageIndex + 2);
      nextPage();
    }
  }

  const localPreviousPage = () => {
    if (canPreviousPage) {
      setLocalPageIndex(pageIndex);
      previousPage();
    }
  }

  const handleInputBlur = () => {
    const pageNumber = parseInt(localPageIndex, 10);
    if (!isNaN(pageNumber)) {
      if (pageNumber > pageCount || pageNumber <= 0) {
        setLocalPageIndex(pageIndex + 1);
        return;
      }
      gotoPage(pageNumber - 1);
    }
  };

  const winglength = 2;
  let isShowAllPage = false;

  let arr = generateArr(winglength, pageIndex)
  if (pageCount < winglength * 2 + 1) {
    arr = Array.from(Array(pageCount).keys());
    isShowAllPage = true;
  }
  else {
    // left wing
    if (pageIndex < winglength) {
      const negativeCount = arr.reduce((count, currentNumber) => {
        if (currentNumber < 0) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);
      arr = arr.slice(negativeCount)
    }

    // right wing
    if (pageIndex >= pageCount - winglength) {
      const biggerCount = arr.reduce((count, currentNumber) => {
        if (currentNumber + 1 > pageCount) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);
      arr = arr.slice(0, arr.length - biggerCount)
    }
  }

  return (
    <>
      {pageCount > 1 ?
        <Flex alignItems="center" justifyContent="center">
          <Button
            size="sm"
            variant="falcon-default"
            onClick={() => localPreviousPage()}
            className={classNames({ disabled: !canPreviousPage })}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </Button>
          <ul className="pagination mb-0 mx-1">
            {
              pageIndex > winglength && !isShowAllPage ?
                <li>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className={classNames('page', 'me-1')}
                  >
                    ......
                  </Button>
                </li> : null
            }
            {
              arr.map((page, index) => (
                <li key={page} className={classNames({ active: pageIndex === page })}>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className={classNames('page', {
                      'me-1': index + 1 !== pageCount
                    })}
                    onClick={() => localGotoPage(page)}
                  >
                    {page + 1}
                  </Button>
                </li>
              ))
            }
            {pageIndex + winglength + 1 < pageCount && !isShowAllPage ? <li>
              <Button
                size="sm"
                variant="falcon-default"
                className={classNames('page', 'me-1')}
              >
                ......
              </Button>
            </li> : null}
          </ul>
          <Button
            size="sm"
            variant="falcon-default"
            onClick={() => localNextPage()}
            className={classNames({ disabled: !canNextPage })}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </Button>

          {!isShowAllPage ?
            <span className="ms-sm-9" >
              <input
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "3px",
                  border: "none",
                  boxShadow: "var(--falcon-btn-falcon-box-shadow)"
                }}
                value={localPageIndex}
                onChange={handleInputChangePageIndexChange}
                onBlur={handleInputBlur}
              /> / <span>
                {pageCount}</span>
            </span>
            : null}
        </Flex>
        : null}

    </>
  );
};

export default AdvanceTablePagination;
