import axios from 'axios';
import { API2_HOST } from 'helpers/urlHelper';
import { getItemFromStore, setItemToStore } from 'helpers/utils';

axios.defaults.withCredentials = true;

const getOptionsByKey = async (key, signal) => {
  try {
    const items = getItemFromStore(key, [], sessionStorage);
    if (items && items.length) return items;

    const res = await axios({
      method: 'get',
      url: `${API2_HOST}/OptionList?optionKey=${key}`,
      signal: signal
    }).catch(err => {
      console.log('ERROR', err);
      return [];
    });

    let list = res.data;
    if (res.status === 200 && res.data.length) {
      if (key === 'new.and.critical.job.department') {
        list = res.data.sort((a, b) => a.value2 - b.value2);
        // list.find(x => x.value === 4126).disabled = true; // disable sales to prevent selection
      }
    }
    setItemToStore(key, JSON.stringify(list), sessionStorage);

    return list;
  } catch (err) {
    console.log(`Error in option list key: ${key}`, err);
    return [];
  }
};

export { getOptionsByKey };
