import React from 'react';
import { Card, Row, Col, Form, FloatingLabel } from 'react-bootstrap';

/* eslint-disable react/prop-types */

const boardOptions = (boards) => {
    let options = [];

    for (const [key, value] of Object.entries(boards)) { // Using the default iterator (could be `map.entries()` instead)
        options.push(<option value={key}>
            {value.name}
        </option>);
    }

    return options;
}

function sprintOptions(boards, selectedBoardId) {
    let board = boards[selectedBoardId];

    if (!board || board == undefined || board.sprints == undefined || board.sprints.length == 0) return (<></>);

    let options = [];
    board.sprints.forEach((sprint) => {
        options.push(<option value={sprint.id}>{sprint.name}</option>);
    });

    return options;
}

const SprintSelector = (props) => {
    //const [stats] = useState(statsData);
    if (!props.boards || props.boards.length == 0) return (<></>);
    return (
        <Card>
            <Card.Body>
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid" label="Board">
                            <Form.Select size="sm" onChange={props.onBoardSelectionChanged}>
                                {boardOptions(props.boards)}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid" label="Sprint">
                            <Form.Select size="sm" aria-label="Default select example" onChange={props.onSprintSelectionChanged}>
                                {sprintOptions(props.boards, props.selectedBoardId)}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
/* eslint-enable react/prop-types */
export default SprintSelector;