import React from 'react';
import PropTypes from 'prop-types';

const CollectionJobsCard = ({ data }) => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const formattedData = Object.values(
    data.reduce((acc, item) => {
      const dateObj = new Date(item.requireddate);
      const day = dateObj.toLocaleDateString("en-US", { weekday: "long" });
      const dayMonth = `${dateObj.getDate()}/${dateObj.getMonth() + 1}`;
      const year =
        dateObj.getFullYear() !== currentYear ? `/${dateObj.getFullYear()}` : "";

      const dateKey = `${day} (${dayMonth}${year})`;

      if (!acc[dateKey]) {
        acc[dateKey] = { date: dateKey, jobs: [] };
      }

      acc[dateKey].jobs.push({
        id: item.id,
        originalJobNo: item.originalJobNo,
        jobNo: item.jobNumber,
        customerName: item.customerName,
      });

      return acc;
    }, {})
  );

  return (
    <div className="customer-list">
      <h4>
        <strong>Job Collections</strong>
      </h4>
      {formattedData.map((item, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h5>
            <strong>
              {item.date} _ ({item.jobs.length})
            </strong>
          </h5>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Original Job Number</th>
                <th scope="col">Job No</th>
                <th scope="col">Customer</th>
              </tr>
            </thead>
            <tbody>
              {item.jobs.map((job, jobIndex) => (
                <tr key={jobIndex}>
                  <td>{job.jobNo ? job.jobNo.split('-').at(-1) : ''}</td>
                  <td>{job.jobNo}</td>
                  <td>{job.customerName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

CollectionJobsCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

const NCCustomersCard = ({ data }) => {
  return (
    <div className="customer-list">
      <h4>
        <strong>New & Critical Customers</strong>
      </h4>
      {data.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <span
            style={{
              fontWeight: 'bold',
              display: 'inline-block',
              marginRight: '5px'
            }}
          >
            {item.dayOfWeek} –
          </span>
          <span style={{ color: 'red' }}>{item.customerNames.join(', ')}</span>
        </div>
      ))}
    </div>
  );
};
NCCustomersCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

const FaultSourcesCard = ({ faultSources, data, title }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Department</th>
          <th scope="col">{title}</th>
        </tr>
      </thead>
      <tbody>
        {faultSources.map((source, index) => {
          const existeds = data.filter(item => item.faultSource === source.id);
          return (
            <tr key={index}>
              <td>{source.name}</td>
              <td>{existeds ? existeds.length : 0}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
FaultSourcesCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  faultSources: PropTypes.array,
  title: PropTypes.string
};

const CriticalReturnCcfCard = ({ data, url }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Ccf ID</th>
          <th scope="col">Original Job Number</th>
          <th scope="col">Customer</th>
          <th scope="col">Description</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                <a
                  href={url + `${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    margin: '0 5px'
                  }}
                >
                  {item.id}
                </a>
              </td>
              <td>{item.originalJobNo}</td>
              <td>{item.customerName}</td>
              <td>{item.natureOfComplaint}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

CriticalReturnCcfCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  url: PropTypes.string
};

export { NCCustomersCard, FaultSourcesCard, CriticalReturnCcfCard, CollectionJobsCard };
