import axios from 'axios';
import { API3_HOST } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString } from '../../helpers/utils'
axios.defaults.withCredentials = true;

const searchs = async (request) => {
    const res = await axios({
        method: 'get',
        url: `${API3_HOST}/sls-stocks?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

const searchProducts = async (request) => {
    const res = await axios({
        method: 'get',
        url: `${API3_HOST}/sls-stocks/product-info?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

export { searchs,searchProducts };

