import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import dayjs from 'dayjs';
import { priorites, progresses } from 'data/TempData';
import { getDepartmentOptionList } from 'services/customer-service/scActionPlanService';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import Priority from './Priority';
import { useSortBe } from 'hooks/useBeHandle';
const ActionPlanTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange,
  colSort,
  handleSort
}) => {
  const [columns, setColumns] = useState([]);
  const isMounted = useRef(null);
  const [dataDepartments, SetDataDepartments] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    const fetchDepart = async signal => {
      const resDepart = await getDepartmentOptionList(signal);
      SetDataDepartments(
        resDepart?.map(x => {
          return {
            ...x,
            value: x.id,
            label: x.name
          };
        })
      );
    };

    fetchDepart(controller.signal);
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const originColumns = [
    {
      accessor: 'id',
      Header: 'Action No.',
      isVisible: true,
      Cell: rowData => {
        const { id, meetingId } = rowData.row.original;

        return (
          <Link
            to={
              `/customer-service/meetings/${meetingId}/action-plan-manager/` +
              id
            }
          >
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {id}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'meetingId',
      Header: 'meetingId',
      isVisible: false
    },
    {
      accessor: 'title',
      Header: 'Title',
      Cell: rowData => {
        const { title, id, meetingId } = rowData.row.original;

        return (
          <Link
            to={
              `/customer-service/meetings/${meetingId}/action-plan-manager/` +
              id
            }
          >
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {title}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'targetDate',
      Header: 'Target Date',
      Cell: rowData => {
        const { targetDate } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {targetDate && dayjs(targetDate).format('DD/MM/YYYY')}
              </h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'assignees',
      Header: 'Responsible persons',
      Cell: rowData => {
        const { assignees } = rowData.row.original;
        return <UsersInCellWidget usersText={assignees} />;
      }
    },
    {
      accessor: 'requestParticipants',
      Header: 'Request participants',
      headerProps: { style: { minWidth: '10px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { requestParticipants } = rowData.row.original;
        return <UsersInCellWidget usersText={requestParticipants} />;
      }
    },
    {
      accessor: 'reporter',
      Header: 'Reporter',
      Cell: rowData => {
        const { reporter } = rowData.row.original;
        return <UsersInCellWidget showName usersText={reporter.toString()} />;
      }
    },
    {
      accessor: 'departments',
      Header: 'Departments',
      Cell: rowData => {
        const { departments } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          departments,
          dataDepartments
        );
        return (
          <>
            {data.map(i => (
              <Link to="#" key={i.value}>
                <Flex alignItems="center">
                  <div className="flex-1">
                    <h5 className="mb-0 fs--1">{i.label}</h5>
                  </div>
                </Flex>
              </Link>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'progress',
      Header: 'Status',
      Cell: rowData => {
        const { progress } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          progress?.toString(),
          progresses
        );
        return (
          <>
            {data.map(i => (
              <SoftBadge
                key={i.value}
                bg={classNames({
                  success: i.label === 'Completed',
                  warning: i.label === 'Inprogress',
                  danger: i.label === 'To Do'
                })}
                className="fs--1 w-100"
              >
                {i.label}
              </SoftBadge>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      Cell: rowData => {
        const { priority } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          priority.toString(),
          priorites
        );
        return (
          <>
            {data.map(i => (
              <Priority key={i.value} param={i} />
            ))}
          </>
        );
      }
    }
  ];

  const { reNewColumns } = useSortBe(
    originColumns,
    colSort,
    setColumns,
    columns
  );

  useEffect(() => {
    reNewColumns();
  }, [colSort]);

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign=""
        beSortOption={{ canSort: true, handleSort: handleSort }}
      />
    </>
  );
};

ActionPlanTable.propTypes = {
  data: PropTypes.array,
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  colSort: PropTypes.object,
  handleSort: PropTypes.func
};

export default ActionPlanTable;
