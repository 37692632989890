import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const CheckedByLink = ({ handleChangeValue, property }) => {
  const currentUser = useSelector(state => state.user.userId);
  const handleCheckedByMe = e => {
    e.preventDefault();
    console.log(currentUser);

    handleChangeValue(currentUser, property);
  };

  return (
    <a href="" onClick={handleCheckedByMe}>
      Checked By Me
    </a>
  );
};

CheckedByLink.propTypes = {
  handleChangeValue: PropTypes.func,
  property: PropTypes.string
};

export default CheckedByLink;
