import axios from 'axios';
import { API3_HOST } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { getMessage400Request } from '../../helpers/utils';
import { ApiResponse } from '../../helpers/stockLocationHelpers';
axios.defaults.withCredentials = true;
const baseURL = `${API3_HOST}/sc-meetings`;
import { baseSearch } from 'services/base-services/baseService';

const searchPagingMeetings = async request => {
  return await baseSearch(baseURL+"/search-paging", request);
};

const searchMeetings = async () => {
  const res = await axios({
    method: 'get',
    url: `${baseURL}`
  }).catch(error => {
    console.log('AXIOS ERROR', error);
    return new BaseTableRS([], 0);
  });

  if (res.status !== 200) return [];

  return res.data?.data?.items;
  // return events
};

const postMeeting = async request => {
  const result = new ApiResponse();
  if (!request) return result;
  const res = await axios({
    method: 'post',
    url: baseURL,
    data: { ...request },
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  }).catch(error => {
    console.log('ERROR: ', { ...error });
    return result;
  });
  if (res.status !== 200) {
    return result;
  }

  result.success = res.data.success;
  result.data = res.data?.data;
  result.message = res.data?.message;
  return result;
};

const patchMeeting = async (id, updatedData) => {
  if (!updatedData || id <= 0) return;
  await axios({
    method: 'patch',
    url: `${baseURL}/${id}`,
    data: { ...updatedData },
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  }).catch(error => {
    console.log('ERROR: ', { ...error });
    return;
  });
};

const patchRelatedMeetingFields = async (id, updatedData) => {
  if (!updatedData || id <= 0) return;
  await axios({
    method: 'patch',
    url: `${baseURL}/related-fields/${id}`,
    data: { ...updatedData },
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  }).catch(error => {
    console.log('ERROR: ', { ...error });
    return;
  });
};

const getMeeting = async id => {
  const result = new ApiResponse();
  const res = await axios({
    method: 'get',
    url: `${baseURL}/${id}`
  }).catch(res => {
    console.log('ERROR: ', { ...res });
    result.message = getMessage400Request(res);
    return result;
  });

  if (res.status !== 200) {
    return result;
  }
  result.success = res.data.success;
  result.data = res.data?.data;
  result.message = res.data?.message;
  return result;
};

export {
  searchMeetings,
  searchPagingMeetings,
  postMeeting,
  patchMeeting,
  getMeeting,
  patchRelatedMeetingFields
};
