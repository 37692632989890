import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Error403 = () => {
    const [code, setCode] = useState();
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.code)
            setCode(location.state.code);

        if (location.state && location.state.message)
            setMessage(location.state.message);

        if (location.state && location.state.errorMessage)
            setErrorMessage(location.state.errorMessage);
    }, [location.state])

    return (
        <Card className="text-center h-100 bg-gradient">
            <Card.Body className="p-5">
                <div className="display-1 text-300 fs-error">{code}</div>
                <p className="display-5 lead mt-4 text-800 font-sans-serif fw-semi-bold text-danger">
                    Whoops!
                </p>
                <p className="fs-2 mt-4 text-800 font-sans-serif fw-semi-bold">
                    You dont have permission to acess this page.
                </p>
                <p className="text-800 font-sans-serif fw-semi-bold">
                    Please head back to EVA and try the link again. Please see the error below for more information.
                </p>
                <p className="text-danger">{message}</p>
                <p className="text-danger">{errorMessage}</p>
                <hr className="text-danger" />
                <p>
                    If this problem persists, please <a href="mailto:ITsupport@emplas.co.uk" className="ms-1">contact us</a>.
                </p>
            </Card.Body>
        </Card>
    )
};

export default Error403;