import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applySite } from '../../../../slices/siteSlice';
import { newSite } from 'helpers/stockLocationHelpers';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import ProjectFormItem from 'components/partials/projects/ProjectFormItem';
import { toast } from 'react-toastify';
import {
  getSiteById,
  postSite,
  patchSite,
  disableSite,
  enableSite
} from 'services/stock-location/siteServices';
import BasicTemplate from 'components/Toast/BasicTemplate';

export default function SiteManager() {
  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const dispatch = useDispatch();
  const site = useSelector(state => state.site.selectedSite);
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(applySite(null));
    };
  }, []);

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      getSiteById(id).then(res => {
        console.log(res?.data);
        if (res?.data && res?.data.id > 0) {
          dispatch(
            applySite({
              ...res?.data
            })
          );
          return;
        }
      });
      return;
    }
    console.log(site);
    dispatch(applySite({ ...newSite }));
    return;
  };
  const handleInputChange = (e, name) => {
    const {
      target: { value }
    } = e;

    switch (name) {
      case 'name':
        dispatch(
          applySite({
            ...site,
            name: value
          })
        );
        break;
      case 'description':
        dispatch(
          applySite({
            ...site,
            description: value
          })
        );
        break;
      default:
        break;
    }
    return;
  };

  const preSaveSite = async () => {
    setIsWaitingSave(true);
    if (!checkRequired()) {
      setIsWaitingSave(false);
      return false;
    }

    await handleSaveSite();
  };
  const handleSaveSite = async () => {
    let result = null;
    if (site.id <= 0) {
      result = await postSite(site);
    } else {
      result = await patchSite(site, site.id);
    }

    const message =
      result.message ??
      'There was a problem saving the site. Please check your entires and try again.';
    result.success
      ? toast.success(
          <BasicTemplate
            success={result.success}
            text="Site successfully saved!"
          />
        )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  }

  const isStatusLive = () => {
    return site?.status == 'Live' || site?.id == 0;
  };

  const handleDisableSite = async () => {
    setIsWaitingSave(true);
    let result = null;
    console.log(site);
    if (isStatusLive()) {
      result = await disableSite(site.id);
    } else {
      result = await enableSite(site.id);
    }

    if (result.success == true) {
      fetchData();
    }

    const message =
      result.message ??
      'There was a problem saving the site. Please check your entires and try again.';
    result.success
      ? toast.success(
          <BasicTemplate
            success={result.success}
            text="Site successfully saved!"
          />
        )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const checkRequired = () => {
    let valid = true;
    console.log(site);
    if (site.name?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please enter name (site id) to continue." />
      );
      valid = false;
    }
    if (site.description?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please input description to continue." />
      );
      valid = false;
    }
    return valid;
  };

  return (
    <Row>
      <Col xs={12}>
        <PageHeader
          title={site && site.id > 0 ? 'Edit ' + site.siteID : 'Create Site'}
          description={`<p>Use this page to manage your sites. Assign actions below the description.</p><p>Leave an input blank to ignore the field.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            <ProjectFormItem
              id="name"
              title="Site ID"
              readOnly={!isStatusLive()}
              body={site && site.siteID}
              placeholder="Please enter a name for this site"
              valueChange={e => handleInputChange(e, 'name')}
            />
            <ProjectFormItem
              id="description"
              title="Description"
              readOnly={!isStatusLive()}
              body={site && site.description}
              placeholder="Please enter a description for this site"
              valueChange={e => handleInputChange(e, 'description')}
            />
            <Row>
              <Col xs={6}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  onClick={() => preSaveSite()}
                >
                  {!isWaitingSave ? (
                    'Save Site'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
              <Col xs={6}>
                {site?.id > 0 ? (
                  <Button
                    style={{
                      float: 'right'
                    }}
                    variant={!isStatusLive() ? 'success' : 'danger'}
                    disabled={isWaitingSave}
                    onClick={() => handleDisableSite()}
                  >
                    {isStatusLive() ? 'Disable' : 'Enable'}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
