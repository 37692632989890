import axios from 'axios';
import { API3_HOST } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString } from '../../helpers/utils'
import { ApiResponse } from '../../helpers/stockLocationHelpers'
axios.defaults.withCredentials = true;

const searchAreas = async (request) => {

    const res = await axios({
        method: 'get',
        url: `${API3_HOST}/areas/search?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

const getAreaById = async (id) => {
    const result = new ApiResponse();
    const res = await axios({
        method: 'get',
        url: `${API3_HOST}/areas/${id}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const postArea = async (area) => {
    const result = new ApiResponse();
    if (!area) return result;
    const res = await axios({
        method: 'post',
        url: `${API3_HOST}/areas`,
        data: { ...area },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });
    if (res.status !== 200) {
        return result;
    }

    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const patchArea = async (area, id) => {
    const result = new ApiResponse();
    if (!area || id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${API3_HOST}/areas/${id}`,
        data: { ...area },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const disableArea = async (id) => {
    const result = new ApiResponse();
    console.log(id);
    if (id <= 0) return result;
    const res = await axios({
        method: 'delete',
        url: `${API3_HOST}/areas/${id}`,
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
const allowDisable = async (id) => {
    const result = new ApiResponse();
    if (id <= 0) return result;
    const res = await axios({
        method: 'get',
        url: `${API3_HOST}/areas/${id}/allow-disable`,
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
const enableArea = async (id) => {
    const result = new ApiResponse();
    if (id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${API3_HOST}/areas/${id}/enable-status`,
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
export {
    allowDisable,
    searchAreas,
    postArea, patchArea,
    getAreaById, disableArea, enableArea
};

