import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { loadSprintData } from 'services/jiraProjectManagement';
import SprintSelector from './SprintSelector'
import KanbanContainer from './KanbanContainer';
import KanbanProvider from './KanbanProvider';
import dayjs from 'dayjs';
import SprintPieChart from './SprintPieChart';
import SprintLinks from './SprintLinks';
import axios from 'axios';
import { API2_HOST } from 'helpers/urlHelper'
import SprintDetailsV2 from './SprintDetailsV2';

const JiraProjectManagementDashboard = () => {

    // -- Page state -- //

    // Loading
    const [loading, setLoading] = useState(false);
    const [loadingSprint, setLoadingSprint] = useState(false);
    const [loadingBacklog, setLoadingBacklog] = useState(false);
    const [selectedBoardId, setSelectedBoardId] = useState(0);
    const [selectedSprintId, setSelectedSprintId] = useState(0);

    const [boardAndSprintData, setBoardAndSprintData] = useState({});
    const [backlogData, setBacklogData] = useState({});
    const [hoursAvailable, setHoursAvailable] = useState(0);
    const [bauHoursAvailable, setBauHoursAvailable] = useState(0);
    const [statuses, setStatuses] = useState([]);
    const [statusMap, setStatusMap] = useState(new Map([]));
    // const [projectData, setProjectData] = useState([]);
    // const [boardData, setBoardData] = useState([]);
    // const [sprintData, setSprintData] = useState([]);
    const [selectedSprintData, setSelectedSprintData] = useState({});

    const computeHoursAvailable = (startDate, endDate) => {
        let dayDiff = endDate.diff(startDate, 'day');
        let weeks = Math.round((dayDiff + 3) / 7);
        setHoursAvailable(weeks * 150);
    }

    const computeBAUHoursAvailable = (startDate, endDate) => {
        let dayDiff = endDate.diff(startDate, 'day');
        let weeks = Math.round((dayDiff + 3) / 7);
        setBauHoursAvailable(weeks * 35);
    }

    const loadData = () => {
        console.log("Loading Begin");
        setLoading(true);
        axios({
            method: 'get',
            url: `${API2_HOST}/jira-project-management/all`
        }).then((result) => {
            let map = {};
            result.data.forEach(item => {
                map[item.id] = item;
            });
            setBoardAndSprintData(map);
            setLoading(false);
            setSelectedBoardId(result.data[0].id);
            setSelectedSprintId(result.data[0].sprints[0].id);
        }).catch(err => {
            console.error('projectData', err);
        });

        axios({
            method: 'get',
            url: `${API2_HOST}/jira-project-management/status`
        }).then((result) => {
            let statusSortFn = function (a, b) {
                let i = a.categoryId;
                let j = b.categoryId;

                // ensure that the 'done' (id 3) category is always last
                // we have to do this as jira is set up with 'in progress' > 'done'.
                if (i == 3) { i = 999; }
                if (j == 3) { j = 999; }
                return i - j;
            }

            result.data.sort(statusSortFn);

            result.data.forEach(status => {
                statusMap.set(status.id, status);
            });
            console.log("STATUS DATA", result.data, statusMap);
            setStatusMap(statusMap);
            setStatuses(result.data);
        }).catch(err => {
            console.error('loadBoardData', err);
        });




        console.log("Loading End");

    }

    // Load inital project data
    useEffect(() => {
        loadData();
    }, []);

    // Load boards for project
    // useEffect(() => {
    //     // console.log("projectId", selectedProjectId)
    //     async function fetchBoards() {
    //         let statusData = await loadStatusData();

    //         let statusSortFn = function (a, b) {
    //             let i = a.categoryId;
    //             let j = b.categoryId;

    //             // ensure that the 'done' (id 3) category is always last
    //             // we have to do this as jira is set up with 'in progress' > 'done'.
    //             if (i == 3) { i = 999; }
    //             if (j == 3) { j = 999; }
    //             return i - j;
    //         }

    //         statusData.sort(statusSortFn);

    //         statusData.forEach(status => {
    //             statusMap.set(status.id, status);
    //         });

    //         setStatusMap(statusMap);
    //         setStatuses(statusData);

    //         if (selectedProjectId != 0) {
    //             let data = await loadBoardData(selectedProjectId);
    //             if (data) {
    //                 setBoardData(data);
    //                 if (data.length > 0) { setSelectedBoardId(data[0].id); }
    //             } else {
    //                 setBoardData([]);
    //             }
    //         }
    //     }
    //     fetchBoards();
    // }, [selectedProjectId]);

    // fetch sprints for boards
    // useEffect(() => {
    //     // console.log("board id", selectedProjectId)

    //     async function fetchSprints() {
    //         setSprintData([]);
    //         console.log("SELECTED SPRINT ID", selectedSprintId);
    //         if (selectedBoardId != 0) {
    //             let data = await loadBoardSprintData(selectedBoardId);
    //             if (data) {
    //                 setSprintData(data);

    //                 if (data.length > 0) {
    //                     setSelectedSprintId(data[0].id);
    //                     console.log("sprint id", selectedSprintId, "sprint data", data);
    //                     //
    //                 }
    //             } else {
    //                 setSprintData([]);
    //             }
    //         }
    //     }
    //     fetchSprints();

    // }, [selectedBoardId]);


    useEffect(() => {
        if (boardAndSprintData[selectedBoardId]) {
            let board = boardAndSprintData[selectedBoardId]
            if (board.sprints?.length > 0) {
                setSelectedSprintId(board.sprints[0].id);
            }

        }

    }, [selectedBoardId]);

    async function fetchSelectedSprintData() {
        console.log("SELECTED SRPINT ID = ", selectedSprintId);
        setSelectedSprintData({});
        if (selectedSprintId != 0) {
            let data = await loadSprintData(selectedSprintId);
            if (data) {
                setSelectedSprintData(data);
                if (data.startDate && data.endDate) {
                    let start = dayjs(data.startDate);
                    let end = dayjs(data.endDate);
                    computeHoursAvailable(start, end);
                    computeBAUHoursAvailable(start, end);
                }
            } else {
                setSelectedSprintData({});
            }
        }
        setLoadingSprint(false);
    }

    async function fetchBacklogData() {
        console.log("SELECTED SRPINT ID = ", selectedSprintId);
        setBacklogData({});

        if (selectedSprintId == 0) return;

        setLoadingBacklog(true);
        axios({
            method: 'get',
            url: `${API2_HOST}/jira-project-management/backlog/${selectedSprintId}`
        }).then((result) => {
            setBacklogData(result.data);
            console.log("backlogData", backlogData);
            setLoadingBacklog(false);
        }).catch(err => {
            console.error('Failed to get backlog data', err);
            setLoadingBacklog(false);
        });
    }

    // Load sprint details
    useEffect(() => {
        setLoadingSprint(true);
        // console.log("sprint id", selectedSprintId)

        fetchSelectedSprintData();
        fetchBacklogData();
    }, [selectedSprintId]);

    /*
    useEffect(() => {
        console.log("statuses", statuses)
    }, [statuses]);
    */
    // console.log("boardData", boardData)
    if (loading) {
        return <div className="text-center">
            <Spinner variant="primary" animation="border" role="status"></Spinner>
        </div>;
    }
    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={7} xl={8} xxl={9}>
                    <SprintSelector
                        className="h-lg-100"
                        boards={boardAndSprintData}
                        selectedBoardId={selectedBoardId}
                        onBoardSelectionChanged={(e) => setSelectedBoardId(e.target.value)}
                        onSprintSelectionChanged={(e) => setSelectedSprintId(e.target.value)}
                    ></SprintSelector>
                </Col>
                <Col lg={5} xl={4} xxl={3}>
                    <SprintLinks selectedBoardId={selectedBoardId} selectedSprintId={selectedSprintId} onClose={fetchSelectedSprintData}
                    ></SprintLinks>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col md={7} xxl={4}>
                    <SprintDetailsV2 loading={loadingSprint} sprint={selectedSprintData} hoursAvailable={hoursAvailable}></SprintDetailsV2>
                </Col>
                <Col md={4} xxl={2}>
                    <SprintPieChart loading={loadingSprint} title="Sprint Planning" total={selectedSprintData.plannedWork} totalDesc="Hours Planned" amount={hoursAvailable} amountDesc="Hours Available"></SprintPieChart>
                </Col>
                <Col md={4} xxl={2}>
                    <SprintPieChart loading={loadingSprint} title="Sprint Progress" total={selectedSprintData.totalWorkLogged} totalDesc="Hours Logged" amount={selectedSprintData.plannedWork} amountDesc="Hours Remaining"></SprintPieChart>
                </Col>
                <Col md={4} xxl={2}>
                    <SprintPieChart loading={loadingSprint} title="Sprint Completion" total={selectedSprintData.totalWorkDone} totalDesc="Hours Resolved" amount={selectedSprintData.plannedWork} amountDesc="Hours Remaining"></SprintPieChart>
                </Col>
                <Col md={4} xxl={2}>
                    <SprintPieChart loading={loadingBacklog} title="BAU Time" total={backlogData.timeLoggedSecs / 3600
                    } totalDesc={`Hours Logged (${backlogData.issueCount} issues)`} amount={bauHoursAvailable} amountDesc="Hours Planned" 
                    extra={backlogData.issueCount > 0 ? <p className='fs--1 mb-0'>{((backlogData.timeLoggedSecs / 3600) / backlogData.issueCount).toFixed(2)} average hours logged per issue</p> : <></>}></SprintPieChart>
                </Col>
            </Row>

            <Row className="g-3 mb-3 mt-4">
                <Col>
                    <KanbanProvider>
                        <KanbanContainer statuses={statuses} sprint={selectedSprintData} statusMap={statusMap}></KanbanContainer>
                    </KanbanProvider>
                </Col>
            </Row>

        </>

    );
};

export default JiraProjectManagementDashboard;