import React from 'react';
import { Card, Table, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const SprintDetailsV2 = (props) => {

  let loading = (props.loading) || (!props.sprint || props.sprint == undefined || Object.keys(props.sprint) == 0);

  let hoursLeft = 0;
  if (!loading) {
    hoursLeft = (props.hoursAvailable - props.sprint.plannedWork).toFixed(2);
  }
  

  return (
    <Card className="h-100 mt-3">
      <FalconCardHeader
        title="Sprint Details"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body
        className='p-0 m-0'
        as={Flex}
        direction="column"
        alignItems="between"
      >
        {loading ? <><Spinner className="p-2 m-2 center" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner></> : 
        <Table size='sm' className='p-0 m-0'>
          <tbody className='p-0 m-0 text-xxsmall' style={{ fontSize: '14px' }}>
            <tr>
              <td>Name</td>
              <td>{props.sprint.name} ({props.sprint.state})</td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{props.hoursAvailable / 150} Weeks</td>
            </tr>

            {
              props.sprint.startDate && dayjs(props.sprint.startDate).year() >= 2000 ?

                <><tr>
                  <td>Start Date</td>
                  <td>{dayjs(props.sprint.startDate).format("DD/MM/YYYY")}</td>
                </tr>
                  <tr>
                    <td>End Date</td>
                    <td>{dayjs(props.sprint.endDate).format("DD/MM/YYYY")}</td>
                  </tr></> : <></>
            }
            <tr>
              <td>Hours Available</td>
              <td>{props.hoursAvailable ? props.hoursAvailable.toFixed(2) : 0} hours</td>
            </tr>
            <tr>
              <td>Hours Planned</td>
              <td>{props.sprint.plannedWork.toFixed(2)} hours ({props.sprint.issues.length} issues)</td>
            </tr>
            <tr>
              <td>Hours Left</td>
              <td className={hoursLeft < 0 ? 'text-danger' : ''}>{hoursLeft} hours</td>
            </tr>
            <tr>
              <td>Logged Work</td>
              <td>{props.sprint.totalWorkLogged.toFixed(2)} hours</td>
            </tr>
            <tr>
              <td>Work Completed</td>
              <td>{props.sprint.totalWorkDone.toFixed(2)} hours</td>
            </tr>
            <tr>
              <td>Work Left</td>
              <td>{props.sprint.totalWorkLeft.toFixed(2)} hours</td>
            </tr>
          </tbody>
        </Table>
}
      </Card.Body>

      {/* <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 6 Months</option>
              <option>Last Year</option>
              <option>Last 2 Year</option>
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Link className="fs--1 font-sans-serif" to="#!">
              Help
            </Link>
          </Col>
        </Row>
      </Card.Footer> */}
    </Card>
  );
};

export default SprintDetailsV2;
