import React, { useEffect, useRef, useState } from 'react';
import { Table, FormCheck, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getImageUrl } from 'services/frameImage';
import './styles.css';
import { getNCProductChecking } from 'services/newCritical';
import { swalToastError } from 'components/utilities/SwalToastUtil';

const QCFrames = ({ frames, setItem, jobNo }) => {
  const checkRef = useRef(null);
  const rejectRef = useRef(null);
  const loadedRef = useRef(null);

  const [NCProduct, setNCProduct] = useState([]);

  const selectAll = target => {
    const { id, checked } = target;

    const frameChecked = id.includes('checked') && checked;
    const frameReject = id.includes('reject') && checked;
    const frameLoaded = id.includes('loaded') && checked;
    let newFrames = frames.map(x => {
      return {
        ...x,
        checked: frameChecked,
        rejected: frameReject,
        loaded: frameLoaded
      };
    });

    setItem(prev => {
      return { ...prev, frames: newFrames };
    });
  };

  useEffect(() => {
    const checked = frames.reduce((a, b) => (a += b.checked ? 1 : 0), 0);
    const rejected = frames.reduce((a, b) => (a += b.rejected ? 1 : 0), 0);
    const loaded = frames.reduce((a, b) => (a += b.loaded ? 1 : 0), 0);

    if (frames.length > 0) {
      checkRef.current.checked = checked === frames.length;
      rejectRef.current.checked = rejected === frames.length;
      loadedRef.current.checked = loaded === frames.length;
    }
  }, [frames]);

  useEffect(() => {
    getNCProductChecking(jobNo)
      .then(res => {
        if (res) {
          setNCProduct(res);
        }
      })
      .catch(error => {
        console.log(error);
        swalToastError('Get NC Product Checking Failed');
      });
  }, []);

  return (
    <div className="my-3 container overflow-auto">
      <Table bordered hover responsive>
        <thead className="table-light">
          <tr>
            <th>
              <small>Checked?</small>
              <FormCheck
                id="select-all-checked"
                ref={checkRef}
                onChange={e => selectAll(e.target)}
              />
            </th>
            <th>
              <small>Rejected?</small>
              <FormCheck
                id="select-all-reject"
                ref={rejectRef}
                onChange={e => selectAll(e.target)}
              />
            </th>
            <th>
              <small>Loaded?</small>
              <FormCheck
                id="select-all-loaded"
                ref={loadedRef}
                onChange={e => selectAll(e.target)}
              />
            </th>
            <th></th>
            <th>Frame ID</th>
            <th>Win No.</th>
            <th>Style No.</th>
            <th>Location</th>
            <th>Glazing</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {frames.length > 0 ? (
            frames.map(x => {
              let rowClass = '';

              const frameHasQCArr = NCProduct.filter(
                nc => nc.winNo === x.winNo
              );

              const frameHasQC =
                frameHasQCArr && frameHasQCArr.length > 0
                  ? frameHasQCArr[0]
                  : '';

              if (
                frameHasQC &&
                x.checked == null &&
                x.rejected == null &&
                x.loaded == null
              ) {
                x.checked = true;
                x.rejected = false;
                Object.keys(frameHasQC).map(key => {
                  if (frameHasQC[key] == 'Rejected') {
                    x.rejected = true;
                    x.checked = false;
                  }
                });
              }

              if (x.checked) rowClass = 'table-success';
              else if (x.rejected) rowClass = 'table-danger';
              else if (x.loaded) rowClass = 'table-warning';

              return (
                <tr className={rowClass} key={`${x.headerId}-${x.frameId}`}>
                  <td>
                    <FormCheck
                      value={x.frameId.toString()}
                      checked={x.checked || null}
                      onChange={() => {
                        setItem(prev => {
                          return {
                            ...prev,
                            frames: [
                              ...frames.filter(y => y.frameId !== x.frameId),
                              {
                                ...x,
                                checked: !x.checked,
                                rejected: false,
                                loaded: false
                              }
                            ]
                          };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <FormCheck
                      value={x.frameId.toString()}
                      checked={x.rejected || null}
                      onChange={() => {
                        setItem(prev => {
                          return {
                            ...prev,
                            frames: [
                              ...frames.filter(y => y.frameId !== x.frameId),
                              {
                                ...x,
                                checked: false,
                                rejected: !x.rejected,
                                loaded: false
                              }
                            ]
                          };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <FormCheck
                      value={x.frameId.toString()}
                      checked={x.loaded || null}
                      onChange={() => {
                        setItem(prev => {
                          return {
                            ...prev,
                            frames: [
                              ...frames.filter(y => y.frameId !== x.frameId),
                              {
                                ...x,
                                checked: false,
                                rejected: false,
                                loaded: !x.loaded
                              }
                            ]
                          };
                        });
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <img className="frame-image" src={getImageUrl(x)}></img>
                  </td>
                  <td>{x.frameId}</td>
                  <td>{x.winNo}</td>
                  <td>{x.styleNo}</td>
                  <td>{x.location}</td>
                  <td>{x.isGlazed ? 'GL' : 'UG'}</td>
                  <td>
                    <Form.Control
                      type="text"
                      value={x.comment || ''}
                      onChange={e =>
                        setItem(prev => {
                          return {
                            ...prev,
                            frames: [
                              ...frames.filter(y => y.frameId !== x.frameId),
                              { ...x, comment: e.target.value }
                            ]
                          };
                        })
                      }
                    ></Form.Control>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="text-center" colSpan={10}>
                No Frame data to display
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

QCFrames.propTypes = {
  frames: PropTypes.arrayOf(PropTypes.object),
  setItem: PropTypes.func,
  jobNo: PropTypes.string
};

export default QCFrames;
