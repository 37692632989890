import React from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';

function createTrendline(data) {
  let n = data.length;
  let sumX = 0,
    sumY = 0,
    sumXY = 0,
    sumX2 = 0;

  for (let i = 0; i < n; i++) {
    sumX += i;
    sumY += data[i];
    sumXY += i * data[i];
    sumX2 += i * i;
  }

  let slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  let intercept = (sumY - slope * sumX) / n;

  let trendlineData = [];
  for (let i = 0; i < n; i++) {
    let trendValue = slope * i + intercept;
    trendlineData.push(Number(trendValue.toFixed(2)));
  }

  return { trendlineData, slope: Number(slope.toFixed(2)) }; 
}

const CFEChart = ({ data }) => {
  const seriesData = data.map(x => x.count);
  const category = data.map(x => x.category);

  const { trendlineData } = createTrendline(seriesData);
  const chartOptions = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Count', 'Trendline']
    },
    xAxis: {
      type: 'category',
      data: category
    },
    yAxis: {
      type: 'value'
    },
    grid: {
      left: '17%',
      right: '2%',
      top: '20%',
      bottom: '20%'
    },
    series: [
      {
        name: 'Count',
        type: 'line',
        data: seriesData,
        itemStyle: {
          color: '#73c0de'
        }
      },
      {
        name: 'Trendline',
        type: 'line',
        data: trendlineData,
        lineStyle: {
          type: 'dashed',
          color: 'red'
        },
        symbol: 'none',
        markLine: {
          data: [
            [
              { coord: [0, trendlineData[0]], name: '' },
              {
                coord: [
                  trendlineData.length - 1,
                  trendlineData[trendlineData.length - 1]
                ],
                name: 'End'
              }
            ]
          ],
          lineStyle: {
            type: 'dashed',
            color: 'red'
          },
          symbol: ['none', 'arrow'],
          symbolSize: [10, 15]
        }
      }
    ]
  };

  return (
    <div style={{ width: '100%', height: '100px' }}>
      <ReactEcharts
        option={chartOptions}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};
CFEChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default CFEChart;
