import React, {useState} from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const AreaTableHeader = ({ toggelModal, handleSearch }) => {
  const [keyword, setKeyword] = useState('');
  return (
    <div>
      <FalconCardHeader
        title="Area"
        light
        titleTag="h6"
        endEl={
          <div
            id="orders-actions"
            className="d-flex align-items-center justify-content-between"
          >
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={toggelModal}
            >
              <span className="d-none d-sm-inline-block ms-1">New</span>
            </IconButton>
            <div className="d-flex align-items-center ms-2">
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                onChange={e => setKeyword(e.target.value)}
              />
              <IconButton
                color="primary"
                className="ms-2"
                size="md"
                icon="search"
                onClick={() => handleSearch(keyword)}
              >
                {/* <FontAwesomeIcon icon={faSearch} /> */}
              </IconButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

AreaTableHeader.propTypes = {
  toggelFilter: PropTypes.func,
  toggelModal: PropTypes.func,
  handleSearch: PropTypes.func
};

export default AreaTableHeader;
