import axios from 'axios';
import { API3_HOST } from '../../helpers/urlHelper';
import { ApiResponse } from '../../helpers/stockLocationHelpers';
import { setItemToStore } from 'helpers/utils';
axios.defaults.withCredentials = true;
const baseURL = `${API3_HOST}/user-settings`;

const KEY_COL_SC_ACTION_PLAN = 'KEY_COL_SC_ACTION_PLAN';
const KEY_SORT_COL_SC_ACTION_PLAN = 'KEY_SORT_COL_SC_ACTION_PLAN';
const KEY_FILTER_SC_ACTION_PLAN = 'KEY_FILTER_SC_ACTION_PLAN';
const KEY_CF_MEETING_CONFIG = 'KEY_CF_MEETING_CONFIG';
const KEY_MEETING_DETAIL_CONFIG = 'KEY_MEETING_DETAIL_CONFIG';

const KEY_ACTION_PLAN_LIST_CONFIG = 'KEY_ACTION_PLAN_LIST_CONFIG';
const KEY_MEETING_LIST_CONFIG = 'KEY_MEETING_LIST_CONFIG';

const getSetting = async key => {
  const result = new ApiResponse();
  const cache = sessionStorage.getItem(key);
  if (cache) {
    result.success = true;
    result.data = JSON.parse(cache);
    result.message = 'Data retrieved from cached';
    return result;
  }

  const res = await axios.get(`${baseURL}/${key}`);
  if (res.status === 200 && res.data.success) {
    setItemToStore(key, JSON.stringify(res.data.data), sessionStorage);
    result.success = true;
    result.data = res.data.data;
    result.message = 'Data retrieved from API and cached';
  } else {
    result.message = 'Failed to retrieve data from API';
  }

  return result;
};

const saveSettings = async (key, value) => {
  const result = new ApiResponse();
  if (!key || !value) return result;
  await axios({
    method: 'patch',
    url: baseURL,
    data: { settingKey: key, settingValue: value },
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  })
    .then(res => {
      if (res.status == 200 && res.data?.success) {
        sessionStorage.removeItem(key);
        getSetting(key);
      }
    })
    .catch(error => {
      console.log('ERROR: ', { ...error });
    });
};

export {
  getSetting,
  saveSettings,
  KEY_COL_SC_ACTION_PLAN,
  KEY_SORT_COL_SC_ACTION_PLAN,
  KEY_FILTER_SC_ACTION_PLAN,
  KEY_MEETING_DETAIL_CONFIG,
  KEY_ACTION_PLAN_LIST_CONFIG,
  KEY_CF_MEETING_CONFIG,
  KEY_MEETING_LIST_CONFIG
};
