import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { post, patch } from 'services/skill-matric-services/smSectionService';

const SectionModal = ({ isShow, data, handleClose, handleSubmit }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  console.log(data)
  
  useEffect(() => {
    setFormData(data);
  }, [isShow]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  console.log(formData)
  const [formData, setFormData] = useState({});

  const handleChange = ({ target }) => {
    console.log(formData);
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (formData.id > 0) {
      await patch(formData.id, { ...formData });
    } else {
      await post({ id: uuid(), ...formData });
    }
    handleSubmit();
  };

  useEffect(() => {}, []);

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">{formData?.id > 0 ? 'Edit' : 'Create'} Section</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0 required">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData?.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Col md="12">
            <Form.Label className="fs-0">Position</Form.Label>
            <Form.Control
              type="number"
              name="position"
              required
              value={formData?.position}
              onChange={handleChange}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Link to="#!" className="me-3 text-600">
            More options
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SectionModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  name: PropTypes.string,
};

export default SectionModal;
