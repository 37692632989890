import React, { useState, useEffect } from "react";
import { Card, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import ProjectInfo from "components/partials/projects/ProjectInfo";
import ProjectDashboardTable from "components/partials/projects/ProjectDashboardTable";
import { useDispatch } from 'react-redux';
import { applyProject } from '../../../../slices/projectSlice';
import { getAllCondensedProjects } from "services/projectServices";

export default function ProjectDashboard() {
    const [projects, setProjects] = useState([]);
    const dispatch = useDispatch();

    // Reset selected project when arriving at this page
    dispatch(applyProject(null));

    useEffect(() => {
        getAllCondensedProjects().then(res => {
            if (res) setProjects(res);
        })
            .catch(err => {
                console.log(err);
            });
    }, [])

    return (
        <>
            <PageHeader
                title="Project Dashboard"
                description={`Manage and view all available projects from this page.`}
                className="mb-3"
            ></PageHeader>
            <ProjectInfo projects={projects} />
            <Card>
                <Card.Body className="overflow-hidden p-lg-6">
                    <Row className="align-items-center justify-content-between">
                        <ProjectDashboardTable projects={projects} />
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}