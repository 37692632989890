import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSite: null
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    applySite: (state, action) => {
      state.selectedSite = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applySite } = siteSlice.actions;

export default siteSlice.reducer;
