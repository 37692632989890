import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { useSelector } from 'react-redux';
import PingIcon from 'components/PingIcon/PingIcon';
// import OptionListSelect from 'components/dropdowns/OptionListSelect';
// import { Col, Form, Row, Button } from 'react-bootstrap';

const columns = [
    {
        accessor: 'nameCell',
        Header: 'Name'
    },
    {
        accessor: 'created',
        Header: 'Created'
    },
    {
        accessor: 'owners',
        Header: 'Owners'
    },
    {
        accessor: 'priority',
        Header: 'Priority'
    },
    {
        accessor: 'status',
        Header: 'Status'
    },
    {
        accessor: 'view',
        Header: 'View'
    }
];

const ProjectDashboardTable = ({ projects }) => {
    const [items, setItems] = useState([]);
    // const [filteredItems, setFilteredItems] = useState([]);
    // const [filter, setFilter] = useState({
    //     priority: [],
    //     status: [],
    //     searchQuery: ''
    // });
    // const [loading, setLoading] = useState(false);

    const userId = useSelector(state => state.user.userId);

    useEffect(() => {
        setItems(
            projects && projects.length > 0 ? projects.map((p) => {
                return (
                    {
                        nameCell: <PingIcon name={p.projectName} hasPing={p.actionUsers?.includes(userId?.toString())} />,
                        name: p.projectName,
                        created: dayjs(p.created).format("DD-MMMM-YYYY"),
                        owners: p.owners,
                        priority: p.priority,
                        status: p.status,
                        view: <Link to={`/npd/projectManager?id=${p.id}`}>View Project</Link>,
                    }
                )
            }) : []
        );
    }, [projects]);
    // Need to tweak the below - Need to handle empty array <- messes up results
    // Maybe add on event instead of useEffect 
    // useEffect(() => {
    //     if (items.length > 0) {
    //         setLoading(true);

    //         setFilteredItems(() => items.filter(x =>
    //             x.name?.toLowerCase().includes(filter.searchQuery) &&
    //             (filter.priority.some(y => y.name === x.priority)) &&
    //             (filter.status.some(y => y.name === x.status))
    //         ));

    //         setLoading(false);
    //     }
    // }, [filter]);

    return (
        <div>
            {/* <Row>
                <Col xs={3}>
                    <Form.Label>Filter Priority</Form.Label>
                    <OptionListSelect
                        optionKey="project.priority"
                        selected={filter.priority}
                        changeFn={opts => setFilter({ ...filter, priority: opts })}
                        isMulti={true}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Label>Filter Status</Form.Label>
                    <OptionListSelect
                        optionKey="project.status"
                        selected={filter.status}
                        changeFn={opts => setFilter({ ...filter, status: opts })}
                        isMulti={true}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Search by project name'
                        value={filter.searchQuery}
                        onChange={e => setFilter({ ...filter, searchQuery: e.target.value })}
                    />
                </Col>
                <Col xs={3} className='mt-auto'>
                    <Button>
                        Apply filter
                    </Button>
                </Col>
            </Row >
            <br /> */}
            <AdvanceTableWrapper
                columns={columns}
                data={items} // filter.searchQuery || filter.status.length || filter.priority.length ? filteredItems : items
                sortable
                pagination
                perPage={25}
                selectionColumnWidth="20%"
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                    rowClassName="align-middle white-space-nowrap text-center"
                    tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden',
                        responsive: true
                    }}
                // loading={loading}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={items.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableWrapper>
        </div >
    )
}

ProjectDashboardTable.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectDashboardTable;