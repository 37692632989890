import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
/* eslint-disable react/prop-types */
/*
const TaskDropMenu = ({ id }) => {
  const { kanbanDispatch } = useContext(KanbanContext);

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleRemoveTaskCard = () => {
    kanbanDispatch({
      type: 'REMOVE_TASK_CARD',
      payload: { id }
    });
  };

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item href="#!">Add Card</Dropdown.Item>
        <Dropdown.Item href="#!">Edit</Dropdown.Item>
        <Dropdown.Item href="#!">Copy link</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
*/

const TaskCard = (props) => {
  console.log(props)
  const handleModalOpen = () => {
    window.open(`https://emplas.atlassian.net/browse/${props.issue.key}`, "_blank");
    //kanbanDispatch({ type: 'OPEN_KANBAN_MODAL', payload: { image: image } });
  };

  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  return (
    //<Draggable draggableId={`task${0}`} index={0}>
    <div
      //ref={provided.innerRef}
      //{...provided.draggableProps}
      //{...provided.dragHandleProps}
      //style={provided.draggableProps.style}
      className="kanban-item"
    >
      <Card
        style={getItemStyle()}
        className="kanban-item-card hover-actions-trigger"
        onClick={handleModalOpen}
      >
        <Card.Body>
          <div className="position-relative">
            {/* <TaskDropMenu id={0} /> */}
          </div>
          <div>
            <h5>
              <SoftBadge
                key={props.issue.key}
                pill={false}
              >
                <img className="m-0 p-0 pe-1" src="https://emplas.atlassian.net/servicedesk/issue-type-icons?icon=incident" />{props.issue.key}
              </SoftBadge>


            </h5>
          </div>

          <div className="fw-light">{props.issue.priority} [{props.issue.statusName}]</div>

          {/* <div className="mb-2">
            <SoftBadge
              key={props.issue.key}
              className="py-1 me-1 mb-1"
            >
              {props.issue.key}
            </SoftBadge>

            <SoftBadge
              className="py-1 me-1 mb-1"
            >
              {props.issue.storyPoints}
            </SoftBadge>

          </div> */}
          <p
            className="mb-0 fw-medium font-sans-serif stretched-link"
            dangerouslySetInnerHTML={{ __html: props.issue.summary }}
          />
          <div className="fw-light">Department: {props.issue.departmentName}</div>
          <div className="fw-light">Reporter: {props.issue.reporterName}</div>
          <div className="fw-light">Assigned to: {props.issue.assigneeName}</div>

          {/* <Container className="p-0 m-0">
            <Row>
              <Col xs={7}>
                

              </Col>

              <Col xs={1} className="p-0 me-1">
                <SoftBadge
                  bg='secondary'
                >
                  {props.issue.storyPoints}
                </SoftBadge>

              </Col>
              <Col xs="auto" className="p-0 m-0">
              
                <SoftBadge
                  bg='secondary'
                  className=''
                >
                {props.issue.assigneeName}
                </SoftBadge>
              </Col>
            </Row>
          </Container> */}

          <Container className="p-0 m-0">
            <Row><Col>{props.issue.storyPoints} Story Points</Col><Col>{props.issue.hoursLogged}Hrs Logged</Col></Row>
          </Container>

          {/* <div>
            <SoftBadge
              className="py-1 me-1 mb-1 pull-right"
              bg='secondary'
            >
              {props.issue.assigneeName}
            </SoftBadge>
          </div> */}

          <div className="z-index-2">

          </div>

          {
            props.issue.subTasks && props.issue.subTasks.length > 0 ? <div className='text-center'><hr></hr><b>({props.issue.subTasks.length}) Subtask(s)&nbsp;</b></div> : <></>
          }
          {
            props.issue.subTasks.map(function (task) {
              return (<div key={task.key}>
                <><SoftBadge
                  key={task.key}
                  pill={false}
                >
                  {task.key}
                </SoftBadge> ({props.issue.statusName})</>
                <br></br>
                <b>{task.summary}</b><br></br>
                {task.hoursLogged}Hrs Logged
              </div>);
            })
          }
        </Card.Body>
      </Card>
    </div>
    //</Draggable>
  );
};

// TaskDropMenu.propTypes = {
//   id: PropTypes.number.isRequired
// };

/*
TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string
      })
    ),
    checklist: PropTypes.shape({
      totalCount: PropTypes.number,
      completed: PropTypes.number
    })
  }),
  index: PropTypes.number
};
*/
/* eslint-enable react/prop-types */
export default TaskCard;
