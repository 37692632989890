import axios from 'axios';
import { API2_HOST } from '../helpers/urlHelper';
import { clearStore, getItemFromStore, setItemToStore } from 'helpers/utils';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
axios.defaults.withCredentials = true;
const userKey = 'eva.users.internal';
const userWithDepartmentKey = 'eva.users.with.departments';

const parseUserToDropdownValue = async (strUserId) => {
  const controller = new AbortController();
  const users = await getAllEvaUsers(controller.signal);
  var values = splitStringMapValueToDropDown(strUserId, users)
  return values;
}

const getAllEvaUsers = async signal => {
  const cache = getItemFromStore(userKey, [], sessionStorage);
  if (cache && cache.length) return cache;

  let users = await axios({
    method: 'get',
    url: `${API2_HOST}/users/internal`,
    signal: signal
  })
    .then(res => {
      if (res.status !== 200) return [];

      return res.data.map(x => {
        return { ...x, value: x.userId, label: x.name };
      });
    })
    .catch(err => {
      console.log(err);
      return [];
    });

  setItemToStore(userKey, JSON.stringify(users), sessionStorage);
  return users;
};

const getUsersWithDepartments = async signal => {
  const cache = getItemFromStore(userWithDepartmentKey, [], sessionStorage);
  if (cache && cache.length) return cache;

  let users = await axios({
    method: 'get',
    url: `${API2_HOST}/users/with-department`,
    signal: signal
  })
    .then(res => {
      if (res.status !== 200) return [];
      console.log(res)
      return res.data.map(x => {
        return { ...x, value: x.userId, label: x.name };
      });
    })
    .catch(err => {
      console.log(err);
      return [];
    });

  setItemToStore(userWithDepartmentKey, JSON.stringify(users), sessionStorage);
  return users;
};

const signOut = async () => {
  await axios({
    method: 'post',
    url: `${API2_HOST}/auth/logout`
  });

  clearStore(sessionStorage);

  return;
};

const getUsername = id => {
  const users = getItemFromStore(
    'eva.users.internal',
    [],
    sessionStorage
  );

  if (!users) return '';

  const createdUser = users.find(i => i.userId == id)
  return createdUser?.name;
};

const getUserById = async id => {
  let storeUser = getItemFromStore('user', {}, sessionStorage);

  if (storeUser && storeUser.userId && storeUser.userName) return storeUser;

  let user = await axios({
    method: 'get',
    url: `${API2_HOST}/users/${id}`
  })
    .then(res => {
      if (res.status === 200) return res.data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });

  setItemToStore(
    'user',
    JSON.stringify({ ...storeUser, ...user }),
    sessionStorage
  );
  return user;
};


export {
  getAllEvaUsers, getUserById, signOut,
  getUsersWithDepartments, parseUserToDropdownValue, getUsername
};
