import React, { useEffect,useRef, useState } from 'react';
import { Modal, Form, Button, ListGroup, Spinner } from 'react-bootstrap';
import BasicTemplate from 'components/Toast/BasicTemplate';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { S3_SM_TRANNING_DOC_FOLDER } from 'helpers/urlHelper';
import {
  uploadFile as uploadS3File,
  deleteFile as deleteS3File,
  dowloadFile
} from 'services/files/s3FileService';
import {
  mapFile,
  getFiles,
  deleteFile
} from 'services/files/attachmentService';
const DocumentModal = ({
  isShow,
  handleClose,
  onSubmit,
  skillLevelId,
  sectionId,
  areaId
}) => {
  const app = 'sm.training.doc';
  const recordValue = `${sectionId}_${areaId}_${skillLevelId}`;

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [itemDownLoading, setItemDownLoading] = useState(-1);
  const [itemDeleting, setItemDeleting] = useState(-1);
  const prefix = `/Section/${sectionId}/Area/${areaId}/SkillLevel/${skillLevelId}`;
  const fileInputRef = useRef(null);

  const documentFolder = S3_SM_TRANNING_DOC_FOLDER + prefix;
  const encodedPrefix = encodeURIComponent(documentFolder);
  useEffect(() => {
    loadFiles();
  }, [skillLevelId, sectionId, areaId]);

  const loadFiles = () => {
    if (skillLevelId && sectionId && areaId) {
      getFiles(recordValue, app).then(x => {
        console.log(x);
        setUploadedFiles(x.data.data);
      });
      // GetFiles(encodedPrefix).then(x => {
      //   setUploadedFiles(x.data);
      // });
    }
  };

  const handleFileChange = e => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      const res = await uploadS3File(encodedPrefix, selectedFile);
      console.log(res);
      if (
        res.status == 200 &&
        res.data.success == true &&
        res.data.mappings?.length > 0
      ) {
        var item = res.data.mappings[0];
        console.log(item);
        await mapFile([
          {
            Name: item.originalName,
            App: app,
            RecordValue: recordValue,
            TempName: item.hashedName,
            Path: '',
            Type: ''
          }
        ]);
        console.log('mapped');
      } else {
        console.log('map falure');
      }
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Clear input file
      }
      loadFiles();
      setIsLoading(false);
    }
  };

  const onFileDownload = async index => {
    setItemDownLoading(index);
    try {
      if (!uploadedFiles || uploadedFiles.length <= index) {
        toast.error(<BasicTemplate success={false} text={'File not found!'} />);
        return;
      }
      const file = uploadedFiles[index];
      const response = await dowloadFile(documentFolder + '/' + file.tempName);
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = file.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      toast.error(<BasicTemplate success={false} text={'Download failed!'} />);
    }
    setItemDownLoading(-1);
  };

  const onFileDelete = async index => {
    setItemDeleting(index);
    try {
      if (!uploadedFiles || uploadedFiles.length <= index) {
        toast.error(<BasicTemplate success={false} text={'File not found!'} />);
        return;
      }

      const file = uploadedFiles[index];
      const res = await deleteFile({
        app: app,
        recordValue: recordValue,
        tempName: file.tempName
      });
      await deleteS3File(documentFolder + '/' + file.tempName);
      if (res.status == 200 && res.data.success == true) {
        toast.success(<BasicTemplate success={true} text={'Success!'} />);
        loadFiles();
      }
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }
    setItemDeleting(-1);
  };

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          // closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          {/* File Upload Section */}

          <Form.Group className="mb-3" controlId="fileInput">
            <Form.Label className="fs-0 required">Upload File</Form.Label>
            <Form.Control type="file" name="file" onChange={handleFileChange} ref={fileInputRef} />
            {isLoading ? (
              <Spinner
                variant="primary"
                animation="border"
                role="status"
              ></Spinner>
            ) : (
              <Button
                variant="secondary"
                onClick={handleUpload}
                className="mt-2"
                disabled={!selectedFile}
              >
                Upload
              </Button>
            )}
          </Form.Group>

          {/* Uploaded Files List */}
          {uploadedFiles && uploadedFiles.length > 0 && (
            <Form.Group className="mb-3">
              <Form.Label className="fs-0">Uploaded Files</Form.Label>
              <ListGroup>
                {uploadedFiles.map((file, index) => (
                  <ListGroup.Item
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {/* Tên file hiển thị ở phía bên trái */}
                    <span>{file.name}</span>

                    {/* Group nút và spinner */}
                    <div className="d-flex align-items-center">
                    {itemDeleting == index ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                          className="me-2"
                        />
                      ) : (<Button
                        variant="danger"
                        size="sm"
                        className="me-2" // Margin-right giữa các nút
                        onClick={() => onFileDelete(index)}
                      >
                        Delete
                      </Button>)}
                      {itemDownLoading == index ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                          className="me-2"
                        />
                      ) : (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => onFileDownload(index)}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
};
DocumentModal.propTypes = {
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  skillLevelId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  areaId: PropTypes.number.isRequired
};

export default DocumentModal;
