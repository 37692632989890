import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { getColor } from 'helpers/utils';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import PropTypes from 'prop-types';

const ActionsChart = ({ complete }) => {
  const options = {
    tooltip: chartJsDefaultTooltip(),
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: chartJsDefaultTooltip()
    }
  };

  const data = {
    labels: ['Complete', 'Uncomplete'],
    datasets: [
      {
        data: [complete, 100 - complete],
        backgroundColor: [getColor('primary'), getColor('300')],
        borderColor: [getColor('primary'), getColor('300')]
      }
    ]
  };

  return (
    <Doughnut data={data} options={options} width="112" className="my-n5" />
  );
};

ActionsChart.propTypes = {
  complete: PropTypes.number.isRequired,
}

export default ActionsChart;
