import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { Collapse } from "bootstrap";

const Minimise = ({ id }) => {
    const [toggle, setToggle] = useState(true);
    const [rotateIcon, setRotateIcon] = useState(false);
    
    useEffect(() => {
        if (!id) return;

        const el = document.getElementById(id);

        const bsCollapse = new Collapse(el, {toggle: false})
        toggle ? bsCollapse.show() : bsCollapse.hide();
    }, [toggle, id])

    const rotate = rotateIcon ? "rotate(180deg)" : "rotate(0)";

    return (
        <FontAwesomeIcon 
            size="xs"
            icon={faChevronUp}
            style={{ transform: rotate, transition: "all 0.15s linear", cursor: 'pointer'}} 
            onClick={() => { setToggle(toggle => !toggle); setRotateIcon(rotate => !rotate); }}
        />
    );
}

Minimise.propTypes = {
    id: PropTypes.string,
}

export default Minimise;