import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import moment from 'moment';

const columns = [
  { accessor: 'site', Header: 'Site' },
  { accessor: 'areaCode', Header: 'Area' },
  { accessor: 'rack', Header: 'X' },
  { accessor: 'shelf', Header: 'Y' },
  { accessor: 'location', Header: 'Z' },
  { accessor: 'barcode', Header: 'Barcode' },
  {
    accessor: 'updated',
    Header: 'Updated',
    type: 'date',
    Cell: rowData => {
      const { updated } = rowData.row.original;

      if (!updated) return '';

      const formattedDate = moment(updated).format('DD/MM/YYYY HH:mm');
      return formattedDate;
    }
  },
  { accessor: 'updatedBy', Header: 'Updated By' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'notes', Header: 'Notes' },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: rowData => {
      const { id } = rowData.row.original;

      return (
        <div>
          <Link
            to={`/stock-location/location-management/location-manager?id=${id}`}
            style={{ display: 'block', marginBottom: '2px' }}
          >
            <label>Edit</label>
          </Link>
        </div>
      );
    }
  }
];

const LocationTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage
}) => {
  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
    </>
  );
};

LocationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func
};

export default LocationTable;
