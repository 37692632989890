import axios from 'axios';
import { FILE_API_HOST } from './urlHelper';
axios.defaults.withCredentials = true;
const bucketName = 'eva-files';

export const UploadFile = async (prefix, file) => {
  const formData = new FormData();
  formData.append('files', file);
  var fullURL = `${FILE_API_HOST}/eva/multi-upload?bucketName=${bucketName}&prefix=${prefix}`;

  const res = await axios({
    url: fullURL,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  const fileName = res?.data?.mappings[0].hashedName;
  const returnUrl = `${FILE_API_HOST}/files/download/${bucketName}?key=${prefix}/${fileName}`;
  return returnUrl;
};

export const GetFiles = async (prefix) => {
  var fullURL = `${FILE_API_HOST}/files/${bucketName}?prefix=${prefix}`;

  const res = await axios({
    url: fullURL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res;
};

export const DeleteFile = async (key) => {
  var fullURL = `${FILE_API_HOST}/files/${bucketName}?key=${key}`;

  const res = await axios({
    url: fullURL,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  
  return res;
};
