import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { buildTargetTextDisplay, formatNumber } from 'helpers/kpiHelper';

const TargetStatItem = ({ stat, handleClick }) => {
  return (
    <div
      className="calendar"
      style={{ width: 'auto', paddingTop: '15px' }}
      onClick={() => handleClick(stat)}
    >
      <span className="calendar-month">
        {moment(stat.date).format('MMM-YY')}
      </span>
      <span
        style={{ fontSize: '20px', whiteSpace: 'nowrap' }}
        className="calendar-day"
      >
        {buildTargetTextDisplay(stat.type, formatNumber(stat.target))}
        {/* {stat.type}
        {formatNumber(stat.target)} */}
      </span>
    </div>
  );
};

TargetStatItem.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.object,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    target : PropTypes.string
  }),
  handleClick: PropTypes.func,
};

const TargetStat = ({ data, handleClick }) => {
  const buildCss = target => {
    if (target.length < 4) {
      return 'col-4 col-md-2';
    } else if (target.length < 7) {
      return 'col-5 col-md-2';
    } else if (target.length < 10) {
      return 'col-6 col-md-3';
    } else if (target.length < 13) {
      return 'col-8 col-md-3';
    } else if (target.length < 16) {
      return 'col-9 col-md-4';
    } else {
      return 'col-12 col-md-5';
    }
  };
  return (
    <>
      {data.map((stat, index) => (
        <div key={index} className={buildCss(buildTargetTextDisplay(stat.type, formatNumber(stat.target)))}>
          <TargetStatItem
            key={stat.title}
            stat={stat}
            index={index}
            handleClick={handleClick}
            lastIndex={data.length - 1}
          />
        </div>
      ))}
    </>
  );
};

TargetStat.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClick: PropTypes.func
};

export default TargetStat;
