import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
const SectionTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  handleClickRow,
  onPageSizeChange
}) => {
  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'position',
      Header: 'Position',
    }
  ];

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        handleClickRow={handleClickRow}
        textAlign=""
      />
    </>
  );
};

SectionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  handleClickRow:  PropTypes.func,
};

export default SectionTable;
