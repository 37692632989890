import React from 'react';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ActionsComplete from './widgets/ActionsComplete';

const ProjectInfo = ({ projects }) => {
    // const actionsComplete = projects.flatMap(x => x.items.flatMap(y => y.actions).filter(z => z.stageId === 7)).length;
    // const totalActions = projects.flatMap(x => x.items.flatMap(y => y.actions)).length;
    const actionsComplete = projects.reduce((accumulator, current) => accumulator + current.completeActions, 0);
    const totalActions = projects.reduce((accumulator, current) => accumulator + current.totalActions, 0);

    const complete = totalActions === 0 ? 0 : parseFloat(((actionsComplete / totalActions) * 100).toFixed(2));

    return (
        <Row className="g-3 mb-3">
            <Col md={6}>
                <StatisticsCard
                    stat={{
                        title: 'Total Number of Projects',
                        value: projects.length,
                        decimal: false,
                        suffix: '',
                        prefix: 'Total: ',
                        className: 'h-md-100',
                        image: bg1
                    }}
                />
            </Col>
            <Col md={6}>
                <ActionsComplete totalComplete={complete} totalActions={totalActions} />
            </Col>
        </Row>
    )
}

ProjectInfo.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectInfo;