import React from "react";
import PropTypes from 'prop-types';
import IconButton from "components/common/IconButton";

const AddNewField = ({ handleClick }) => {
    return (
        <IconButton 
            className="mb-1 float-end"
            variant="info"
            transform="shrink-3"
            icon="plus"
            size="sm" 
            onClick={handleClick}
        >Add Custom Field</IconButton>
    )
}

AddNewField.propTypes = {
    handleClick: PropTypes.func,
}

export default AddNewField;