import PropTypes from 'prop-types';
import React, { useState , useRef, useEffect} from 'react';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { getDepartmentOptionList } from 'services/customer-service/scActionPlanService';

const DepartmentComponent = ({ departmentText }) => {
  const isMounted = useRef(null);
  const [dataDepartments, SetDataDepartments] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    const fetchDepart = async () => {
      const resDepart = await getDepartmentOptionList(controller.signal);
      if (resDepart) {
        SetDataDepartments(
          resDepart.map(x => {
            return {
              ...x,
              value: x.id,
              label: x.name
            };
          })
        );
      }
    };

    fetchDepart();
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const data = splitStringMapValueToDropDown(
    departmentText,
    dataDepartments
  );
 
  return (
    <>
      {data.map(i => (
        <Link to="#" key={i.value}>
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{i.label}</h5>
            </div>
          </Flex>
        </Link>
      ))}
    </>
  );
};

DepartmentComponent.propTypes = {
  departmentText: PropTypes.string,
};

export default DepartmentComponent;
