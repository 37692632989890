import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';

const MeetingFilter = ({
  formData,
  handleChange,
  handleSubmit,
  searchKeyword,
  style,
  displayOption = {},
  saveConfig
}) => {
  const [searchValue, setSearchValue] = useState(searchKeyword);
  useEffect(() => {
    setSearchValue(searchKeyword);
  }, [searchKeyword]);

  return (
    <Card>
      {displayOption && displayOption.showHeader && (
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
        >
          Filter
          <IconButton
              variant="falcon-default"
              size="sm"
              icon="cog"
              transform="shrink-3"
              onClick={saveConfig}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Save this filter
              </span>
            </IconButton>
        </Card.Header>
      )}
      <Card.Body className="bg-light">
        <Row style={style}>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                value={searchValue}
                onChange={e => {
                  handleChange('keyword', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <InputDatePicker
              column="date"
              displayName="Date"
              selected={formData?.date}
              handleChange={handleChange}
              useUTC={false}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Created By"
              fieldName="createdBys"
              handleChange={handleChange}
              placeholder="Created By..."
              value={formData?.createdBys}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Managed By"
              fieldName="managedBys"
              handleChange={handleChange}
              placeholder="Managed By..."
              value={formData?.managedBys}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Attendees"
              fieldName="attendees"
              handleChange={handleChange}
              placeholder="Attendees..."
              value={formData?.attendees}
              isMulti
            />
          </Col>
        </Row>
      </Card.Body>
      {displayOption && displayOption.showFooter && (
        <Card.Footer>
          <hr />
          <div className="text-center mt-2">
            <Button color="primary" onClick={handleSubmit}>
              Search
            </Button>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};
MeetingFilter.propTypes = {
  formData: PropTypes.object,
  displayOption: PropTypes.object,
  handleSubmit: PropTypes.func,
  saveConfig: PropTypes.func,
  style: PropTypes.object,
  handleChange: PropTypes.func,
  searchKeyword: PropTypes.string,
  isParentFullRow: PropTypes.bool
};

export default MeetingFilter;
