import { API3_HOST } from '../../helpers/urlHelper';
import {
  basePost,
  basePatch,
  baseGet
} from 'services/base-services/baseService';
import axios from 'axios';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString } from '../../helpers/utils'

axios.defaults.withCredentials = true;


const baseURL = `${API3_HOST}/sm-skill-matrix`;

const search = async request => {
  const res = await axios({
    method: 'get',
    url: `${baseURL}?${objectToQueryString(request)}`
  }).catch(error => {
    console.log('AXIOS ERROR', error);
    return new BaseTableRS([], 0);
  });

  if (res.status !== 200) return new BaseTableRS([], 0);
  return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
};

const post = async request => {
  return await basePost(baseURL, request);
};

const get = async id => {
  return await baseGet(baseURL, id);
};

const patch = async (id, request) => {
  return await basePatch(baseURL, request, id);
};

export { search, post, patch, get };
