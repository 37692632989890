import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { search as searchSection } from 'services/skill-matric-services/smSectionService';

const AreaTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  handleClickRow,
  onPageSizeChange
}) => {
  const [sections, setSection] = useState([]);
  const isMounted = useRef(null);
  useEffect(() => {
    isMounted.current = true;
    const fetchSections =  async () =>{
      const sectionRes = await searchSection({isGetAll: true});
      setSection(sectionRes.items);
    } 
    
    fetchSections();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'sectionId',
      Header: 'Section',
      Cell: rowData => {
        const { sectionId } = rowData.row.original;
        const section = sections.find(i => i.id == sectionId);
        return section?.name ?? "";
      }
    }
  ];

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        handleClickRow={handleClickRow}
        textAlign=""
      />
    </>
  );
};

AreaTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  handleClickRow: PropTypes.func,
};

export default AreaTable;
