import React, { useEffect, useRef, useState } from 'react';
import is from 'is_js';
import Column from './Column';
import NCModal from '../form-modal/NCModal';
import { groupBy } from 'helpers/utils';
import PropTypes from 'prop-types';

const Container = ({ depts, items, search, departmentsSelected }) => {
  const { newAndCriticalCustomers, newAndCriticalJobs } = items;
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    customerCode: '',
    jobNo: '',
    stageId: 0,
    stageName: ''
  });
  const containerRef = useRef(null);

  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad');
    }

    if (is.mobile()) {
      containerRef.current.classList.add('mobile');
      if (is.safari()) {
        containerRef.current.classList.add('safari');
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome');
      }
    }
  }, []);

  return (
    <div
      className="kanban-container me-n3 scrollbar overflow-scroll"
      ref={containerRef}
    >
      {departmentsSelected && departmentsSelected.length > 0 ? (
        departmentsSelected.map(col => {
          let list = [];

          if (col.id === 4126)
            // Customer view - just show customer info
            list = newAndCriticalCustomers;
          else if (col.id === 4128)
            // First stage all with stage 0 show here
            list = groupBy(
              newAndCriticalJobs.filter(
                x =>
                  (x.stageId === col.id || x.stageId === 0) &&
                  x.customerType !== 902
              ),
              'deliveryDate'
            );
          else if (col.id === 4129) {
            // First stage all with stage 0 and customer type is 902: Commercial Supply & fit
            list = groupBy(
              newAndCriticalJobs.filter(
                x =>
                  (x.stageId === col.id || x.stageId === 0) &&
                  x.customerType === 902
              ),
              'deliveryDate'
            );
          } else
            list = groupBy(
              newAndCriticalJobs.filter(x => x.stageId === col.id),
              'deliveryDate'
            );

          // Sort by customer for despatch stages
          if (col.id === 4133 || col.id === 4134)
            list.forEach(kvPair => {
              kvPair.jobs = kvPair?.jobs.sort((a, b) => {
                if (a.customerCode < b.customerCode) {
                  return -1;
                }
                if (a.customerCode > b.customerCode) {
                  return 1;
                }
                return 0;
              });
            });

          return (
            <Column
              key={col.id}
              dept={col}
              columnItems={list}
              setShow={setShowModal}
              setModalInfo={setModalInfo}
              search={search}
            />
          );
        })
      ) : (
        <div>No data found</div>
      )}
      <NCModal
        show={showModal}
        setShow={setShowModal}
        modalInfo={modalInfo}
        depts={depts}
      />
    </div>
  );
};

Container.propTypes = {
  depts: PropTypes.array,
  items: PropTypes.object,
  search: PropTypes.string,
  departmentsSelected: PropTypes.array
};

export default Container;
