import { configureStore } from '@reduxjs/toolkit';
import projectReducer from './slices/projectSlice';
import areaReducer from './slices/areaSlice';
import authReducer from './slices/authSlice';
import siteReducer from 'slices/siteSlice';
import binReducer from 'slices/binSlice';
import permissionSetupReducer from 'slices/permissionSetupSlice';
import userPermissionReducer from 'slices/userPermissionSlice';
const store = configureStore({
  reducer: {
    project: projectReducer,
    user: authReducer,
    area: areaReducer,
    site: siteReducer,
    bin: binReducer,
    permissionSetup: permissionSetupReducer,
    userPermission: userPermissionReducer
  }
});

export default store;
