import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormHeader from './FormHeader';
import SaveBtn from './SaveBtn';
import { handleUpdate } from 'helpers/newCritical';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';
import CircleRating from 'components/common/CircleRating';

const FeedbackForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedbackItem, setFeedbackItem] = useState({
    experienceFeedback: '',
    rating: 0,
    onboardRating: 0,
    welcomePackRating: 0,
    adequateSupportRating: 0,
    deliveryTimeRating: 0,
    deliveryAppRating: 0,
    productQualityRating: 0,
    deliveryRating: 0,
  });
  const isJobArray = Array.isArray(job);

  useEffect(() => {
    if (isJobArray) setFeedbackItem(prev => ({ ...prev, jobs: job }));
    else setFeedbackItem(prev => ({ ...prev, ...job }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'CSOFeedback');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        {isJobArray && (
          <p className="pt-1 px-2 text-danger">
            <small>
              *Completing this form will update the following job(s) -{' '}
              {job.map(x => x.jobno).join(', ')}
            </small>
          </p>
        )}
        <Form className="p-3">
          <Form.Group className="mb-3 g-3" as={Col} controlId="instruction">
            <Form.Label>
              <i>(1 being Poor and 10 being great)</i>
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 g-3" as={Col} controlId="onboard">
            <Form.Label>
              How did you find the initial onboard process?
            </Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.onboardRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, onboardRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group className="mb-3 g-3" as={Col} controlId="welcome">
            <Form.Label>Did you receive your welcome pack?</Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.welcomePackRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, welcomePackRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group
            className="mb-3 g-3"
            as={Col}
            controlId="adequateSupportFeedback"
          >
            <Form.Label>
              Did you receive adequate support from your cooridnator?
            </Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.adequateSupportRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, adequateSupportRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group
            className="mb-3 g-3"
            as={Col}
            controlId="deliveryTimeFeedback"
          >
            <Form.Label>Was the delivery time accurate?</Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.deliveryTimeRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, deliveryTimeRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group className="mb-3 g-3" as={Col} controlId="appUse">
            <Form.Label>Did you use the delivery notification app?</Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.deliveryAppRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, deliveryAppRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group className="mb-3 g-3" as={Col} controlId="productQuality">
            <Form.Label>Product quality?</Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.productQualityRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, productQualityRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group className="mb-3 g-3" as={Col} controlId="delivery">
            <Form.Label>How was the delivery?</Form.Label>
            <br></br>
            <CircleRating rating={feedbackItem.deliveryRating} handleChange={rating =>
              setFeedbackItem(prev => ({ ...prev, deliveryRating: rating }))}></CircleRating>
          </Form.Group>

          <Form.Group
            className="mb-3 g-3"
            as={Col}
            controlId="experienceFeedback"
          >
            <Form.Label>
              Overall, how would you rate your experience of using Emplas?
            </Form.Label>
            <Form.Control
              as={'textarea'}
              rows={4}
              type="text"
              value={feedbackItem.experienceFeedback || ''}
              onChange={e =>
                setFeedbackItem(prev => ({
                  ...prev,
                  experienceFeedback: e.target.value
                }))
              }
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-4 g-4" as={Col} controlId="rating">
            <Form.Label>Rating</Form.Label>
            <p>
              <Rating
                initialRating={feedbackItem.rating}
                fullSymbol={
                  <FontAwesomeIcon
                    icon="star"
                    className="text-warning fs-2 me-1"
                  />
                }
                emptySymbol={
                  <FontAwesomeIcon icon="star" className="text-300 fs-2 me-1" />
                }
                onClick={rating =>
                  setFeedbackItem(prev => ({ ...prev, rating }))
                }
              />
            </p>
          </Form.Group>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text={!isJobArray ? 'Save' : 'Complete'}
                loadingText="...Saving"
                handleClick={() =>
                  handleUpdate(feedbackItem, setLoading, 'CSOFeedback').then(
                    res => {
                      //bulk complete job.
                      if (res && isJobArray) {
                        setTimeout(() => {
                          nav('/new-critical/nc-dashboard');
                          window.location.reload();
                        }, 1000);
                      }
                    }
                  )
                }
              />

              {!isJobArray && (
                <Col xs={3}>
                  <SaveBtn
                    loading={loading}
                    text="Save And Complete"
                    loadingText="Processing..."
                    handleClick={() =>
                      handleSaveAndUpdateStage(feedbackItem, jobStageItem)
                    }
                  />
                </Col>
              )}
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

FeedbackForm.propTypes = {
  job: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default FeedbackForm;
