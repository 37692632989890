import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';

const InputMonthYearPicker = ({ column, displayName, selected, handleChange }) => {
  const [tempDate, setTempDate] = useState('');

  useEffect(() => {
    setTempDate(selected ? selected.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit' }) : '');
  }, [selected]);

  const handleDateChange = e => {
    const value = e.target.value;
    if (value === '') {
      handleChange(column, '');
      return;
    }

    setTempDate(value);
    const parts = value.split('/');
    if (parts.length === 2 && parts[0].length === 2 && parts[1].length === 4) {
      const month = parseInt(parts[0], 10);
      const year = parseInt(parts[1], 10);

      const newDate = new Date(Date.UTC(year, month - 1, 1));
      if (newDate.getFullYear() === year && newDate.getMonth() === month - 1) {
        handleChange(column, newDate);
      }
    }
  };

  return (
    <Form.Group controlId={column}>
      <Form.Label>{displayName}</Form.Label>
      <DatePicker
        selected={selected}
        onChange={newDate => {
          const newDateUTC = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), 1)); 
          handleChange(column, newDateUTC);
          setTempDate(newDate ? newDateUTC.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit' }) : '');
        }}
        showMonthYearPicker 
        dateFormat="MM/yyyy"
        customInput={
          <CustomDateInput
            formControlProps={{
              placeholder: 'MM/yyyy',
              value: tempDate,
              onChange: handleDateChange
            }}
          />
        }
      />
    </Form.Group>
  );
};

InputMonthYearPicker.propTypes = {
  column: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]),
  handleChange: PropTypes.func,
};

export default InputMonthYearPicker;
