import React from 'react';
import { toast } from 'react-toastify';

const NCToast = (success) => {
    const html = (
        <div className="text-700 text-white">
            <h6 className="text-white">{success ? 'Success!' : 'Error!'}</h6>
            <hr className="me-2" />
            <p>{success ? 'Updated successfully.' : 'Something went wrong. Please try again.'}</p>
        </div>
    );

    return success ? toast.success(html) : toast.error(html);
}

export default NCToast;