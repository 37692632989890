import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import UserDropdown from 'components/dropdowns/UserDropdown';
import FormHeader from './FormHeader';
import { moreThanMinDate } from 'helpers/utils';
import SaveBtn from './SaveBtn';
import { handleUpdate } from 'helpers/newCritical';
import QCFrames from './QCFrames';
import CheckedByLink from './CheckedBy';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';
import { getProductCheckedBy } from 'services/newCritical';

const QAForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [qaItem, setQaItem] = useState({
    // qCReceivedDate: '',
    framesCheckedBy: 0,
    // framesCheckedDate: '',
    ancilCheckedBy: 0,
    // ancilCheckedDate: '',
    weldCheckedBy: 0,
    // weldCheckedDate: '',
    hardwareCheckedBy: 0,
    // hardwareCheckedDate: '',
    glassCheckedBy: 0,
    // glassCheckedDate: '',
    boughtInItem: false,
    deficiencies: false,
    reasonForDeficiency: '',
    ipf: false,
    frames: []
  });

  const handleCheckedByMe = (value, key) => {
    setQaItem(prev => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (job.jobNo) {
      getProductCheckedBy(job.jobNo)
        .then(res => {
          if (res) {
            setQaItem({
              ...qaItem,
              ...job,
              framesCheckedBy: res.framesCheckedBy,
              ancilCheckedBy: res.ancilCheckedBy,
              weldCheckedBy: res.weldedCillCheckedBy,
              glassCheckedBy: res.glassCheckedBy,
              hardwareCheckedBy: res.hardwareCheckedBy
            });
          } else {
            setQaItem({ ...qaItem, ...job });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'QCData');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Form.Group className="mb-3 g-3" as={Col} controlId="requiredDate">
            <Form.Label>QC Received Date</Form.Label>
            <DatePicker
              calendarStartDay={1}
              className="form-control"
              placeholderText="Recieved Date"
              onChange={date =>
                setQaItem(prev => ({
                  ...prev,
                  qCReceivedDate: new Date(date).toISOString()
                }))
              }
              dateFormat={'dd/MM/yyyy'}
              selected={
                moreThanMinDate(qaItem.qCReceivedDate)
                  ? new Date(qaItem.qCReceivedDate)
                  : ''
              }
            />
          </Form.Group>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="frameCheckUser">
              <Form.Label style={{ marginRight: '1rem' }}>
                Frames Checked By
              </Form.Label>
              <CheckedByLink
                handleChangeValue={handleCheckedByMe}
                property={'framesCheckedBy'}
              />
              <UserDropdown
                selectedOptions={qaItem?.framesCheckedBy}
                handleChange={opts =>
                  setQaItem(prev => ({ ...prev, framesCheckedBy: opts.value }))
                }
                isMulti={false}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="ancilCheckUser">
              <Form.Label style={{ marginRight: '1rem' }}>
                Ancil Checked By
              </Form.Label>
              <CheckedByLink
                handleChangeValue={handleCheckedByMe}
                property={'ancilCheckedBy'}
              />
              <UserDropdown
                selectedOptions={qaItem?.ancilCheckedBy}
                handleChange={opts =>
                  setQaItem(prev => ({ ...prev, ancilCheckedBy: opts.value }))
                }
                isMulti={false}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="weldCheckUser">
              <Form.Label style={{ marginRight: '1rem' }}>
                Welded Cills Checked By
              </Form.Label>
              <CheckedByLink
                handleChangeValue={handleCheckedByMe}
                property={'weldCheckedBy'}
              />
              <UserDropdown
                selectedOptions={qaItem?.weldCheckedBy}
                handleChange={opts =>
                  setQaItem(prev => ({ ...prev, weldCheckedBy: opts.value }))
                }
                isMulti={false}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="hardwareCheckUser">
              <Form.Label style={{ marginRight: '1rem' }}>
                Hardware Checked By
              </Form.Label>
              <CheckedByLink
                handleChangeValue={handleCheckedByMe}
                property={'hardwareCheckedBy'}
              />
              <UserDropdown
                selectedOptions={qaItem?.hardwareCheckedBy}
                handleChange={opts =>
                  setQaItem(prev => ({
                    ...prev,
                    hardwareCheckedBy: opts.value
                  }))
                }
                isMulti={false}
              />
            </Form.Group>
            {/* <Form.Group as={Col} controlId="hardwareCheckedDate">
                            <Form.Label>Date</Form.Label>
                            <DatePicker
                                className='form-control'
                                placeholderText='Checked Date'
                                onChange={date => setQaItem(prev => ({ ...prev, hardwareCheckedDate: new Date(date).toISOString() }))}
                                dateFormat={'dd/MM/yyyy'}
                                selected={moreThanMinDate(qaItem.hardwareCheckedDate) ? new Date(qaItem.hardwareCheckedDate) : ''}
                            />
                        </Form.Group> */}
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="glassCheckUser">
              <Form.Label style={{ marginRight: '1rem' }}>
                Glass Checked By
              </Form.Label>
              <CheckedByLink
                handleChangeValue={handleCheckedByMe}
                property={'glassCheckedBy'}
              />
              <UserDropdown
                selectedOptions={qaItem?.glassCheckedBy}
                handleChange={opts =>
                  setQaItem(prev => ({ ...prev, glassCheckedBy: opts }))
                }
                isMulti={false}
              />
            </Form.Group>
            {/* <Form.Group as={Col} controlId="glassCheckedDate">
                            <Form.Label>Date</Form.Label>
                            <DatePicker
                                className='form-control'
                                placeholderText='Checked Date'
                                onChange={date => setQaItem(prev => ({ ...prev, glassCheckedDate: new Date(date).toISOString() }))}
                                dateFormat={'dd/MM/yyyy'}
                                selected={moreThanMinDate(qaItem.glassCheckedDate) ? new Date(qaItem.glassCheckedDate) : ''}
                            />
                        </Form.Group> */}
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="boughtIn">
              <Form.Check
                type="checkbox"
                id="bought-in-items"
                label="Bought in items?"
                checked={qaItem.boughtInItem}
                onChange={e =>
                  setQaItem(prev => ({
                    ...prev,
                    boughtInItem: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="deficiencies">
              <Form.Check
                type="checkbox"
                id="deficiencies?"
                label="Deficiencies?"
                checked={qaItem.deficiencies}
                onChange={e =>
                  setQaItem(prev => ({
                    ...prev,
                    deficiencies: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="ipf">
              <Form.Check
                type="checkbox"
                id="ipf?"
                label="IPF?"
                checked={qaItem.ipf}
                onChange={e =>
                  setQaItem(prev => ({ ...prev, ipf: e.target.checked }))
                }
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3 g-3" id="deficiencyReason">
            <Form.Label>Reason For deficiency</Form.Label>
            <Form.Control
              type="text"
              readOnly={!qaItem.deficiencies}
              value={qaItem.deficiencies ? qaItem.reasonForDeficiency : ''}
              onChange={e =>
                setQaItem(prev => ({
                  ...prev,
                  reasonForDeficiency: e.target.value
                }))
              }
            ></Form.Control>
          </Form.Group>

          <QCFrames
            frames={qaItem.frames.sort((a, b) => a.frameId - b.frameId)}
            setItem={setQaItem}
            jobNo={jobStageItem.jobNo}
          />

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="Saving..."
                handleClick={() => handleUpdate(qaItem, setLoading, 'QCData')}
              />
              <SaveBtn
                loading={loading}
                text="Save & Move to Despatch Loading"
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(qaItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

QAForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default QAForm;
