import axios from 'axios';
import { API2_HOST, SCAN_STATION_URL } from '../helpers/urlHelper';
import dayjs from 'dayjs';

axios.defaults.withCredentials = true;
const url = `${API2_HOST}/NewAndCriticalCustomer`;

const dashboardData = async ({ dateTo, dateFrom, keyword }) => {
  let start,
    end = '';

  if (dayjs(dateTo).isValid() && dayjs(dateFrom).isValid()) {
    start = dayjs(dateFrom).toISOString();
    end = dayjs(dateTo).toISOString();
  }

  const res = await axios({
    method: 'get',
    url: `${url}/GetDashboardData?start=${start}&end=${end}${
      keyword ? `&keyword=${keyword}` : ''
    }`
  }).catch(err => {
    console.error('dashboardData', err);
  });

  return res.status === 200
    ? res.data
    : { newAndCriticalCustomers: [], newAndCriticalJobs: [] };
};

const getFormData = async (jobnos, custCode) => {
  let res = {};

  if (custCode) res.customer = await getCustomerDetails(custCode);

  if (jobnos) res.job = await getJobDetails(jobnos);

  return res;
};

const getCustomerDetails = async custCode => {
  const cust = await axios({
    method: 'get',
    url: `${url}/SalesData?custCode=${custCode}`
  }).catch(err => {
    console.error('getCustomerDetails', err);
  });

  return cust.status === 200 && cust.data ? cust.data : {};
};

const getJobDetails = async jobnos => {
  const job = await axios({
    method: 'get',
    url: `${url}/NCJobDetails?jobNos=${jobnos}`
  }).catch(err => {
    console.error('getJobDetails', err);
  });
  console.log(job.data);
  return job.status === 200 && job.data ? job.data : {};
};

const updateItem = async (item, path) => {
  const success = await axios({
    method: 'patch',
    url: `${url}/${path}`,
    data: item
  });

  return success.data ?? false;
};

const bulkComplete = async item => {
  if (!item.jobs?.length) return false;

  console.log('BULK', item);
  const res = await axios({
    method: 'post',
    url: `${url}/BulkComplete`,
    data: item
  }).catch(err => {
    console.log('BulkComplete', err);
  });

  if (res && res.data) return res.data;

  return false;
};

const getNCProductChecking = async jobNo => {
  const QCProduct = await axios({
    method: 'GET',
    url: `${SCAN_STATION_URL}/QC/nc-check-sheet?jobNo=${jobNo}`
  }).catch(err => {
    console.error('getNCProductChecking', err);
  });

  return QCProduct.status === 200 && QCProduct.data ? QCProduct.data : {};
};

const getDepartmentsSelected = async userId => {
  const res = await axios({
    method: 'get',
    url: `${url}/departments-selected?userId=${userId}`
  }).catch(err => {
    console.error('getDepartmentsSelected', err);
  });

  if (res && res.data) return res.data;
  return null;
};

const saveDepartmentsSelected = async data => {
  const res = await axios({
    method: 'POST',
    url: `${url}/departments-selected`,
    data: data
  }).catch(err => {
    console.error('saveDepartmentsSelected', err);
  });

  if (res && res.data) return res.data;
  return null;
};

const getProductCheckedBy = async jobNo => {
  const res = await axios({
    method: 'GET',
    url: `${SCAN_STATION_URL}/QC/product-checked-by?jobNo=${jobNo}`
  }).catch(err => {
    console.error('Get Product Checked By Failed!', err);
  });

  if (res && res.data) return res.data;
  return null;
};

export {
  dashboardData,
  getFormData,
  updateItem,
  bulkComplete,
  getNCProductChecking,
  getDepartmentsSelected,
  saveDepartmentsSelected,
  getProductCheckedBy
};
