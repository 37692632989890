import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyPermissionSetup } from 'slices/permissionSetupSlice';
import { Button, Card, Col, Row, Spinner, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { toast } from 'react-toastify';
import {
  getById,
  post,
  patch
} from 'services/permissions/permissionSetupServices';
import BasicTemplate from 'components/Toast/BasicTemplate';

export default function PermissionSetupManager() {
  const newItem = {
    id: 0,
    code: "",
    name: "",
    description: "",
  };

  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(state => state.permissionSetup.selectedPermissionSetup);
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(applyPermissionSetup(null));
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setIsWaitingSave(true);

    let result = null;
    if (data.id <= 0) {
      result = await post(data);
    } else {
      result = await patch(data, data.id);
    }

    const message =
      result.message ??
      'There was a problem saving the data. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="Permission Setup successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      getById(id).then(res => {
        console.log(res?.data);
        if (res?.data && res?.data.id > 0) {
          dispatch(
            applyPermissionSetup({
              ...res?.data
            })
          );
          return;
        }
      });
      return;
    }

    dispatch(applyPermissionSetup({ ...newItem }));
    return;
  };
  const handleInputChange = (e, name) => {
    const {
      target: { value }
    } = e;

    switch (name) {
      case 'code':
        dispatch(
          applyPermissionSetup({
            ...data,
            code: value
          })
        );
        break;
      case 'description':
        dispatch(
          applyPermissionSetup({
            ...data,
            description: value
          })
        );
        break;
      case 'name':
        dispatch(
          applyPermissionSetup({
            ...data,
            name: value
          })
        );
        break;
      default:
        break;
    }
    return;
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Col xs={12}>
        <PageHeader
          title={data && data.id > 0 ? 'Edit ' + data.code : 'Create Permission Setup'}
          description={`<p>Use this page to manage your Permission Setup. Assign actions below the description.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="code">
                <Form.Label className='required'>Code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Code"
                  defaultValue={data && data.code}
                  value={data && data.code}
                  onChange={e => handleInputChange(e, 'code')}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Code.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="name">
                <Form.Label className='required'>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  defaultValue={data && data.name}
                  value={data && data.name}
                  onChange={e => handleInputChange(e, 'name')}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  defaultValue=""
                  value={data && data.description}
                  onChange={e => handleInputChange(e, 'description')}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={6}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  type="submit"
                // onClick={() => saveData()}
                >
                  {!isWaitingSave ? (
                    'Save'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
              <Col xs={6}>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Form >
  );
}
