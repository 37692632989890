import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';

const SettingModal = ({ columnsInput, isShow, handleClose, onSubmit }) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns(columnsInput);
  }, [columnsInput]);

  const toggleColumn = columnId => {
    setColumns(cols =>
      cols.map(col =>
        col.accessor === columnId ? { ...col, isVisible: !col.isVisible } : col
      )
    );
  };

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={'white'}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          {columns.map(column => (
            <Form.Group
              key={column.accessor}
              className="mb-3"
              controlId={column.accessor}
              // onClick={() => toggleColumn(column.accessor)}
            >
              <Form.Check
                type="checkbox"
                label={column.Header}
                checked={column.isVisible !== false}
                onChange={() => toggleColumn(column.accessor)}
              />
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SettingModal.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      Header: PropTypes.string.isRequired,
      isVisible: PropTypes.bool
    })
  ),
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleColumn: PropTypes.func,
  columnsInput: PropTypes.string
};

export default SettingModal;
