import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import AreaTable from "components/partials/stock-location/areas/AreaTable";
import { searchAreas } from "services/stock-location/areaServices";
import { BaseTableRQ } from "../models/BaseTable"

export default function AreaList() {
    const defaultPaging = new BaseTableRQ(1, 200);
    const [areas, setAreas] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(defaultPaging);

    const [selectedStatus, setSelectedStatus] = useState(0);

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
        let newStatus = '';
        if (event.target.value !== '-- Status --') {
            newStatus = event.target.value;
        }
        const request = { ...defaultPaging, status: newStatus };
        setPaging(request);
        loadAreas(request);
    };

    useEffect(() => {
        loadAreas({...paging, status:selectedStatus});
    }, [])

    const loadAreas = (pagingRq) => {
        searchAreas(pagingRq).then(res => {
            if (res) {
                setAreas(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            });

        setPaging(pagingRq);
    }

    const gotoPage = (index) => {
        index += 1;
        if (index <= 0) return;
        paging.pageNum = index;
        loadAreas(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum + 1;
        if (page > Math.ceil(total / paging.pageSize)) return;
        paging.pageNum = page;
        loadAreas(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum - 1;
        if (page <= 0) return;
        paging.pageNum = page;
        loadAreas(paging);
    }

    return (
        <>
            <PageHeader
                title="Area List"
                description={`Manage and view all available Areas from this page.`}
                className="mb-3"
            ></PageHeader>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row className="flex-between-center">
                                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0"></h5>
                                </Col>
                                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                    <div id="orders-actions">
                                        <Form.Group>
                                            <Form.Select
                                                size="sm"
                                                aria-label="Status"
                                                value={selectedStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option >-- Status --</option>
                                                <option value="0">Live</option>
                                                <option value="1">Disabled</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="p-2 overflow-hidden">
                            <AreaTable
                                areas={areas}
                                paging={paging}
                                total={total}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                gotoPage={gotoPage}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}