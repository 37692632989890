import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import '../../../../../../assets/scss/user.scss';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge, Table } from 'react-bootstrap';
import AppContext from 'context/Context';
import TargetSettingModal from './TargetSettingModal';
import MeasurableModal from './MeasurableModal';
import { search, patch } from 'services/bs-sector-measurable/measurableService';
import { search as searchTarget } from 'services/bs-sector-measurable/targetService';
import { search as searchMeasureabledData } from 'services/bs-sector-measurable/measurableDataService';
import moment from 'moment';
import classNames from 'classnames';
import { buildTargetTextDisplay } from 'helpers/kpiHelper';
import isoWeek from 'dayjs/plugin/isoWeek';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import FullPageSpinner from 'components/common/full-page-spinner';
import { getUsername } from 'services/userServices';
import { MesuarableTdItem } from './MesuarableTdItem';
export default function SectorCard({ sector, selectedDate, noOfWeek = 4 }) {
  dayjs.extend(isoWeek);
  const {
    config: { isDark }
  } = useContext(AppContext);
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);

  const [idEditing, setIdEditing] = useState(0);
  const [weeksAgo, setWeeksAgo] = useState({});
  const [selectedCurrentMonth, setSelectedCurrentMonth] = useState(
    dayjs(new Date()).startOf('month').toDate()
  );
  const [selectedSubOneMonth, setSelectedSubOneMonth] = useState(
    dayjs(new Date()).subtract(1, 'month').startOf('month').toDate()
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const selectedWeek = dayjs(selectedDate).week();

    setWeeksAgo({
      fourWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 4)
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      threeWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 3)
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      twoWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 2)
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      oneWeekAgo: dayjs(selectedDate)
        .week(selectedWeek - 1)
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
    });

    setSelectedCurrentMonth(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
    );

    setSelectedSubOneMonth(
      dayjs(selectedDate).subtract(1, 'month').startOf('month').toDate()
    );
  }, [selectedDate]);

  const defaultInitialObj = {
    sectorId: sector.id
  };

  useEffect(() => {
    setMesurableData({ sectorId: sector.id });
    getMeasurables();
  }, [sector]);

  useEffect(() => {
    getMeasurables();
  }, [selectedCurrentMonth, selectedSubOneMonth]);

  const [measurables, setMeasurables] = useState([]);
  // const getOptionList()
  const getMeasurables = async () => {
    setIsLoading(true);
    let resMeasurables = await search({ isGetAll: true, sectorId: sector.id });
    let resTargets = await searchTarget({
      isGetAll: true,
      sectorId: sector.id
    });
    let mesuarableValuesRs = await searchMeasureabledData({
      isGetAll: true,
      sectorId: sector.id
    });
    let measurables = resMeasurables.items.map(x => {
      let currentMonthTarget = resTargets.items.find(
        t =>
          t.measurableId == x.id &&
          new Date(t.date).getTime() === selectedCurrentMonth.getTime()
      );

      let previousMonthTarget = resTargets.items.find(
        t =>
          t.measurableId == x.id &&
          new Date(t.date).getTime() === selectedSubOneMonth.getTime()
      );

      let mesuarableValues = mesuarableValuesRs?.items?.filter(
        mv => mv.measurableId == x.id
      );
      let rs = {
        ...x,
        dateData: mesuarableValues,
        fourWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.fourWeeksAgo
        ),
        threeWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.threeWeeksAgo
        ),
        twoWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.twoWeeksAgo
        ),
        oneWeekAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.oneWeekAgo
        ),
        currentMonthTarget: currentMonthTarget,
        previousMonthTarget: previousMonthTarget
      };

      return rs;
    });
    setMeasurables(measurables);
    setIsLoading(false);
  };

  const [isMesurableShow, setIsMesurableShow] = useState(false);
  const [mesurableData, setMesurableData] = useState({});

  const toggleMesurabledModal = () => {
    setIsMesurableShow(!isMesurableShow);
  };

  const handleCloseMesurableModal = () => {
    setIsMesurableShow(false);
    setMesurableData({ ...defaultInitialObj });
  };

  const handleSubmitMesurableModal = () => {
    handleCloseMesurableModal();
    getMeasurables();
  };
  //
  const [isTargetSettingShow, setIsTargetSettingShow] = useState(false);
  const [targetSettingData, setTargetSettingData] = useState({});

  const toggleTargetSettingdModal = () => {
    setIsTargetSettingShow(!isTargetSettingShow);
  };

  const handleCloseTargetSettingModal = () => {
    setIsTargetSettingShow(false);
    setTargetSettingData({});
  };

  const handleSubmitTargetSettingModal = () => {
    handleCloseTargetSettingModal();
    getMeasurables();
  };

  const handleSaveUserIncharge = (option, id) => {
    console.log(id, option);
    patch(id, { inChargeUserId: option.userId }).then(() => {
      setIdEditing(0);
      getMeasurables();
    });
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h3>{sector.name}</h3>
          <Button variant="outline-primary" onClick={toggleMesurabledModal}>
            Create
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12}>
              <FullPageSpinner
                show={isLoading}
                quantity={3}
                variant="success"
              />
              <Table
                responsive
                borderless={true}
                style={{
                  backgroundColor: !isDark ? '#edf2f9' : '#0B1727',
                  borderRadius: '10px'
                }}
              >
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: '5vw' }}>
                      #
                    </th>
                    <th style={{ width: '20vw' }}>Measurable</th>
                    <th className="text-center" style={{ width: '15vw' }}>
                      Target {moment(selectedSubOneMonth).format('MMM-YY')}
                    </th>
                    <th className="text-center" style={{ width: '15vw' }}>
                      Target {moment(selectedCurrentMonth).format('MMM-YY')}
                    </th>
                    <th
                      className="text-center"
                      style={{ width: idEditing > 0 ? '15vw' : '5vw' }}
                    >
                      #
                    </th>
                    <th className="text-center" style={{ width: '10vw' }}>
                      Variation Percentage
                    </th>

                    <th
                      className="text-center"
                      style={{ width: '15vw' }}
                      hidden={noOfWeek < 4}
                    >
                      <Badge style={{ fontSize: '12px' }}>
                        {dayjs(weeksAgo.fourWeeksAgo).format('DD-MMM')}
                      </Badge>
                      <br></br>Week -4
                    </th>
                    <th
                      className="text-center"
                      style={{ width: '15vw' }}
                      hidden={noOfWeek < 3}
                    >
                      <Badge style={{ fontSize: '12px' }}>
                        {dayjs(weeksAgo.threeWeeksAgo).format('DD-MMM')}
                      </Badge>
                      <br></br>Week -3
                    </th>
                    <th
                      className="text-center"
                      style={{ width: '15vw' }}
                      hidden={noOfWeek < 2}
                    >
                      <Badge style={{ fontSize: '12px' }}>
                        {dayjs(weeksAgo.twoWeeksAgo).format('DD-MMM')}
                      </Badge>
                      <br></br>Week -2
                    </th>
                    <th className="text-center" style={{ width: '15vw' }}>
                      <Badge style={{ fontSize: '12px' }}>
                        {dayjs(weeksAgo.oneWeekAgo).format('DD-MMM')}
                      </Badge>
                      <br></br>Week -1
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {measurables?.map((x, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center p-1">
                          <i>
                            <b>{index + 1}</b>
                          </i>
                        </td>
                        <td
                          className="p-1"
                          onClick={() => {
                            toggleMesurabledModal();
                            setMesurableData(x);
                          }}
                        >
                          <i>
                            <b>{x.name}</b>
                          </i>
                        </td>
                        <td
                          className="text-center p-1"
                          onClick={() => {
                            toggleTargetSettingdModal();
                            setTargetSettingData({
                              ...x,
                              date: selectedSubOneMonth,
                              target: x.previousMonthTarget?.target
                            });
                          }}
                        >
                          {buildTargetTextDisplay(
                            x.targetTypeValue,
                            x.previousMonthTarget?.target?.toLocaleString(
                              'en-US'
                            )
                          )}
                        </td>
                        <td
                          className="text-center p-1"
                          onClick={() => {
                            toggleTargetSettingdModal();
                            setTargetSettingData({
                              ...x,
                              date: selectedCurrentMonth,
                              target: x.currentMonthTarget?.target
                            });
                          }}
                        >
                          {buildTargetTextDisplay(
                            x.targetTypeValue,
                            x.currentMonthTarget?.target?.toLocaleString(
                              'en-US'
                            )
                          )}
                        </td>
                        <td className={classNames('text-center p-1')}>
                          <div
                            onClick={() => {
                              setIdEditing(x.id);
                            }}
                            onBlur={() => {
                              setIdEditing(0);
                            }}
                          >
                            {x.id == idEditing && (
                              <UserDropdownV2
                                fieldName="inChargeUserId"
                                handleChange={(_, option) =>
                                  handleSaveUserIncharge(option, x.id)
                                }
                                placeholder=""
                                isMulti={false}
                                value={x?.inChargeUserId}
                              />
                            )}
                            {x.id != idEditing && (
                              <Avatar
                                size="xl"
                                name={getUsername(x?.inChargeUserId)}
                                className="me-2"
                              />
                            )}
                          </div>
                        </td>
                        {/* <MesuarableTdUserInCharge
                          item={x}
                          col={'inchargeUser'}
                          handleSaveUserIncharge={handleSaveUserIncharge}
                          callback={getMeasurables}
                        /> */}
                        <td className="text-center p-1">
                          {x.variationPercentage}%
                        </td>
                        <MesuarableTdItem
                          data={measurables}
                          item={x}
                          col={dayjs(weeksAgo.fourWeeksAgo).format(
                            'YYYY-MM-DD'
                          )}
                          value={x.fourWeeksAgo?.value?.toLocaleString('en-US')}
                          callback={getMeasurables}
                          noOfWeek={4}
                          compareNoOfWeek={noOfWeek}
                        />
                        <MesuarableTdItem
                          data={measurables}
                          item={x}
                          col={dayjs(weeksAgo.threeWeeksAgo).format(
                            'YYYY-MM-DD'
                          )}
                          value={x.threeWeeksAgo?.value?.toLocaleString(
                            'en-US'
                          )}
                          callback={getMeasurables}
                          noOfWeek={3}
                          compareNoOfWeek={noOfWeek}
                        />
                        <MesuarableTdItem
                          data={measurables}
                          item={x}
                          col={dayjs(weeksAgo.twoWeeksAgo).format('YYYY-MM-DD')}
                          value={x.twoWeeksAgo?.value?.toLocaleString('en-US')}
                          callback={getMeasurables}
                          noOfWeek={2}
                          compareNoOfWeek={noOfWeek}
                        />
                        <MesuarableTdItem
                          data={measurables}
                          item={x}
                          col={dayjs(weeksAgo.oneWeekAgo).format('YYYY-MM-DD')}
                          value={x.oneWeekAgo?.value?.toLocaleString('en-US')}
                          callback={getMeasurables}
                          noOfWeek={1}
                          compareNoOfWeek={noOfWeek}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isMesurableShow && (
        <MeasurableModal
          measurables={measurables}
          isShow={isMesurableShow}
          handleClose={handleCloseMesurableModal}
          data={mesurableData}
          handleSubmit={handleSubmitMesurableModal}
        />
      )}
      {isTargetSettingShow && (
        <TargetSettingModal
          isShow={isTargetSettingShow}
          handleClose={handleCloseTargetSettingModal}
          data={targetSettingData}
          handleSubmit={handleSubmitTargetSettingModal}
        />
      )}
    </>
  );
}

SectorCard.propTypes = {
  sector: PropTypes.object,
  selectedDate: PropTypes.object,
  noOfWeek: PropTypes.number
};
