import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getAllSites } from 'services/stock-location/siteServices';

const SiteDropdown = ({ selectedOptions, handleChange, isMulti = true }) => {
  const [sites, setSites] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    getAllSites()
      .then(res => {
        setSites(res.items.map(x => {
          let lableText  =  x.code + (x.description == null ? "" : " - " + x.description);
          return {
            ...x, value: x.id, label: lableText
          }
        }));
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="sites"
      options={sites}
      value={
        typeof selectedOptions === 'number'
          ? sites?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder="Select a site from the list..."
    />
  );
};

SiteDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default SiteDropdown;
