import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Calendar = ({ date }) => {
  const year = moment(date).format('yyyy');
  const day = moment(date).format('DD');
  const month = moment(date).format('MMM');

  const currentYear = new Date().getFullYear();
  return (
    <div className="calendar">
      <span className="calendar-month">
        {year.toString() !== currentYear.toString() ? (
          <span style={{ fontSize: '12px', color: 'pink' }}>{year}</span>
        ) : (
          ''
        )}{' '}
        {month}
      </span>
      <span className="calendar-day">{day}</span>
    </div>
  );
};

Calendar.propTypes = {
  date: PropTypes.object,
};

export default Calendar;
