import axios from 'axios';
import { API2_HOST } from '../../helpers/urlHelper';

axios.defaults.withCredentials = true;

const baseURL = `${API2_HOST}/customer-focus-summary`;
const newCffsURL =
  process.env.REACT_APP_EVA_URL + '/Internal/CCF/CCFSearch.aspx?mode=newccfs';
const criticalReturnCffsURL =
  process.env.REACT_APP_EVA_URL + '/Internal/CCF/CCF.aspx?ccf_id=';
const missingFeedbackUrl =
  process.env.REACT_APP_EVA_URL +
  '/Internal/CCF/CCFSearch.aspx?mode=missing-feedbackrequired-info';

const baseGetInfo = async (urlRq, urlRs) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/${urlRq}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      return {
        data: response.data.data,
        url: urlRs
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getNNCCustomers = async () => {
  return baseGetInfo('get-nc-customers', undefined);
};

const getThisWeekNewCCFs = async () => {
  return baseGetInfo('get-this-week-new-ccfs', newCffsURL);
};

const getNewCCFs = async () => {
  return baseGetInfo('get-new-ccfs', newCffsURL);
};
const getNNCNewCCFs = async () => {
  return baseGetInfo('get-nc-new-ccfs', undefined);
};

const getMissingFeedbackRequiredInfoCCFs = async () => {
  return baseGetInfo('get-info-feedback-ccfs', missingFeedbackUrl);
};
const getCriticalReturnCff = async () => {
  return baseGetInfo(
    'get-critical-return-to-be-checked',
    criticalReturnCffsURL
  );
};

const getCollectionJobs = async () => {
  return baseGetInfo(
    'get-collection-jobs',
    '#'
  );
};

const getTodayCommercialDDC = async () => {
  return baseGetInfo('get-today-commercial-ddc', undefined);
};
const getTodayTradeDDC = async () => {
  return baseGetInfo('get-today-trade-ddc', undefined);
};
const getNcDdc = async () => {
  return baseGetInfo('get-today-nc-ddc', undefined);
};
export {
  getNNCCustomers,
  getThisWeekNewCCFs,
  getNewCCFs,
  getNNCNewCCFs,
  getMissingFeedbackRequiredInfoCCFs,
  getTodayTradeDDC,
  getTodayCommercialDDC,
  getNcDdc,
  getCriticalReturnCff,
  getCollectionJobs
};
