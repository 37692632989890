import { API2_HOST } from '../../helpers/urlHelper';
import {
  baseSearch,
  basePost,
  basePatch
} from 'services/base-services/baseService';
import { ApiResponse } from 'helpers/stockLocationHelpers';
import axios from 'axios';
axios.defaults.withCredentials = true;

const baseURL = `${API2_HOST}/bs-sector-measurable-data`;
const search = async request => {
  return await baseSearch(baseURL, request);
};

const upSert = async (measurableId, date, value) => {
  const result = new ApiResponse();
  const res = await axios({
    method: 'patch',
    url: `${baseURL}/${measurableId}/${date}/${value}`,
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  }).catch(error => {
    console.log('ERROR: ', { ...error });
    return result;
  });

  if (res.status !== 200) {
    return result;
  }
  result.success = res.data.success;
  result.data = res.data?.data;
  result.message = res.data?.message;
  return result;
};

const post = async request => {
  return await basePost(baseURL, request);
};

const patch = async (id, request) => {
  return await basePatch(baseURL, request, id);
};

export { search, post, patch, upSert };
