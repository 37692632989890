import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownV2 from './BaseDropdownV2';
import { search } from 'services/skill-matric-services/smSectionService';

const SmSectionDropdown = ({
  fieldName = 'sectionId',
  title = 'Section',
  closeMenuOnSelect = true,
  isMulti = false,
  param = { isGetAll: true },
  placeholder = 'Section',
  handleChange,
  selectedOptions,
  isDisabled=false
}) => {
  return (
    <>
      <BaseDropdownV2
        disabled={isDisabled}
        fieldName={fieldName}
        title={title}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        placeholder={placeholder}
        handleChange={handleChange}
        getSource={search}
        getSourParam={param}
        mapToOption={res => {
          return res?.items.map(i => {
            return { ...i, value: i.id, label: i.name };
          });
        }}
        value={selectedOptions}
      />
    </>
  );
};

SmSectionDropdown.propTypes = {
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  fieldName: PropTypes.string,
  title: PropTypes.string,
  closeMenuOnSelect: PropTypes.func,
  isMulti: PropTypes.bool,
  param: PropTypes.object,
  placeholder: PropTypes.string,
  selectedOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default SmSectionDropdown;
