import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { searchBins } from 'services/stock-location/binServices';
import { BinSearchRQ } from 'components/pages/stock-location/models/BinSearchRQ';

const BinDropdown = ({
  selectedOptions,
  handleChange,
  isMulti = false,
  placeholder = 'Select a Bin from the list...'
}) => {
  const [sites, setBins] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    searchBins(new BinSearchRQ(1, 100, '', true))
      .then(res => {
        setBins(
          res.items.map(x => {
            return {
              ...x,
              value: x.id,
              label: x.barcode
            };
          })
        );
      })
      .catch(err => {
        console.log('Error in fetch', err);
      });

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="bins"
      options={sites}
      value={
        typeof selectedOptions === 'number'
          ? sites?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder={placeholder}
    />
  );
};

BinDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string
};

export default BinDropdown;
