const defaultFields = [
    { id: 1, projectId: 0, title: 'Description and overview', notes: '', actions: [] },
    { id: 2, projectId: 0, title: 'Justification', notes: '', actions: [] },
    { id: 3, projectId: 0, title: 'Basic Construction and design', notes: '', actions: [] },
    { id: 4, projectId: 0, title: 'Important and Significant Characteristic', notes: '', actions: [] },
    { id: 5, projectId: 0, title: 'Full parts list', notes: '', actions: [] },
    { id: 6, projectId: 0, title: 'Tools, Gauges & Jigs etc.', notes: '', actions: [] },
    { id: 7, projectId: 0, title: 'Prototype Build', notes: '', actions: [] },
    { id: 8, projectId: 0, title: 'Window Designer', notes: '', actions: [] },
    { id: 9, projectId: 0, title: 'Machine Consideration', notes: '', actions: [] },
    { id: 10, projectId: 0, title: 'Packaging Specification', notes: '', actions: [] },
    { id: 11, projectId: 0, title: 'SIP/SOP and Process flow', notes: '', actions: [] },
    { id: 12, projectId: 0, title: 'Training and Awareness', notes: '', actions: [] },
    { id: 13, projectId: 0, title: 'Outstanding Considerations', notes: '', actions: [] },
    { id: 14, projectId: 0, title: 'Documents Required', notes: '', actions: [] },
    { id: 15, projectId: 0, title: 'Audits', notes: '', actions: [] },
    { id: 16, projectId: 0, title: 'Accreditation', notes: '', actions: [] },
    { id: 17, projectId: 0, title: 'H&S Considerations', notes: '', actions: [] },
    { id: 18, projectId: 0, title: 'Environmental Considerations', notes: '', actions: [] },
    { id: 19, projectId: 0, title: 'Overall considerations', notes: '', actions: [] },
    { id: 20, projectId: 0, title: 'Final Sign Off', notes: '', actions: [] },
    { id: 22, projectId: 0, title: 'Customer Feedback', notes: '', actions: [] },
    { id: 21, projectId: 0, title: 'Customer Communication', notes: '', actions: [] },
    { id: 22, projectId: 0, title: 'Weekly Demand', notes: '', actions: [] },
]

const newProject = {
    Id: 0,
    projectName: "",
    created: "",
    owners: [],
    champions: [],
    items: [],
};

export {
    defaultFields,
    newProject,
}