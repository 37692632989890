import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { createActionPlan } from 'services/customer-service/scActionPlanService';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, InputGroup } from 'react-bootstrap';
import SettingModal from './SettingModal';
import { CustomerFocusTableColumns } from './CustomersFocusTable';
import {
  saveSettings,
  KEY_COL_SC_ACTION_PLAN
} from 'services/base-services/userSettingServices';

const CustomersFocusTableHeader = ({
  reload,
  setReload,
  toggelFilter,
  isFilter,
  meetingId,
  handleChange,
  keyword,
  handleSubmit
}) => {
  const [columns, setColumns] = useState([]);
  const [value, setValue] = useState('');
  const [showSettingModal, setShowSettingModal] = useState(false);
  const createNew = async () => {
    const result = await createActionPlan({ meetingId });
    console.log(result);
    if (result) {
      window.open(
        `/customer-service/meetings/${meetingId}/action-plan-manager/` +
          result.data,
        '_blank'
      );
    }
  };

  useEffect(() => {
    const med = async () => {
      const initialCols = await CustomerFocusTableColumns(!reload);
      setColumns(initialCols);
    };

    med();
    setValue(keyword)
  }, [reload, keyword]);

  const submitColSettings = event => {
    event.preventDefault();
    const checkboxes = Array.from(
      event.target.querySelectorAll('input[type="checkbox"]')
    );
    const settings = checkboxes.map(checkbox => ({
      accessor: checkbox.id,
      isVisible: checkbox.checked
    }));
    console.log('Checked values:', settings);
    const jsonString = JSON.stringify(settings);
    saveSettings(KEY_COL_SC_ACTION_PLAN, jsonString).then(() => {
      setShowSettingModal(false);
      setReload(!reload);
    });
  };

  return (
    <div>
      <FalconCardHeader
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Action Plans</span>
            {!isFilter && (
              <InputGroup
                className="position-relative"
                style={{ width: '200px', marginLeft: '10px' }}
              >
                <FormControl
                  value={value || ''}
                  onChange={({ target: { value } }) => {
                    setValue(value);
                    handleChange('keyword', value);
                  }}
                  size="sm"
                  id="search"
                  placeholder={'Search'}
                  type="search"
                  className="shadow-none"
                />
                <InputGroup.Text
                  className="bg-transparent"
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon icon="search" className="fs--1 text-600" />
                </InputGroup.Text>
              </InputGroup>
            )}
          </div>
        }
        light
        titleTag="h6"
        endEl={
          <div id="orders-actions">
            {meetingId > 0 ? (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => createNew(meetingId)}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            ) : (
              ''
            )}

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={toggelFilter}
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="cog"
              transform="shrink-3"
              onClick={() => {
                setShowSettingModal(!showSettingModal);
              }}
            >
              <span className="d-none d-sm-inline-block ms-1">Setting</span>
            </IconButton>
          </div>
        }
      />
      {showSettingModal && (
        <SettingModal
          columnsInput={columns}
          isShow={showSettingModal}
          onSubmit={submitColSettings}
          handleClose={() => setShowSettingModal(false)}
        />
      )}
    </div>
  );
};

CustomersFocusTableHeader.propTypes = {
  meetingId: PropTypes.string,
  keyword: PropTypes.string,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  toggelFilter: PropTypes.func,
  isFilter: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default CustomersFocusTableHeader;
