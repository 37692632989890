import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const PingNotification = ({ name, hasPing }) => {

    return (
        <div>
            <span>{name}</span>
            {'    '}
            {hasPing && <span className='ping'></span>}
        </div>
    )
}

PingNotification.propTypes = {
    name: PropTypes.string,
    hasPing: PropTypes.bool
}

export default PingNotification;
