import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedUserPermission: null
};

export const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState,
  reducers: {
    applyUserPermission: (state, action) => {
      state.selectedUserPermission = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applyUserPermission } = userPermissionSlice.actions;

export default userPermissionSlice.reducer;