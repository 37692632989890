import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyArea } from '../../../../slices/areaSlice';
import { newArea } from 'helpers/stockLocationHelpers';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import ProjectFormItem from 'components/partials/projects/ProjectFormItem';
import SiteDropdown from 'components/dropdowns/SiteDropdown';
import { toast } from 'react-toastify';
import {
  getAreaById,
  postArea,
  patchArea,
  disableArea,
  enableArea,
  allowDisable
} from 'services/stock-location/areaServices';
import BasicTemplate from 'components/Toast/BasicTemplate';
import YesNoTemplate from 'components/Toast/YesNoTemplate';

export default function AreaManager() {
  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const dispatch = useDispatch();
  const area = useSelector(state => state.area.selectedArea);
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(applyArea(null));
    };
  }, []);

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      getAreaById(id).then(res => {
        if (res?.data && res?.data.id > 0) {
          dispatch(
            applyArea({
              ...res?.data
            })
          );
          return;
        }
      });
      return;
    }

    // If we get this far its a new Area
    dispatch(applyArea({ ...newArea }));
    // area = useSelector(state => state.area.selectedArea);
    return;
  };
  const handleInputChange = (e, name) => {
    const {
      target: { value }
    } = e;

    switch (name) {
      case 'code':
        dispatch(
          applyArea({
            ...area,
            code: value
          })
        );
        break;
      case 'description':
        dispatch(
          applyArea({
            ...area,
            description: value
          })
        );
        break;
      default:
        break;
    }
    return;
  };

  const saveAreaData = async () => {
    setIsWaitingSave(true);
    if (!checkRequired()) {
      setIsWaitingSave(false);
      return false;
    }

    let result = null;
    if (area.id <= 0) {
      result = await postArea(area);
    } else {
      result = await patchArea(area, area.id);
    }

    const message =
      result.message ??
      'There was a problem saving the area. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="Area successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const isStatusLive = () => {
    return area?.status == 'Live' || area?.id == 0;
  };

  const handleAgreeDelete = async (id) => {
    setIsWaitingSave(true);
    const result = await disableArea(id);
    const message =
      result.message ??
      'There was a problem saving the area. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="Area successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    if (result.success == true) {
      fetchData();
    }
    setIsWaitingSave(false);
  }
  const handleDisableArea = async () => {
    setIsWaitingSave(true);
    let result = null;
    console.log(area);
    if (isStatusLive()) {
      const allowDelete = await allowDisable(area.id);
      if (allowDelete?.data === false) {
        toast.warn(
          <YesNoTemplate
            text="The area contain stock, do you want to delete?"
            textHeader='Do you want to delete?'
            onYes={() => handleAgreeDelete(area.id)}
            onNo={() => toast.dismiss()}
          />,
          { autoClose: false, theme: "colored" }
        );
        setIsWaitingSave(false);
        return;
      }
      else {
        result = await disableArea(area.id);
      }
    } else {
      result = await enableArea(area.id);
    }

    if (result.success == true) {
      fetchData();
    }

    const message =
      result.message ??
      'There was a problem saving the area. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="Area successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };


  const checkRequired = () => {
    let valid = true;
    if (area.code === '') {
      toast.error(
        <BasicTemplate text="Please enter a area code to continue." />
      );
      valid = false;
    }
    if (area.siteId?.length <= 0) {
      toast.error(<BasicTemplate text="Please select Site to continue." />);
      valid = false;
    }
    if (area.description?.length <= 0) {
      toast.error(
        <BasicTemplate text="Please input description to continue." />
      );
      valid = false;
    }
    return valid;
  };

  const singleSelectChange = (option, key) => {
    switch (key) {
      case 'site':
        dispatch(
          applyArea({
            ...area,
            siteId: option.value
          })
        );
        break;
      case 'status':
        dispatch(
          applyArea({
            ...area,
            status: option.value
          })
        );
        break;
      default:
        return;
    }
    return;
  };

  return (
    <Row>
      <Col xs={12}>
        <PageHeader
          title={area && area.id > 0 ? 'Edit ' + area.code : 'Create Area'}
          description={`<p>Use this page to manage your areas. Assign actions below the description.</p><p>Leave an input blank to ignore the field.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            <ProjectFormItem
              id="code"
              title="Area Code"
              readOnly={!isStatusLive()}
              body={area && area.code}
              placeholder="Please enter a code for this area"
              valueChange={e => handleInputChange(e, 'code')}
            />
            <ProjectFormItem
              id="description"
              title="Description"
              readOnly={!isStatusLive()}
              body={area && area.description}
              placeholder="Please enter a description for this area"
              valueChange={e => handleInputChange(e, 'description')}
            />
            <ProjectFormItem
              id="site"
              title="Site"
              readOnly={!isStatusLive()}
              inputElement={
                <SiteDropdown
                  selectedOptions={area?.siteId}
                  handleChange={opts => singleSelectChange(opts, 'site')}
                  isMulti={false}
                />
              }
            />
            <Row>
              <Col xs={6}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  onClick={() => saveAreaData()}
                >
                  {!isWaitingSave ? (
                    'Save Area'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
              <Col xs={6}>
                {area?.id > 0 ? (
                  <Button
                    style={{
                      float: 'right'
                    }}
                    variant={!isStatusLive() ? 'success' : 'danger'}
                    disabled={isWaitingSave}
                    onClick={() => handleDisableArea()}
                  >
                    {isStatusLive() ? 'Disable' : 'Enable'}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
