import React, { useState, useEffect } from "react";
import { StockTableRQ } from "../models/StockTableRQ"
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import StockTable from "components/partials/stock-location/stocks/StockTable";
import { searchs } from 'services/stock-location/stockService'
import SlsProductDropdown from "components/dropdowns/SlsProductDropdown";
export default function StockList() {
    const [stocks, setStocks] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(new StockTableRQ(1, 100, ""));
    const [product, setProduct] = useState({});

    const handleProductCodeChange = (option) => {
        setProduct(option);
        setPaging({ ...paging, productCode: option.productCode })
    };

    useEffect(() => {
        // loads(paging);
    }, [])

    const loads = (pagingRq) => {
        searchs(pagingRq).then(res => {
            if (res) {
                setStocks(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            });

        setPaging(pagingRq);
    }

    const gotoPage = (index) => {
        index += 1;
        if (index <= 0) return;
        paging.pageNum = index;
        loads(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum + 1;
        if (page > Math.ceil(total / paging.pageSize)) return;
        paging.pageNum = page;
        loads(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum - 1;
        if (page <= 0) return;
        paging.pageNum = page;
        loads(paging);
    }

    return (
        <>
            <PageHeader
                title="Stocks"
                description={`Manage and view all available stocks from this page.`}
                className="mb-3"
            ></PageHeader>

            <Row className="g-3 mb-3">
                <Col md={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title className=" mb-3 mx-1">
                                <div>Search by code:	</div>
                            </Card.Title>
                            <div>
                                <Row >
                                    <Form.Group className="mb-3">
                                        <SlsProductDropdown
                                            selectedOptions={product}
                                            handleChange={opts => handleProductCodeChange(opts)}
                                            isMulti={false}
                                        />

                                    </Form.Group>
                                </Row>
                                <Row >
                                    <Form.Group className="mb-3">
                                        {product.productDescription}
                                    </Form.Group>
                                </Row>
                            </div>
                            <div >
                                <Button
                                    style={{
                                        float: 'center',
                                    }}
                                    variant={'primary'}
                                    // disabled={isWaitingSave}
                                    onClick={() => loads(paging)}
                                >Load
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Card.Body className="overflow-hidden p-lg-6">
                    <Row className="align-items-center justify-content-between">
                        <StockTable
                            stocks={stocks}
                            paging={paging}
                            total={total}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            gotoPage={gotoPage}
                        />
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}