import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table, Spinner } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  headerGroups,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  tableRef,
  style,
  handleClickRow,
  renderExpandedRow,
  loading = false,
  beSortOption = {},
  expandedRowIndex,
  setExpandedRowIndex,
  setExpandedRowData,
  expandedRowData
}) => {
  const countColumn = headerGroups
    .map(x => x.headers.length)
    .reduce((acc, curr) => acc + curr, 0);
  return (
    <div>
      <SimpleBarReact>
        <Table {...getTableProps(tableProps)} ref={tableRef} style={style}>
          <thead className={headerClassName}>
            {headerGroups.map((group, i) => (
              <tr
                key={'k' + i}
                {...group.getHeaderGroupProps()}
              >
                {group.headers.map((column, j) => (
                  <th
                    style={{ position: 'fixed', top: 0 }}
                    key={'hearder'+ j}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(column.headerProps)
                    )}
                    onClick={
                      beSortOption.handleSort
                        ? () => beSortOption.handleSort(column)
                        : column.getSortByToggleProps().onClick
                    }
                  >
                    {column.render('Header')}
                    {beSortOption.canSort ? (
                      column.isBeSorted ? (
                        column.isBeSortedDesc ? (
                          <span className="sort desc" />
                        ) : (
                          <span className="sort asc" />
                        )
                      ) : (
                        <span className="sort" />
                      )
                    ) : column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="sort desc" />
                        ) : (
                          <span className="sort asc" />
                        )
                      ) : (
                        <span className="sort" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading && [
              <tr key="table-row-loading" className="text-center">
                <td colSpan={headers.length}>
                  <Spinner
                    variant="primary"
                    animation="border"
                    role="status"
                  ></Spinner>
                </td>
              </tr>
            ]}
            {page &&
              page.length > 0 &&
              !loading &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={'row-fragment-' + i}>
                    <tr key={'r' + i}
                      className={rowClassName}
                      {...row.getRowProps({
                        onClick: () =>
                          handleClickRow(
                            row,
                            setExpandedRowIndex,
                            expandedRowIndex,
                            setExpandedRowData
                          )
                      })}
                    >
                      {row.cells.map((cell, index) => (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>

                    {expandedRowIndex === row.index && renderExpandedRow && (
                      <tr>
                        <td colSpan={row.cells.length}>
                          {expandedRowData[row.id] ? (
                            renderExpandedRow(expandedRowData[row.id]) 
                          ) : (
                            <div>Loading...</div>
                          )}
                        </td>
                      </tr>
                    )}
                   </React.Fragment>
                );
              })}
            {page &&
              !page.length &&
              !loading && [
                <tr key="table-row-empty" className="text-center">
                  <td colSpan={countColumn > 0 ? countColumn : headers.length}>
                    No Results
                  </td>
                </tr>
              ]}
          </tbody>
        </Table>
      </SimpleBarReact>
    </div>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  headerGroups: PropTypes.array,
  style: PropTypes.object,
  handleClickRow: PropTypes.func,
  tableRef: PropTypes.object,
  renderExpandedRow: PropTypes.any,
  expandedRowIndex: PropTypes.number,
  setExpandedRowIndex: PropTypes.func,
  setExpandedRowData: PropTypes.func,
  expandedRowData: PropTypes.object,
  beSortOption: PropTypes.object
};

export default AdvanceTable;
