import { API2_HOST } from '../../helpers/urlHelper';
import { baseSearch } from 'services/base-services/baseService'

const baseURL = `${API2_HOST}/cf-daily-metrics`;

const search = async (request) => {
    return await baseSearch(baseURL, request);
}

export {
    search
};

