import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPermissionSetup: null
};

export const permissionSetupSlice = createSlice({
  name: 'permissionSetup',
  initialState,
  reducers: {
    applyPermissionSetup: (state, action) => {
      state.selectedPermissionSetup = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applyPermissionSetup } = permissionSetupSlice.actions;

export default permissionSetupSlice.reducer;
