export class BaseTableRS {
    constructor(items, total) {
        this.items = items;
        this.total = total;
    }
}

export class BaseTableRQ {
    constructor(pageNum, pageSize, isLike = false, isGetAll = false) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        this.isLike = isLike;
        this.isGetAll = isGetAll;
    }
}

export class DropDownItemRS {
    constructor(id, code, description) {
        this.id = id;
        this.code = code;
        this.description = description;
    }
}