import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { departments } from 'data/TempData';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import DepartmentDropdown from 'components/dropdowns/CustomerServices/DepartmentDropdown';
import PriorityDropdown from 'components/dropdowns/CustomerServices/PriorityDropdown';
import ProgressDropdown from 'components/dropdowns/CustomerServices/ProgressDropdown';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';

const CustomersFocusFilter = ({
  formData,
  handleChange,
  handleSubmit,
  searchKeyword,
  style,
  displayOption = {},
  saveConfig
}) => {
  const [searchValue, setSearchValue] = useState(searchKeyword);
  useEffect(() => {
    setSearchValue(searchKeyword);
  }, [searchKeyword]);

  console.log(formData)

  return (
    <Card>
      {displayOption && displayOption.showHeader && (
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
        >
          Filter
          <IconButton
              variant="falcon-default"
              size="sm"
              icon="cog"
              transform="shrink-3"
              onClick={saveConfig}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Save this filter
              </span>
            </IconButton>
        </Card.Header>
      )}
      <Card.Body className="bg-light">
        <Row style={style}>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            {/* <Col lg={'4'}> */}
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                value={searchValue}
                onChange={e => {
                  handleChange('keyword', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <InputDatePicker
              column="targetDate"
              displayName="Target Date"
              selected={formData?.targetDate}
              handleChange={handleChange}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <ProgressDropdown
              label="Status"
              fieldName="progresses"
              isMulti
              placeholder="Status..."
              handleChange={handleChange}
              value={formData?.progresses}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Responsible persons"
              fieldName="assignees"
              handleChange={handleChange}
              placeholder="Responsible..."
              value={formData?.assignees}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Request participants"
              fieldName="requestParticipants"
              handleChange={handleChange}
              placeholder="Participants..."
              value={formData?.requestParticipants}
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <UserDropdownV2
              label="Reporters"
              fieldName="reporters"
              handleChange={handleChange}
              placeholder="Reporters..."
              value={formData?.reporters}
              isMulti
            />
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={formData?.departments}
            />
          </Col>
          {/* <Col md="12">
            <div className="border-dashed-bottom"></div>
          </Col> */}
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <Form.Group controlId="relativeJobNumbers">
              <Form.Label>Relative Job No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Relative Job Numbers"
                name="relativeJobNumbers"
                value={formData?.relativeJobNumbers}
                onChange={e => {
                  handleChange('relativeJobNumbers', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={displayOption.col} style={{ paddingBottom: '10px' }}>
            <PriorityDropdown
              label="Priorities"
              fieldName="priorities"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Priorities..."
              handleChange={handleChange}
              value={formData?.priority}
            />
          </Col>
        </Row>
      </Card.Body>
      {displayOption && displayOption.showFooter && (
        <Card.Footer>
          <hr />
          <div className="text-center mt-2">
            <Button color="primary" onClick={handleSubmit}>
              Search
            </Button>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};
CustomersFocusFilter.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  saveConfig: PropTypes.func,
  searchKeyword: PropTypes.string,
  style: PropTypes.object,
  displayOption: PropTypes.object,
  isParentFullRow: PropTypes.bool
};

export default CustomersFocusFilter;
