import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SmSectionDropdown from 'components/dropdowns/SkillMatrix/SmSectionDropdown';
import SmAreaDropdown from 'components/dropdowns/SkillMatrix/SmAreaDropdown';
import SmSkillLevelDropdown from 'components/dropdowns/SkillMatrix/SmSkillLevelDropdown';
import UserDropdownV2 from 'components/dropdowns/CustomerServices/UserDropdownV2';

export default function CoreTrainingSetup({ formData, handleFilterChange }) {
  const [paramArea, setParamArea] = useState({
    isGetAll: true,
    sectionId: formData?.sectionId
  });

  const [baseParamRequest] = useState({ isGetAll: true });


  useEffect(() => {
    setParamArea({ isGetAll: true, sectionId: formData?.sectionId });
  }, [formData?.sectionId, formData?.areaId]);

  const hanleFilterInsideChange = (key, value) => {
    console.log(key, value);
    handleFilterChange(key, value);
  };

  return (
    <>
      <Col xs={12}>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <Card className="mb-3">
              <Card.Body className="bg-light">
                <Row style={{ paddingBottom: '10px' }}>
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Trainee</Form.Label>
                      <UserDropdownV2
                        disabled={formData?.id > 0}
                        fieldName="trainee"
                        isMulti={false}
                        handleChange={hanleFilterInsideChange}
                        placeholder="Trainee"
                        value={formData?.trainee}
                        useDepartment={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Section</Form.Label>
                      <SmSectionDropdown
                        isDisabled={formData?.id > 0}
                        param={baseParamRequest}
                        handleChange={hanleFilterInsideChange}
                        selectedOptions={formData?.sectionId}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Area</Form.Label>
                      <SmAreaDropdown
                        isDisabled={
                          formData?.sectionId == undefined || formData?.id > 0
                        }
                        param={paramArea}
                        handleChange={hanleFilterInsideChange}
                        selectedOptions={formData?.areaId}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Skill Level</Form.Label>
                      <SmSkillLevelDropdown
                        isDisabled={formData?.id > 0}
                        isMulti={false}
                        param={baseParamRequest}
                        handleChange={hanleFilterInsideChange}
                        selectedOptions={formData?.skillLevelId}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
}

CoreTrainingSetup.propTypes = {
  formData: PropTypes.object,
  handleFilterChange: PropTypes.func
};
