// Check for production or development
const isDev = process.env.NODE_ENV === 'development';

const API3_HOST = isDev
  ? process.env.REACT_APP_API_TEST_URL
  : process.env.REACT_APP_API_URL;

const API2_HOST = isDev
  ? process.env.REACT_APP_EVA_TEST_API2_URL
  : process.env.REACT_APP_EVA_API2_URL;

const FILE_API_HOST = isDev
  ? process.env.REACT_APP_FILE_API_HOST_DEV
  : process.env.REACT_APP_FILE_API_HOST_LIVE;

const EVA_LOGIN = isDev
  ? process.env.REACT_APP_EVA_TEST_LOGIN_REDIRECT
  : process.env.REACT_APP_EVA_LOGIN_REDIRECT;

const SCAN_STATION_URL = isDev
  ? process.env.REACT_APP_EVA_SCAN_STATION_TEST_API
  : process.env.REACT_APP_EVA_SCAN_STATION_API;

const EVA_API_URL = process.env.EVA_API_URL;

const NC_API_URL = process.env.NC_API;

const S3_SM_TRANNING_DOC_FOLDER = isDev
  ? process.env.REACT_APP_S3_SM_TRANNING_DOC_DEV
  : process.env.REACT_APP_S3_SM_TRANNING_DOC_LIVE;
export {
  API3_HOST,
  API2_HOST,
  EVA_LOGIN,
  EVA_API_URL,
  FILE_API_HOST,
  NC_API_URL,
  SCAN_STATION_URL,
  S3_SM_TRANNING_DOC_FOLDER
};
