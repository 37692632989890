import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import { weekdays, moreThanMinDate } from 'helpers/utils';
import SaveBtn from './SaveBtn';
import { handleUpdate } from 'helpers/newCritical';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { getNextStage } from './FormUtil';

const SalesForm = ({ cust, dept }) => {
  const userId = useSelector(state => state.user.userId);

  // const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({
    // createdBy: userId,
    // updatedBy: userId,
    // customerName: '',
    // customerCode: '',
    // address1: '',
    // address2: '',
    // county: '',
    // postcode: '',
    // mobile: '',
    // email: '',
    // deliveryDate: '',
    // deliveryRestrictions: '',
    //  transportAgreement: '',
    //  newCriticalReason: '',
    //  evaAccountSetup: false,
    //  secondCheckDate: '',
    //  specialRequirements: '',
    //  preContractPresented: false,
    //  welcomePackDelivered: false
  });

  useEffect(() => {
    if (cust && cust.customerCode) setCustomer({...cust, ...customer});
  }, [cust]);

  useEffect(() => {
    setCustomer({...customer, createdBy: userId, updatedBy: userId})
  }, [userId])

  const getCustomerDays = days =>
    days
      ? days
        .split(',')
        .map(day => weekdays[parseInt(day)])
        .join(', ')
      : '';

  // const handleSaveAndUpdateStage = async (data, jobStageItem) => {
  //   // Update Sale Data
  //   handleUpdate(data, setLoading, 'SalesData');

  //   const nextStage = await getNextStage(
  //     jobStageItem.stageId,
  //     jobStageItem.disableStage
  //   );

  //   if (nextStage) {
  //     const newJobStageItem = {
  //       ...jobStageItem,
  //       stageId: nextStage.id
  //     };

  //     handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
  //       if (res)
  //         setTimeout(() => {
  //           nav('/new-critical/nc-dashboard');
  //           window.location.reload();
  //         }, 1000);
  //     });
  //   }
  // };

  return (
    <>
      {loading ? (
        <div className="text-center p-4 w-100">
          <Spinner variant="primary" animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <FormHeader name={dept.name} colour={dept.data}>
            <Form className="p-3">
              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="custName">
                  <Form.Label>Name</Form.Label>{' '}
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.customerName ?? ''}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="custCode">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.customerCode ?? ''}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="delAddress1">
                  <Form.Label>Delivery Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.address1 ?? ''}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="delAddress2">
                  <Form.Label>Delivery Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.address2 ?? ''}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="delCounty">
                  <Form.Label>Delivery County</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.county ?? ''}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="delPostcode">
                  <Form.Label>Delivery Postcode</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.postcode ?? ''}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="delMobile">
                  <Form.Label>Delivery Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.mobile ?? ''}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="delEmail">
                  <Form.Label>Delivery Email</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.email ?? ''}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="delDays">
                  <Form.Label>Delivery Days</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={getCustomerDays(cust?.deliveryDate)}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="delRestrictions">
                  <Form.Label>Delivery Restrictions</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={cust?.deliveryRestrictions ?? ''}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3 g-3" controlId="transAgreement">
                <Form.Label>Transport Agreement</Form.Label>
                <Form.Control
                  type="text"
                  value={customer?.transportAgreement ?? ''}
                  onChange={e =>
                    setCustomer({
                      ...customer,
                      transportAgreement: e.target.value
                    })
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3 g-3" controlId="ncReason">
                <Form.Label>N&C Reason</Form.Label>
                <Form.Control
                  type="text"
                  value={customer?.ncReason ?? ''}
                  onChange={e =>
                    setCustomer({
                      ...customer,
                      ncReason: e.target.value
                    })
                  }
                />
              </Form.Group>

              <Row className="mb-3 g-3">
                {/* <Form.Group as={Col} id="setUpAccounts">
                  <Form.Check
                    type="checkbox"
                    id="set-up-accounts-(EVA)?"
                    label="Set up Accounts (EVA)?"
                    defaultChecked={cust?.evaAccountSetup}
                    title='Auto checked once a login account is created on EVA for this customer'
                    disabled
                  // onChange={e => setCustomer(prev => ({ ...prev, evaAccountSetup: e.target.checked }))}
                  />
                </Form.Group> */}

                <Form.Group as={Col} controlId="secondCheck">
                  <Form.Label>Date of second check</Form.Label>
                  <DatePicker
                    calendarStartDay={1}
                    className="form-control"
                    placeholderText="Checked?"
                    onChange={date =>
                      setCustomer(prev => ({
                        ...prev,
                        secondCheckDate: new Date(date).toISOString()
                      }))
                    }
                    dateFormat={'dd/MM/yyyy'}
                    selected={
                      moreThanMinDate(customer?.secondCheckDate)
                        ? new Date(customer?.secondCheckDate)
                        : ''
                    }
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3 g-3" controlId="specialRequirements">
                <Form.Label>Special Requirements</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={customer?.specialRequirements || ''}
                  onChange={e =>
                    setCustomer(prev => ({
                      ...prev,
                      specialRequirements: e.target.value
                    }))
                  }
                />
              </Form.Group>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="preContractPresented">
                  <Form.Check
                    id='preContractPresented'
                    type="checkbox"
                    label="Pre-contract Presented?"
                    defaultChecked={customer?.preContractPresented}
                    value={customer?.preContractPresented || ""}
                    onClick={e => {
                      console.log(e.target.checked)
                      setCustomer({ ...customer, preContractPresented: e.target.checked})
                    }
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="welcomePackDelivered">
                  <Form.Check
                    type="checkbox"
                    id="welcomePackDelivered"
                    label="Welcome Pack delivered?"
                    defaultChecked={customer?.welcomePackDelivered}
                    onClick={e => {
                      console.log(e.target.checked)
                      setCustomer({ ...customer, welcomePackDelivered: e.target.checked})
                    }
                    }
                  />
                </Form.Group>
              </Row>

              <Row className="my-3 g-3">
                <Col xs={1}>
                  <SaveBtn
                    loading={loading}
                    text="Save"
                    loadingText="Saving..."
                    handleClick={() =>{
                      handleUpdate(customer, setLoading, 'SalesData');
                    }
                    }
                  />
                </Col>

                {/* <Col xs={3}>
                  <SaveBtn
                    loading={loading}
                    text="Save And Update Stage"
                    loadingText="Processing..."
                    handleClick={() =>
                      handleSaveAndUpdateStage(customer, jobStageItem)
                    }
                  />
                </Col> */}
              </Row>
            </Form>
          </FormHeader>
        </>
      )}
    </>
  );
};

SalesForm.propTypes = {
  cust: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default SalesForm;
