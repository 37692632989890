import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import av3 from 'assets/img/team/3.jpg';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import TimeAgo from './TimeAgo';
import { Link } from 'react-router-dom';
import Editor from './Editor/Editor';
import { useParams } from 'react-router-dom';

const CommentEditor = ({
  userName,
  commentIn,
  handleCancel,
  handleSave,
  updated
}) => {
  const [comment, setComment] = useState(commentIn);
  const [editing, setEditing] = useState(true);
  const { meetingId, actionPlanId } = useParams();
  const imageFolder =
    process.env.REACT_APP_CUSTOMER_SERVICE_FOLDER + `/meetings/${meetingId}/sc_action_plans/${actionPlanId}`;
  // const imageFolder = `dev_customer_service/sc_action_plans/${id}/comments`;
  return (
    <Row rows={12}>
      <Flex alignItems="center" className="border-top border-200 pt-3">
        {userName ? <Avatar name={userName} /> : <Avatar src={av3} />}
        <div
          style={{
            marginLeft: '12px',
            border: editing ? '' : 'solid 1px',
            width: '100%',
            borderRadius: '3px',
            padding: '5px',
            marginBottom: '5px',
            backgroundColor: 'white'
          }}
          onClick={() => {
            setEditing(true);
          }}
        >
          <Link
            className="fw-semi-bold"
            to="/user/profile"
            style={{ marginRight: '10px' }}
          >
            {userName}
          </Link>
          {updated && <TimeAgo timestamp={updated} />}
        </div>
      </Flex>
      <Flex alignItems="center" className="border-200 pt-0">
        <div className="avatar avatar-xl" style={{ marginRight: '11px' }} />
        <Editor
          imageFolder={imageFolder}
          className="rounded-pill ms-2 fs--1"
          initialText={commentIn}
          minHeight={200}
          handleChangeOut={e => {
            setComment(e);
          }}
        /> 
      </Flex>
      <Flex alignItems="center" className="border-200 pt-1">
        <Row rows={12}>
          <Col
            className="avatar avatar-xl"
            style={{ marginRight: '10px' }}
          ></Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="primary"
              className="me-2"
              onClick={() => handleSave(comment)}
            >
              Save
            </Button>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Flex>
    </Row>
  );
};

CommentEditor.propTypes = {
  userName: PropTypes.string,
  handleSave: PropTypes.func,
  comments: PropTypes.array,
  otherComments: PropTypes.string,
  commentIn: PropTypes.string,
  handleCancel: PropTypes.func,
  updated:  PropTypes.object
};

export default CommentEditor;
