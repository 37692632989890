import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import AppContext from 'context/Context';
import { patch } from 'services/bs-sector-measurable/targetService';
import InputMonthYearPicker from 'components/common/InputMonthYearPicker';
import { search } from 'services/bs-sector-measurable/targetService';
import TargetStat from './TargetStat';
import GrowSpinner from 'components/common/GrowSpinner';
import FormattedNumberInput from 'components/common/FormattedNumberInput';

const TargetSettingModal = ({ data, isShow, handleClose, handleSubmit }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  useEffect(() => {
    setFormData(data);
  }, [data, data.time]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTargets();
  }, []);

  const [targets, setTargets] = useState([]);
  const [formData, setFormData] = useState(data);

  console.log(formData)
  const getTargets = async () => {
    setIsLoading(true);
    let resTarget = await search({ isGetAll: true, measurableId: data.id });
    console.log(resTarget);
    setTargets(
      resTarget.items.map(x => {
        return { ...x, type: formData.targetTypeValue };
      })
    );
    setIsLoading(false);
  };

  const handleChange = ({ target }) => {
    console.log(formData);
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, value) => {
    console.log(formData);
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit');
    e.preventDefault();
    let rq = mapRequest(formData);
    console.log(rq, data.id);

    await patch(data.id, { ...rq });
    handleSubmit();
  };

  const mapRequest = data => {
    let date =
      data.date.getFullYear() +
      '-' +
      String(data.date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(data.date.getDate()).padStart(2, '0');
    return { ...data, date };
  };

  const handleStartClick = stat => {
    console.log(stat, formData);
    setFormData({ ...stat, date: new Date(stat.date) });
  };

  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      contentClassName="border"
      size="lg"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Set up Target Value</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="titleInput">
                <Form.Label className="fs-0">Month</Form.Label>
                <InputMonthYearPicker
                  column="date"
                  displayName=""
                  selected={formData?.date}
                  handleChange={handleDateChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="targetType">
                <Form.Label className="fs-0">Target</Form.Label>
                <FormattedNumberInput
                  name="target"
                  value={formData.target}
                  onChange={handleChange}
                />

                {/* <Form.Control
                  type="number"
                  name="target"
                  required
                  value={formData?.target}
                  onChange={handleChange}
                /> */}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row">
                <Form.Label className="fs-0">Targets</Form.Label>
                {isLoading ? (
                  <div className="col-4 col-md-2">
                    <GrowSpinner quantity={3} />
                  </div>
                ) : (
                  <TargetStat
                    data={targets}
                    handleClick={handleStartClick}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

TargetSettingModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  name: PropTypes.string
};

export default TargetSettingModal;
