import React from 'react';
import { Row, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import CFEChart from './CFEChart';
import moment from 'moment';
import { CFName } from 'data/TempData';
import {
  NCCustomersCard,
  FaultSourcesCard,
  CriticalReturnCcfCard,
  CollectionJobsCard
} from './CFCards';
const buildCategory = date => {
  const currentYear = moment().year();
  const recordYear = moment(date).year();

  const category =
    recordYear === currentYear
      ? moment(date).format('DD MMM')
      : moment(date).format('DD MMM YYYY');
  return category;
};

const buildBECategory = date => {
  const dayOfWeek = moment(date).format('dddd'); // Get the day of the week
  const dayAndMonth = moment(date).format('D/M'); // Get the day and month in "D/M" format

  const category = `${dayOfWeek} (${dayAndMonth})`; // Combine the formats
  return category;
};

const handleCount = (title, data) => {
  if (title === CFName.NcCustomers) {
    if (data == -1) return 0;
    return (
      data?.find(x => x.dayOfWeek == buildBECategory(new Date()))?.customerNames
        ?.length ?? 0
    );
  }
  if (title === CFName.CollectionJobs) {
    if (data == -1) return 0;
    return (
      data?.find(x => x.date == buildBECategory(new Date()))
        ?.length ?? 0
    );
  }
  return data?.length;
};

const CardNumberWidget = ({
  title,
  data,
  loading,
  url,
  faultSources,
  cfType,
  metrics,
  showChart = true,
  clickAbleNumber = true
}) => {
  const id = title.replace(/\s+/g, '').replace(/[^a-zA-Z]/g, '');

  const metricData = metrics
    ?.filter(x => x.cfType === cfType)
    .map(x => {
      return {
        count: x.value,
        category: buildCategory(x.recordDate)
      };
    });

  metricData.push({
    count: handleCount(title, data),
    category: buildCategory(new Date())
  });

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center g-0 h-100 align-items-center justify-content-center text-center">
          {/* <Col> */}
          <h6 className="mb-2 text-900">{title}</h6>

          <div className="fs-3 fw-normal text-700 mb-0 flex-between-center">
            {loading ? (
              <Spinner
                variant="primary"
                animation="border"
                role="status"
              ></Spinner>
            ) : (
              <>
                <div className="accordion" id={`accordion${id}`}>
                  <div
                    className="accordion-item"
                    style={{
                      border: 'none',
                      background: 'none',
                      boxShadow: 'none'
                    }}
                  >
                    <h2 className="accordion-header" id={`heading${id}`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <div style={showChart ? {}: {width: '10%'}}>
                          {' '}
                        </div>
                        <div>
                          <a
                            href={!clickAbleNumber || url === undefined ? '#' : url}
                            target={!clickAbleNumber || url === undefined ? '' : '_blank'}
                            rel="noopener noreferrer"
                            style={{
                              textAlign: 'center',
                              flex: '0 0 auto'
                            }}
                          >
                            {title === 'N&C Customers'
                              ? [...new Set(data.flatMap(x => x.customerNames))]
                                  .length 
                              : data.length}
                          </a>
                        </div>
                        {showChart && (
                          <div
                            style={{
                              flex: '1 1 auto',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              maxWidth: '75%'
                            }}
                          >
                            <CFEChart title={title} data={metricData} />
                          </div>
                        )}

                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${id}`}
                          aria-expanded="false"
                          aria-controls={`collapse${id}`}
                          style={{
                            flex: '0 0 auto',
                            width: '5px',
                            border: 'none',
                            background: 'none',
                            boxShadow: 'none',
                            marginLeft: '10px' // Tạo khoảng cách giữa nút và biểu đồ
                          }}
                        ></button>
                      </div>
                    </h2>

                    {data && data.length > 0 && (
                      <div
                        className="accordion-collapse collapse"
                        id={`collapse${id}`}
                        aria-labelledby={`heading${id}`}
                        data-bs-parent={`#accordion${id}`}
                      >
                        <div
                          className="accordion-body"
                          style={{ fontSize: '12px', textAlign: 'left' }}
                        >
                          <div className="table-responsive scrollbar">
                            {title === 'N&C Customers' && (
                              <NCCustomersCard data={data} />
                            )}
                            {
                              title == 'Collection Jobs' && (
                                <CollectionJobsCard data={data}/>
                              )
                            }
                            {faultSources && (
                              <FaultSourcesCard
                                faultSources={faultSources}
                                data={data}
                                title={title}
                              />
                            )}
                            {title === 'Critical Return to be Checked' && (
                              <CriticalReturnCcfCard data={data} url={url} />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

CardNumberWidget.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  url: PropTypes.string,
  faultSources: PropTypes.array,
  cfType: PropTypes.number,
  metrics: PropTypes.array,
  showChart: PropTypes.bool,
  clickAbleNumber: PropTypes.bool
};

export default CardNumberWidget;
