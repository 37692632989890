import React from "react";
import PropTypes from "prop-types";

const BasicTemplate = ({ text, success }) => (
    <div className="text-700 text-white">
        <h5 className="text-white">{success ? 'Success!' : 'Error!'}</h5>
        <hr className="me-2" />
        {text}
    </div>
);

BasicTemplate.propTypes = {
    text: PropTypes.string,
    success: PropTypes.bool
}

export default BasicTemplate;