import { getOptionsByKey } from 'services/optionLists';

export const getNextStage = async (
  currentStageId,
  disabledStage,
  customerType = 0
) => {
  const allStages = await getOptionsByKey('new.and.critical.job.department');

  if (allStages && allStages.length > 0) {
    const currentStage = allStages.find(s => s.id === currentStageId);
    let nextStage = allStages.find(s => s.value2 == +currentStage.value2 + 1);
    if (disabledStage || nextStage.value2 == 10 || customerType == 902) {
      nextStage = allStages.find(s => s.value2 == +nextStage.value2 + 1);
    }
    return nextStage;
  }
};
